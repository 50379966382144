import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import PageLayout from "../../layouts/PageLayout";
import ExcelJS from "exceljs";
import Reports from "../../../services/api/reportApi";
import { useEffect, useState } from "react";

export default function AccountsDetailsTable() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");

  const [accountsData, setAccountsData] = useState([]);

  useEffect(() => {
    fetchAccountsData();
  }, []);

  const fetchAccountsData = async () => {
    try {
      const data = await Reports.getOneTripAccount({ id: id });
      setAccountsData(data);
    } catch (error) {
      console.error("Error fetching reports data", error);
    }
  };

  const columnData =
    accountsData.length && accountsData?.map((item, index) => {
      item.sNo = index + 1;
      return item;
    });

  const columns = [
    {
      id: "sNo",
      name: <TableHead width="90px">S.No</TableHead>,
      cell: (row) => <TableCell width="90px">{row.sNo}</TableCell>,
    },
    {
      id: "routeId",
      name: <TableHead>Route ID</TableHead>,
      cell: (row) => <TableCell>{row.routeId || "-"}</TableCell>,
    },
    {
      id: "routeName",
      name: <TableHead>Route Name</TableHead>,
      cell: (row) => <TableCell textCenter>{row.routeName || "-"}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{row.tripDate || "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell textCenter>{row.tripType.name || "-"}</TableCell>,
    },
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => <TableCell>{row.driverID || "-"}</TableCell>,
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell textCenter>{row.driverName || "-"}</TableCell>,
    },
    {
      id: "tripAmmount",
      name: <TableHead>Trip Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.tripAmount)?.toLocaleString() || "-"}</TableCell>,
    },
    {
      id: "paidAmmount",
      name: <TableHead>Paid Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.invoicePaidAmount)?.toLocaleString() || "-"}</TableCell>,
    },
    {
      id: "paymentMode",
      name: <TableHead>Payment Mode</TableHead>,
      cell: (row) => <TableCell>{row.paymentMode.name || "-"}</TableCell>,
    },
    // Assuming 'Paid By' field is available in tripData
    {
      id: "paidBy",
      name: <TableHead>Paid By</TableHead>,
      cell: (row) => <TableCell textCenter>{row.payer.payerName || "-"}</TableCell>,
    },
    {
      id: "paidDate",
      name: <TableHead>Paid Date</TableHead>,
      cell: (row) => <TableCell>{row.invoicePaidDate || "-"}</TableCell>,
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Trip Data");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define cell style
    const cellStyle = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: { vertical: "center", horizontal: "center" },
    };

    // Adding headers
    const headers = [
      "S.No",
      "Route ID",
      "Route Name",
      "Trip Date",
      "Trip Type",
      "Driver ID",
      "Driver Name",
      "Trip Amount",
      "Paid Amount",
      "Payment Mode",
      "Paid By",
      "Paid Date"
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Adding data rows
    accountsData.length && accountsData?.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1,
        row.routeId,
        row.routeName,
        row.tripDate,
        row.tripType.name,
        row.driverID,
        row.driverName,
        row.tripAmount,
        row.invoicePaidAmount,
        row.paymentMode.name,
        row.payer.payerName,
        row.invoicePaidDate
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
      });
    });

    // Auto-fit columns
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 12 ? 12 : maxLength;
    });

    // Generate buffer and download the file
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `TripData_${new Date().toLocaleDateString()}.xlsx`;
    link.click();
  };

  return (
    <PageLayout
      title={"Report Management"}
      description={"View all your reports here"}
    >
      <div className=" w-full flex mt-7">
        <div className="w-full removeScrollBar pb-5">
          <div className="flex justify-end w-[98.5%] pr-5">
            <div className="flex">
              <button onClick={handleExport} className="flex mb-5">
                <Link className="bg-primary-blue flex justify-between items-center text-gray-100 text-lg font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
                  <span className="mr-3">Download Now</span>
                  <svg
                    className="text-2xl "
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </Link>
              </button>
            </div>
          </div>
          <Table columns={columns} rows={columnData} />
        </div>
      </div>
    </PageLayout>
  );
}
