import React, { useEffect, useState } from "react";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import { Link, useNavigate } from "react-router-dom";
import eye from "./assets/eye.6bf84ddf18c60bb31dcf copy.png";
import Outstation from "../../../services/api/outstationApi";
import DateUtils from "../../../services/utility/DateUtils";
// Function to render the label with active state

// Main component for tab navigation
function InvoiceNotPaid({ companyList, activeKey }) {

  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [company, setCompany] = useState("");

  const [outstationList, setOutstationList] = useState([]);

  useEffect(() => {
    fetchOutstationData();
  }, [fromDate, toDate, company]);

  const getInvoiceStatusName = (id) => {
    if (id == 1) {
      return "Not Paid";
    }
    else if (id == 2) {
      return "On Hold";
    }
    else if (id == 3) {
      return "Paid";
    }
    return "-";
  };

  const columns = [
    {
      id: "srNo",
      name: <TableHead>S.No</TableHead>,
      cell: (row) => <TableCell>{row.sNo}</TableCell>,
    },
    {
      id: "routeId",
      name: <TableHead position="left">Route Id</TableHead>,
      cell: (row) => (
        <TableCell position="left">
          {row.routeId || "-"}
        </TableCell>
      ),
    },
    {
      id: "vehicleNo",
      name: <TableHead position="left">Vehicle No</TableHead>,
      cell: (row) => <TableCell position="left">{row.driver?.vehicleNo || "-"}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{row.tripStartDate ? DateUtils.convertDate(row.tripStartDate) : "-" || "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead position="left">Trip Type</TableHead>,
      cell: (row) => <TableCell position="left">{row.tripType?.name || "-"}</TableCell>,
    },
    {
      id: "companyName",
      name: <TableHead position="left">Company Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.company?.name || "-"}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead p>Status</TableHead>,
      cell: (row) => <TableCell>{getInvoiceStatusName(row.invoicePaymentStatusID) || "-"}</TableCell>,
    },
    {
      id: "paidAmount",
      name: <TableHead>Paid Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.invoicePaidAmount)?.toLocaleString() || "-"}</TableCell>,
    },
    {
      id: "paidDate",
      name: <TableHead>Paid Date</TableHead>,
      cell: (row) => <TableCell>{row.invoicePaymentDate ? DateUtils.convertDate(row.invoicePaymentDate) : "-" || "-"}</TableCell>,
    },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <button className=" flex items-center gap-2" type="button" onClick={() => navigate(`/outstation/${row.routeId}?id=${row.id}`)}>
            <img src={eye} width={30} alt="" />
          </button>
        </TableCell>
      ),
    },
  ];

  const fetchOutstationData = async () => {
    const params = {
      invoicePaymentStatusID: activeKey,
      fromDate: fromDate,
      toDate: toDate,
      companyID: company?.id,
    }
    try {
      const response = await Outstation.getAllOutstationTrip(params);
      setOutstationList(
        response.map((item, index) => {
          item.sNo = index + 1;
          return item;
        })
      );
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  return (
    <div className="w-full h-full removeScrollBar">
      <div className="flex justify-between gap-3 items-center">
        <div className="flex lg:gap-8 gap-2 w-full lg:pl-2 items-center mb-5  lg:mb-5  mt-2 lg:mt-6">
          <DropDown
            label={"Company"}
            placeholder={"Select Company"}
            displayValue={"name"}
            data={companyList}
            value={company}
            setValue={setCompany}
            selectAll
          />
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
          />
        </div>
        <button className="flex mt-5 mr-2">
          <div className="bg-primary-blue/95 hover:bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
            <p className="mr-3 whitespace-nowrap">Download Now</p>
            <svg
              className="text-2xl "
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </div>
        </button>
      </div>
      <Table columns={columns} rows={outstationList} />
    </div>
  );
}

export default InvoiceNotPaid;
