import { message } from "antd";
import axios from "axios";

const LoginVerify = {
  signin: async (email, password) => {
    // Add email and password parameters
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/signin`,
        { email, password } // Pass email and password as data
      );

      if (response.data.status) {
        const accessToken = response.data.accessToken;
        const email = response.data.data.email;
        const name = response.data.data.name;
        const userType = response.data.data.userType;
        const id = response.data.data.id;

        localStorage.setItem("accessTokenFleetrax", accessToken);
        localStorage.setItem("emailFleetrax", email);
        localStorage.setItem("nameFleetrax", name);
        localStorage.setItem("userTypeFleetrax", userType);
        localStorage.setItem("userIdFleetrax", id);
        if (response.data.message === "Successfully login") {
          message.success("Successfully logged in.");
        }
        return response.data.data; // Return the response data
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during login", error);
      alert("Error during login");
      return null; // Return an appropriate value or throw the error
    }
  },
};

export default LoginVerify;
