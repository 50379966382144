import { Link } from "react-router-dom";
import { Popover } from "antd";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DateUtils from "../../../services/utility/DateUtils";

export default function ExpenseTable({ data }) {
  const expenseColumns = [
    {
      id: "slNo",
      name: (
        <TableHead position="center" width="100px">
          S.No
        </TableHead>
      ),
      cell: (row) => (
        <TableCell position="center" width="100px">
          {row.sNo}
        </TableCell>
      ),
    },
    {
      id: "paidBy",
      name: <TableHead position="center">PAID BY</TableHead>,
      cell: (row) => (
        <TableCell position="center">{row.payer?.payerName}</TableCell>
      ),
    },
    {
      id: "paidDate",
      name: <TableHead>PAID DATE</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.paidDate ? DateUtils.convertDate(row.paidDate) : "-"}
        </TableCell>
      ),
    },
    {
      id: "expenses",
      name: <TableHead position="left">EXPENSES</TableHead>,
      cell: (row) => <TableCell position="left">{row.description}</TableCell>,
    },
    {
      id: "amount",
      name: <TableHead position="left">AMOUNT</TableHead>,
      cell: (row) => (
        <TableCell position="left">
          ₹ {parseInt(row.debitAmount)?.toLocaleString()}
        </TableCell>
      ),
    },
    {
      id: "reason",
      name: (
        <TableHead position="center" width="400px">
          REASON
        </TableHead>
      ),
      cell: (row) => (
        <TableCell position="left" width="400px">
          <div className=" w-full text-center overflow-x-auto border border-border-gray text-sm rounded-md px-2 py-1">
            {row.transactionId}
          </div>
        </TableCell>
      ),
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <Table columns={expenseColumns} rows={data} />
    </div>
  );
}
