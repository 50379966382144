import axios from "axios";

const LiveTrack = {
    getAllLiveTracking: async (params) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}trip/verify/getLiveTracking`,{
                    params: params,
                    headers: {
                        "x-access-token": localStorage.getItem("accessTokenFleetrax"),
                        "email": localStorage.getItem("emailFleetrax"),
                        "usertype": localStorage.getItem("userTypeFleetrax"),
                      },
                }
            );
            return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
    },
    getOneLiveTracking: async (params) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}trip/verify/getLiveTracking?id=${params.id}`,
                {
                    headers: {
                        "x-access-token": localStorage.getItem("accessTokenFleetrax"),
                        "email": localStorage.getItem("emailFleetrax"),
                        "usertype": localStorage.getItem("userTypeFleetrax"),
                      },
                }
            );
            return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
    },
}

export default LiveTrack;
