import axios from "axios";

const Auth = {
  signUp: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/verify/signup`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getUsers: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}auth/verify/getUsers`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getOneUser: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}auth/verify/getOneUser`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  updateUser: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/verify/updateUser`,

        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  deleteUser: async (params) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}auth/verify/deleteUser/${params.id}`,
        {},
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Auth;
