import { Link } from "react-router-dom";
import { Popover } from "antd";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";

export default function LiveTrackingTable({ data }) {

  function checkStatus(status, name, time, pickupLocation) {
    const statusColor = () => {
      if (status === 0) return "#929695"; // gray
      if (status === 1) return "#f7ec0f"; // yellow
      if (status === 3) return "#2cf70c"; // green
      if (status === 4) return "#ed1607"; // red
    };
    const contentForPopOver = () => {
      return (
        <div className="flex flex-col justify-center items-center font-semibold">
          <p className="text-text-black">{name}</p>
          <p className="text-text-black">{pickupLocation}</p>
          <p className="text-[#175CD3]">{time}</p>
        </div>
      );
    };
    return (
      <Popover content={contentForPopOver}>
        <div className="flex justify-center items-center">
          <div
            className="w-3 h-3 rounded-full cursor-pointer"
            style={{ backgroundColor: statusColor() }}
          ></div>
        </div>
      </Popover>
    );
  }

  const statusDivWidth = 12;

  const columns = [
    {
      id: "routeId",
      name: <TableHead width="100px">Route ID</TableHead>,
      cell: (row) => (
        <TableCell textCenter width="100px">
          <Link className="text-primary-blue" to={`/trip-management/${row.routeId}?id=${row.id}#track-live`}>
            {row.routeId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead width="120px">Trip Date</TableHead>,
      cell: (row) => <TableCell width="120px">{row.tripDate}</TableCell>,
    },
    {
      id: "driverId",
      name: <TableHead width="120px">Driver ID</TableHead>,
      cell: (row) => <TableCell width="120px">{row.driverID || "-"}</TableCell>,
    },
    {
      id: "routeName",
      name: <TableHead width="130px" position="left">Route Name</TableHead>,
      cell: (row) => <TableCell width="130px" position="left"><Link to={`tel:${row.mobileNo}`} className="hover:text-primary-blue" title="Call Driver">{row.routeName}</Link></TableCell>,
    },
    {
      id: "shiftTime",
      name: <TableHead centered>Shift Time</TableHead>,
      cell: (row) => <TableCell textCenter>{row.shiftTime}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead position="left">Trip Type</TableHead>,
      cell: (row) => <TableCell position="left">{row.tripType}</TableCell>,
    },
    {
      id: "liveStatus",
      name: <TableHead width="600px">Live Status</TableHead>,
      cell: (row) => (
        <TableCell width="600px">
          <div className=" w-full flex justify-center items-center mr-8">
            <div className="flex w-[600px] justify-center">
              {row.liveTracking.map((status, index) => (
                <div className="flex items-center" key={index}>
                  {checkStatus(status.picked, status.employee.employeeName, status.pickedTime ? status.pickedTime : status.pickupTime, status.pickupLocation)}
                  {index !== row.liveTracking.length - 1 && (
                    <div
                      style={{
                        width: `calc((600px - ${row.liveTracking.length * statusDivWidth
                          }px) / ${row.liveTracking.length - 1})`,
                      }}
                      className="h-[2px] bg-[#cbcdd2]"
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </TableCell>
      ),
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <Table columns={columns} rows={data.data} />
    </div>
  );
}
