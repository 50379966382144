import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import CreateSuperAdmin from "./CreateSuperAdmin";
import CreateAdmin from "./CreateAdmin";
import CreateSuperVisor from "./CreateSupervisor";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function CreateTabNavigation() {
  const [activeKey, setActiveKey] = useState("superAdmin");

  useEffect(() => {
    const key = sessionStorage.getItem("createAdminTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("createAdminTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: "superAdmin",
      label: (
        <LabelForTabs isActive={activeKey === "superAdmin"}>
          Super Admin
        </LabelForTabs>
      ),
      children: <CreateSuperAdmin />,
    },
    {
      key: "admin",
      label: (
        <LabelForTabs isActive={activeKey === "admin"}>
          Admin
        </LabelForTabs>
      ),
      children: <CreateAdmin />,
    },
    {
      key: "superVisor",
      label: (
        <LabelForTabs isActive={activeKey === "superVisor"}>
          Super Visor
        </LabelForTabs>
      ),
      children: <CreateSuperVisor />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default CreateTabNavigation;
