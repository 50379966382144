import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";

export default function TicketManagementTable({ data }) {

  const columns = [
    {
      id: "ticketId",
      name: <TableHead>Ticket ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link className="text-primary-blue" to={`/ticket-management/${row.ticketID}`}>
            {row.ticketID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "employeeId",
      name: <TableHead position="left">Employee ID</TableHead>,
      cell: (row) => <TableCell position="left">{row.employeeID}</TableCell>,
    },
    {
      id: "employeeName",
      name: <TableHead position="left" width="120px">Employee Name</TableHead>,
      cell: (row) => <TableCell position="left" width="120px">{row.employeeName}</TableCell>,
    },
    {
      id: "companyName",
      name: <TableHead width="170px" position="left">Company Name</TableHead>,
      cell: (row) => <TableCell width="170px" position="left">{row.companyName}</TableCell>,
    },
    {
      id: "ticketType",
      name: <TableHead position="left" width="170px">Ticket Type</TableHead>,
      cell: (row) => <TableCell position="left" width="170px">{row.ticketType}</TableCell>,
    },
    {
      id: "raisedDate",
      name: <TableHead>Raised Date</TableHead>,
      cell: (row) => <TableCell>{row.raisedDate}</TableCell>,
    },
    {
      id: "raisedTime",
      name: <TableHead width="130px">Raised Time</TableHead>,
      cell: (row) => <TableCell width="130px">{row.raisedTime}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead>Status</TableHead>,
      cell: (row) =>
        <TableCell>
          <StatusCard status={row.ticketStatus} />
        </TableCell>,
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <Table columns={columns} rows={data} />
    </div>
  );
}
