import axios from "axios";

const Expense = {
  createExpenses: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}passbook/createExpenses`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getExpenses: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}passbook/getExpenses`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Expense;
