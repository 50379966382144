export default function TableCell({
  children,
  textCenter,
  position = "center",
  width = " 140px ",
  height = "40px",
}) {
  return (
    <div
      style={{
        width: width,
        justifyContent: position,
        minHeight: height,
      }}
      className={`flex items-center ${textCenter ? "text-center" : "text-left"} h-auto  pl-4`}
    >
      {children}
    </div>
  );
}
