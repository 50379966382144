import { useLocation } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import search from "../../assets/search.svg";
import Mandate from "./Mandate";

export default function Input({
    label,
    type,
    placeholder,
    value,
    error,
    onChange,
    defaultValue,
    width,
    mandate,
    disabled,
    readOnly,
    ...other
}) {

    const inputStyle = {
        width: width || "100%",
    };

    const location = useLocation();
    const url = location.pathname;

    return (
        <div
            className={`flex flex-col min-w-[200px]  w-full ${url === "/login" || url === "/forgot-password" || url === "/reset-password" ? " w-full" : " max-w-[300px] "}`}
            style={{
                width: inputStyle.width
            }}
        >
            {label && (
                <div className="h-fit">
                    <label
                        className="text-[#344054] text-[15px] pl-1 font-medium"
                        htmlFor={label}
                    >
                        {label}<Mandate mandate={mandate} />
                    </label>
                </div>
            )}
            <div>
                {type === "search" ? (
                    <>
                        <div
                            className={`flex items-center border ${disabled && !readOnly && "bg-[#eee]"} ${error ? "border-[#F04438]" : "border-border-gray"} rounded-lg w-full h-[40px] p-1 bg-white`}
                        >
                            <img src={search} alt="search" className="px-1" />
                            <input
                                type="text"
                                name={label}
                                id={label}
                                className={`w-full h-full px-1 border-none outline-none placeholder-placeholder-gray ${value === "" ? "text-sm" : "text-base"}`}
                                style={{
                                    width: inputStyle.width
                                }}
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                disabled={disabled || readOnly}
                                {...other}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <input
                            type={type}
                            name={label}
                            id={label}
                            className={`${disabled && !readOnly && "bg-[#eee]"} ${readOnly && "pointer-events-none disabled:bg-white"} border ${!value && error ? "border-[#F04438]" : "border-border-gray"} ${value === "" ? "text-sm" : "text-base"} text-text-black placeholder-placeholder-gray outline-none rounded-lg pb-[1px] pl-2 h-[40px] focus:border-[#1849A9]`}
                            style={{
                                width: inputStyle.width
                            }}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            {...other}
                        />
                        {!value && error && (
                            <ErrorMessage>
                                {error}
                            </ErrorMessage>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
