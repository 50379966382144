import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Typography, message } from "antd";
import { Card, Popover } from "antd";
import { Breadcrumbs } from "@mui/material";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Upload from "../../components/reusable/Upload";
import edit from "../../assets/edit.svg";
import edit_pencil from "../../assets/edit_pencil.svg";
import SuccessModal from "../../components/modals/SuccessModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import Button from "../../components/reusable/Button";
import profile from "../../assets/profile_avatar.svg";

import DateUtils from "../../services/utility/DateUtils";
import Driver from "../../services/api/driverApi";
import Common from "../../services/api/commonApi";

export default function DriverDetailsPage() {

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const paramsDriverID = params.get("id");

    const [driverDetails, setDriverDetails] = useState("");

    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editPersonalDetailsModal, setEditPersonalDetailsModal] = useState(false);
    const [editAddressDetailsModal, setEditAddressDetailsModal] = useState(false);
    const [editDocumentDetailsModal, setEditDocumentDetailsModal] = useState(false);

    const [isPermanentAddressSame, setIsPermanentAddressSame] = useState(false);
    const [profileImage, setProfileImage] = useState(undefined);
    const [shiftTime, setShiftTime] = useState("");
    const profileImageRef = useRef();

    const [routeId, setRouteId] = useState("");

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    // Personal Details
    const [driverID, setDriverID] = useState("");
    const [driverName, setDriverName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [vehicleName, setVehicleName] = useState("");
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [driverStatus, setDriverStatus] = useState("");

    // Temporary Address States
    const [temporaryStreetAddress, setTemporaryStreetAddress] = useState("");
    const [temporaryHouseNumber, setTemporaryHouseNumber] = useState("");
    const [temporaryState, setTemporaryState] = useState("");
    const [temporaryDistrict, setTemporaryDistrict] = useState("");
    const [temporaryPincode, setTemporaryPincode] = useState("");

    // Permanent Address States
    const [permanentStreetAddress, setPermanentStreetAddress] = useState("");
    const [permanentHouseNumber, setPermanentHouseNumber] = useState("");
    const [permanentState, setPermanentState] = useState("");
    const [permanentDistrict, setPermanentDistrict] = useState("");
    const [permanentPincode, setPermanentPincode] = useState("");

    // Error States
    const [driverIDError, setDriverIDError] = useState("");
    const [driverNameError, setDriverNameError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [vehicleNameError, setVehicleNameError] = useState("");
    const [vehicleNumberError, setVehicleNumberError] = useState("");
    const [vehicleTypeError, setVehicleTypeError] = useState("");
    const [bloodGroupError, setBloodGroupError] = useState("");
    const [driverStatusError, setDriverStatusError] = useState("");

    const [temporaryStreetAddressError, setTemporaryStreetAddressError] = useState("");
    const [temporaryHouseNumberError, setTemporaryHouseNumberError] = useState("");
    const [temporaryStateError, setTemporaryStateError] = useState("");
    const [temporaryDistrictError, setTemporaryDistrictError] = useState("");
    const [temporaryPincodeError, setTemporaryPincodeError] = useState("");

    const [permanentStreetAddressError, setPermanentStreetAddressError] = useState("");
    const [permanentHouseNumberError, setPermanentHouseNumberError] = useState("");
    const [permanentStateError, setPermanentStateError] = useState("");
    const [permanentDistrictError, setPermanentDistrictError] = useState("");
    const [permanentPincodeError, setPermanentPincodeError] = useState("");

    const [tempStateList, setTempStateList] = useState([]);
    const [permStateList, setPermStateList] = useState([]);
    const [tempDistrictList, setTempDistrictList] = useState([]);
    const [permDistrictList, setPermDistrictList] = useState([]);
    const [vehicleTypesList, setVehicleTypesList] = useState([]);

    const [createdDate, setCreatedDate] = useState("");
    const [createdTime, setCreatedTime] = useState("");

    const [aadharImage, setAadharImage] = useState("");

    const [createdBy, setCreatedBy] = useState("");

    console.log(driverDetails);

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    };

    const getVehicleName = (id, object) => {
        if (object === undefined) {
            if (id === 1) {
                return "SEDAN";
            } else if (id === 2) {
                return "SUV";
            } else if (id === 3) {
                return "TEMPO";
            } else if (id === 4) {
                return "COACH";
            }
        }
        if (id === 1) {
            return { id: 1, name: "SEDAN" };
        } else if (id === 2) {
            return { id: 1, name: "SUV" };
        } else if (id === 3) {
            return { id: 1, name: "TEMPO" };
        } else if (id === 4) {
            return { id: 1, name: "COACH" };
        }
        return "-";
    };

    const getDriverStatus = (id) => {
        if (id === "Part Time") {
            return { id: "Part Time", name: "Part Time" };
        } else if (id === "Dedicated") {
            return { id: "Dedicated", name: "Dedicated" };
        }
    };

    useEffect(() => {
        const fetchDriverData = async () => {
            try {
                const oneDriver = await Driver.getDriverOne({
                    id: paramsDriverID
                });
                setDriverDetails(oneDriver.data);
                setDriverID(oneDriver.data.id);
                setDriverName(oneDriver.data.driverName);
                setMobileNumber(oneDriver.data.mobileNo);
                setVehicleName(oneDriver.data.vehicleName);
                setVehicleNumber(oneDriver.data.vehicleNo);
                setVehicleType(getVehicleName(oneDriver.data.vehicleTypeID, "object"));
                setBloodGroup(oneDriver.data.bloodGroup);
                setDriverStatus(getDriverStatus(oneDriver.data.driverStatus));
                setTemporaryStreetAddress(oneDriver.data.tempStreetAddress);
                setTemporaryHouseNumber(oneDriver.data.tempHouseNo);
                setTemporaryState(oneDriver.data.selectedTemState);
                setTemporaryDistrict(oneDriver.data.selectedTemDistrict);
                setTemporaryPincode(oneDriver.data.tempPincode);
                setPermanentStreetAddress(oneDriver.data.perStreetAddress);
                setPermanentHouseNumber(oneDriver.data.perHouseNo);
                setPermanentState(oneDriver.data.selectedPerState);
                setPermanentDistrict(oneDriver.data.selectedPerDistrict);
                setPermanentPincode(oneDriver.data.perPincode);
                setIsPermanentAddressSame(oneDriver.data.isAddressPermanent);
                setAadharImage(oneDriver.data.adhaarUrls);
                setCreatedDate(DateUtils.convertDate(oneDriver.data.createdAt));
                setCreatedTime(DateUtils.convertTime(oneDriver.data.createdAt));
                setCreatedBy(oneDriver.data.ccUser?.name);
            } catch (error) {
                console.error("Error fetching driver data", error);
            }
        };
        fetchDriverData();
    }, []);
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await Common.state();
                setTempStateList(response);
            } catch (err) {
                console.log(err);
            }
        };
        fetchStates();
    }, []);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await Common.state();
                setPermStateList(response);
            } catch (err) {
                console.log(err);
            }
        };
        fetchStates();
    }, []);

    useEffect(() => {
        const fetchVehicleTypes = async () => {
            try {
                const response = await Common.vehicleType();
                setVehicleTypesList(response);
            } catch (err) {
                console.log(err);
            }
        };

        fetchVehicleTypes();
    }, []);

    useEffect(() => {
        const id = temporaryState?.id;
        const fetchDistricts = async () => {
            try {
                const response = await Common.district(id);
                setTempDistrictList(response);
            } catch (err) {
                console.log(err);
            }
        };
        fetchDistricts();
    }, [temporaryState]);

    useEffect(() => {
        const id = permanentState?.id;
        const fetchDistricts = async () => {
            try {
                const response = await Common.district(id);
                setPermDistrictList(response);
            } catch (err) {
                console.log(err);
            }
        };
        fetchDistricts();
    }, [permanentState]);

    const driverStatusList = [
        { name: "Dedicated", id: "Dedicated" },
        { name: "Part Time", id: "Part Time" },
    ];

    const validatePersonalDetails = () => {
        let isValid = true;
        // Personal Details

        // Validation for Driver Name
        if (!driverName || driverName.trim() === "") {
            setDriverNameError("Enter Driver Name");
            isValid = false;
        } else {
            setDriverNameError("");
        }

        // Validation for Mobile Number
        if (!mobileNumber || mobileNumber.trim() === "") {
            setMobileNumberError("Enter Mobile Number");
            isValid = false;
        } else if (mobileNumber.length !== 10) {
            setMobileNumberError("Enter a valid mobile number");
            isValid = false;
        } else {
            setMobileNumberError("");
        }

        // Validation for Vehicle Name
        if (!vehicleName || vehicleName.trim() === "") {
            setVehicleNameError("Enter Vehicle Name");
            isValid = false;
        } else {
            setVehicleNameError("");
        }

        // Validation for Vehicle Number
        if (!vehicleNumber || vehicleNumber.trim() === "") {
            setVehicleNumberError("Enter Vehicle Number");
            isValid = false;
        } else {
            setVehicleNumberError("");
        }

        // Validation for Vehicle Type
        if (!vehicleType) {
            setVehicleTypeError("Select Vehicle Type");
            isValid = false;
        } else {
            setVehicleTypeError("");
        }

        return isValid;
    };

    const handleUpdateDriver = async (e) => {
        e.preventDefault();
        if (validatePersonalDetails()) {
            try {
                const data = {
                    id: paramsDriverID,
                    driverID: driverID,
                    driverName: driverName,
                    mobileNo: mobileNumber,
                    vehicleName: vehicleName,
                    vehicleNo: vehicleNumber,
                    vehicleType: vehicleType?.id,
                    bloodGroup: bloodGroup,
                    driverStatus: driverStatus?.id,
                    tempStreetAddress: temporaryStreetAddress,
                    tempHouseNo: temporaryHouseNumber,
                    selectedTemState: temporaryState,
                    selectedTemDistrict: temporaryDistrict,
                    tempPincode: temporaryPincode,
                    perStreetAddress: permanentStreetAddress,
                    perHouseNo: permanentHouseNumber,
                    selectedPerState: permanentState,
                    selectedPerDistrict: permanentDistrict,
                    perPincode: permanentPincode,
                    isAddressPermanent: isPermanentAddressSame,
                    // upiId: upiId,
                    // aadharNum: aadharNum,
                    // adhaarUrls: adhaarUrls,
                    // licenseNumber: licenseNumber,
                    // dlUrls: dlUrls,
                    // licExpDate: licenseExpDate,
                    // permitExpDate: permitExpDate,
                    // insExpDate: insuranceExpDate,
                    // taxExpDate: taxExpDate,
                    // pcUrls: pcUrls,
                    // cvUrls: cvUrls,
                    // policeCUrls: policeCUrls,
                    // fcUrls: fcUrls,
                    // rcUrls: rcUrls,
                    // selectedImage: selectedImage,
                }
                const response = await Driver.updateDriver(data);
                if (response.code === 200) {
                    message.success("Driver Updated successfully");
                    setEditDocumentDetailsModal(false);
                } else {
                    message.error(response.message);
                }
            } catch (error) {
                console.error("Error updating driver data", error);
            }
        }
    };

    const BreadcrumbsUrl = () => (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link color="inherit" href="/" onClick={handleClick}>
                    Trip Management
                </Link>
                <Link
                    color="inherit"
                    href="/getting-started/installation/"
                    onClick={handleClick}
                >
                    Not Allotted
                </Link>
                <Typography color="text.primary">LC007</Typography>
            </Breadcrumbs>
        </>
    );

    const menuIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="16"
                viewBox="0 0 4 16"
                fill="none"
            >
                <path
                    d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        );
    };

    const actionButton = (
        <button className="flex flex-col">
            <button className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1" onClick={() => setEditPersonalDetailsModal(!editPersonalDetailsModal)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                >
                    <path
                        d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <p>Edit</p>
            </button>

            <button className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1" onClick={handleDeleteModal}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                >
                    <path
                        d="M2.09473 3.49984H3.28863M3.28863 3.49984H12.8399M3.28863 3.49984V11.6665C3.28863 11.9759 3.41442 12.2727 3.63832 12.4915C3.86222 12.7103 4.1659 12.8332 4.48254 12.8332H10.4521C10.7687 12.8332 11.0724 12.7103 11.2963 12.4915C11.5202 12.2727 11.646 11.9759 11.646 11.6665V3.49984H3.28863ZM5.0795 3.49984V2.33317C5.0795 2.02375 5.20528 1.72701 5.42918 1.50821C5.65308 1.28942 5.95676 1.1665 6.2734 1.1665H8.66122C8.97786 1.1665 9.28154 1.28942 9.50544 1.50821C9.72934 1.72701 9.85513 2.02375 9.85513 2.33317V3.49984M6.2734 6.4165V9.9165M8.66122 6.4165V9.9165"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <p>Delete</p>
            </button>
        </button>
    );

    // function for profile image
    function handleProfileImage(event) {
        if (event) {
            setProfileImage(event.target.files[0]);
        }
    };

    function handleDeleteModal() {
        setConfirmModal(!confirmModal);
    }
    // function for delete inside action
    async function handleDelete() {
        try {
            const response = await Driver.deleteDriver(
                { id: paramsDriverID }
            );
            if (response.code === 200) {
                setConfirmModal(!confirmModal);
                setTimeout(() => {
                    setDeleteModal(!deleteModal);
                    navigate("/driver-management");
                }, 2000);
            }
        } catch (error) {
            console.error("Error deleting driver", error);
        }
    };

    const PersonalDetails = () => {
        return (
            <>
                <div className=" w-full px-1 flex justify-center items-center ">
                    <Card className=" w-full  mt-6  shadow-md  ">
                        <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                            <div className=" w-full flex justify-between items-center">
                                <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                                    Personal Details
                                </div>
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                                <Input
                                    label={"Driver ID"}
                                    placeholder={"-"}
                                    value={driverDetails?.driverID}
                                    readOnly
                                />
                                <Input
                                    label={"Driver Name"}
                                    placeholder={"-"}
                                    value={driverDetails?.driverName}
                                    readOnly
                                />
                                <MobileNumberInput
                                    label={"Driver Mobile Number"}
                                    placeholder={"-"}
                                    value={driverDetails?.mobileNo}
                                    readOnly
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                                <Input
                                    label={"Vehicle Name"}
                                    placeholder={"-"}
                                    value={driverDetails?.vehicleName}
                                    readOnly
                                />
                                <Input
                                    label={"Vehicle Number"}
                                    placeholder={"-"}
                                    value={driverDetails?.vehicleNo}
                                    readOnly
                                />
                                <DropDown
                                    label={"Vehicle Type"}
                                    placeholder={"-"}
                                    defaultValue={getVehicleName(driverDetails?.vehicleTypeID)}
                                    readOnly
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
                                <Input
                                    label={"Blood Group"}
                                    placeholder={"-"}
                                    value={driverDetails?.bloodGroup}
                                    readOnly
                                />
                                <DropDown
                                    label={"Driver Status"}
                                    placeholder={"-"}
                                    defaultValue={driverDetails?.driverStatus}
                                    readOnly
                                />
                                <div className="w-[300px] hidden md:block" />
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        )
    }

    const AddressDetails = () => {
        return (
            <>
                <div className="w-full px-1 flex justify-center items-center">
                    <Card className="w-full mt-6 shadow-md">
                        <div className="w-full flex flex-col gap-3 lg:gap-6 rounded-md">
                            <div className="w-full flex justify-between items-center">
                                <div className="w-full text-start text-lg mt-1 font-medium text-gray-800">
                                    Address Details
                                </div>
                            </div>
                            <div className="font-medium text-base underline">
                                <p>Temporary Address</p>
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <Input
                                    label={"Street Address"}
                                    placeholder={"-"}
                                    value={driverDetails?.tempStreetAddress}
                                    readOnly
                                />
                                <Input
                                    label={"House Number"}
                                    placeholder={"-"}
                                    value={driverDetails?.tempHouseNo}
                                    readOnly
                                />
                                <DropDown
                                    label={"State"}
                                    placeholder={"-"}
                                    defaultValue={driverDetails?.selectedTemState}
                                    readOnly
                                />
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <DropDown
                                    label={"District"}
                                    placeholder={"-"}
                                    defaultValue={driverDetails?.selectedTemDistrict}
                                    readOnly
                                />
                                <Input
                                    label={"Enter Pincode"}
                                    placeholder={"-"}
                                    value={driverDetails?.tempPincode}
                                    readOnly
                                />
                                <div className="w-[300px]" />
                            </div>
                            <div className="flex flex-col w-full">
                                <p className="text-base font-medium underline">Permanent Address</p>
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <Input
                                    label={"Street Address"}
                                    placeholder={"-"}
                                    value={isPermanentAddressSame ? driverDetails?.tempStreetAddress : driverDetails?.perStreetAddress}
                                    readOnly
                                />
                                <Input
                                    label={"House Number"}
                                    placeholder={"-"}
                                    value={isPermanentAddressSame ? driverDetails?.tempHouseNo : driverDetails?.perHouseNo}
                                    readOnly
                                />
                                <DropDown
                                    label={"State"}
                                    placeholder={"-"}
                                    defaultValue={isPermanentAddressSame ? driverDetails?.selectedTemState : driverDetails?.selectedPerState}
                                    readOnly
                                />
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <DropDown
                                    label={"District"}
                                    placeholder={"-"}
                                    defaultValue={isPermanentAddressSame ? driverDetails?.selectedTemDistrict : driverDetails?.selectedPerDistrict}
                                    readOnly
                                />
                                <Input
                                    label={"Enter Pincode"}
                                    placeholder={"-"}
                                    value={isPermanentAddressSame ? driverDetails?.tempPincode : driverDetails?.perPincode}
                                    readOnly
                                />
                                <div className="w-[300px]" />
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    const DocumentDetails = () => {
        return (
            <>
                <div className=" w-full px-1 flex justify-center items-center ">
                    <Card className=" w-full  mt-6  shadow-md  ">
                        <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                            <div className=" w-full flex justify-between items-center">
                                <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                                    Document Details
                                </div>
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                                <Input
                                    label={"UPI ID"}
                                    placeholder={"-"}
                                    value={driverDetails?.upiId}
                                    readOnly
                                />
                                <Input
                                    label={"Aadhar Number"}
                                    placeholder={"-"}
                                    value={driverDetails?.aadharNum}
                                    readOnly
                                />
                                <Upload
                                    label={"Aadhar Card"}
                                    width={"300px"}
                                    viewLink={driverDetails?.adhaarUrls}
                                    viewName={"Aadhar Card Image"}
                                    readOnly
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                                <Input
                                    label={"Driving License"}
                                    placeholder={"-"}
                                    value={driverDetails?.driverID}
                                    readOnly
                                />
                                <Upload
                                    label={"Driving License"}
                                    width={"300px"}
                                    viewLink={driverDetails?.dlUrls}
                                    viewName={"Driving License Image"}
                                    readOnly
                                />
                                <DateInput
                                    label={"License Batch Exp"}
                                    placeholder={"-"}
                                    value={driverDetails?.licExpDate}
                                    readOnly
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <DateInput
                                    label={"Permit Exp Date"}
                                    placeholder={"-"}
                                    value={driverDetails?.permitExpDate}
                                    readOnly
                                />
                                <DateInput
                                    label={"Insurance Exp Date"}
                                    placeholder={"-"}
                                    value={driverDetails?.insExpDate}
                                    readOnly
                                />
                                <DateInput
                                    label={"Tax Exp Date"}
                                    placeholder={"-"}
                                    value={driverDetails?.taxExpDate}
                                    readOnly
                                />
                            </div>
                            <p className="text-lg font-semibold">Proof Upload</p>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                                <Upload
                                    label={"Pollution Certificate"}
                                    width={"300px"}
                                    viewLink={driverDetails?.pcUrls}
                                    viewName={"Pollution Certificate"}
                                    readOnly
                                />
                                <Upload
                                    label={"Covid Vaccine Certificate"}
                                    width={"300px"}
                                    viewLink={driverDetails?.cvUrls}
                                    viewName={"Covid Vaccine Certificate"}
                                    readOnly
                                />
                                <Upload
                                    label={"Police Certificate"}
                                    width={"300px"}
                                    viewLink={driverDetails?.policeCUrls}
                                    viewName={"Police Certificate"}
                                    readOnly
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                                <Upload
                                    label={"FC Copy"}
                                    width={"300px"}
                                    viewLink={driverDetails?.fcUrls}
                                    viewName={"FC Copy Image"}
                                    readOnly
                                />
                                <Upload
                                    label={"RC Copy"}
                                    width={"300px"}
                                    viewLink={driverDetails?.rcUrls}
                                    viewName={"RC Copy Image"}
                                    readOnly
                                />
                                <div className="w-[300px]" />
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    const CreateDetails = () => {
        return (
            <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
                    <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                        Creater Details
                    </div>
                    <>
                        <div className=" w-full flex-wrap gap-5 h-fit py-10 flex justify-between items-center">
                            <Input
                                label={"Created by"}
                                placeholder={"-"}
                                value={createdBy || "-"}
                                disabled={true}
                            />
                            <DateInput
                                label={"Created date"}
                                placeholder={"-"}
                                value={createdDate}
                                disabled={true}
                            />
                            <TimeInput
                                label={"Created time"}
                                placeholder={"-"}
                                value={createdTime}
                                disabled={true}
                            />
                        </div>
                    </>
                </Card>
            </div>
        );
    };

    // below are for edit modals
    const PersonalDetailsModal = () => {
        return (
            <>
                <div className=" w-full  mt-6 mb-10  ">
                    <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                        Edit Driver
                    </div>
                    <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
                        <p className="text-base font-medium underline">Personal Details</p>
                        {/* Profile Image */}
                        <div className="flex flex-col items-center justify-center w-full">
                            <div className="relative -top-4">
                                {profileImage === undefined ? (
                                    <img width={100} src={profile} alt="profile" className="w-32 h-32" />
                                ) : (
                                    <img
                                        src={URL.createObjectURL(profileImage)}
                                        alt="profile"
                                        className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                                    />
                                )}
                                <button
                                    className="absolute right-1 top-3"
                                    onClick={() => profileImageRef.current.click()}
                                >
                                    <img src={edit_pencil} alt="edit" />
                                    <input
                                        type="file"
                                        className="hidden"
                                        ref={profileImageRef}
                                        onChange={handleProfileImage}
                                        accept="image/*"
                                    />
                                </button>
                            </div>
                        </div>
                        <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                            <Input
                                label={"Enter Driver Name"}
                                placeholder={"Enter driver name"}
                                value={driverName}
                                onChange={(e) => setDriverName(e.target.value)}
                                mandate={true}
                                error={driverNameError}
                            />
                            <MobileNumberInput
                                label={"Mobile Number"}
                                value={mobileNumber}
                                setValue={setMobileNumber}
                                mandate={true}
                                error={mobileNumberError}
                            />
                            <Input
                                label={"Vehicle Name"}
                                placeholder={"Enter vehicle name"}
                                value={vehicleName}
                                onChange={(e) => setVehicleName(e.target.value)}
                                mandate={true}
                                error={vehicleNameError}
                            />
                        </div>
                        <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                            <Input
                                label={"Vehicle Number"}
                                placeholder={"Enter vehicle number"}
                                value={vehicleNumber}
                                onChange={(e) => setVehicleNumber(e.target.value)}
                                mandate={true}
                                error={vehicleNumberError}
                            />
                            <DropDown
                                label={"Vehicle Type"}
                                placeholder={"Select vehicle type"}
                                displayValue={"name"}
                                data={vehicleTypesList}
                                value={vehicleType}
                                setValue={setVehicleType}
                                mandate={true}
                                error={vehicleTypeError}
                            />
                            <Input
                                label={"Blood Group"}
                                placeholder={"Enter blood group"}
                                value={bloodGroup}
                                onChange={(e) => setBloodGroup(e.target.value)}
                                error={bloodGroupError}
                            />
                        </div>
                        <div className=" w-full flex flex-wrap lg:flex-nowrap items-center gap-3  lg:gap-10 ">
                            <DropDown
                                label={"Driver Status"}
                                placeholder={"Select driver status"}
                                displayValue={"name"}
                                data={driverStatusList}
                                value={driverStatus}
                                setValue={setDriverStatus}
                                error={driverStatusError}
                                width={"290px"}
                            />
                        </div>
                    </div>
                    <div className=" w-full flex  justify-center items-center">
                        <Button
                            text={"Next"}
                            width={"170px"}
                            onClick={() => {
                                if (validatePersonalDetails()) {
                                    setEditPersonalDetailsModal(false);
                                    setEditAddressDetailsModal(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    const AddressDetailsModal = () => {
        return (
            <>
                <div className=" w-full  mt-6 mb-10  ">
                    <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                        Edit Driver
                    </div>
                    <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
                        <p className="text-base font-medium underline">Temporary Address </p>
                        <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                            <Input
                                label={"Street Address"}
                                placeholder={"Enter street address"}
                                value={temporaryStreetAddress}
                                onChange={(e) => setTemporaryStreetAddress(e.target.value)}
                                error={temporaryStreetAddressError}
                            />
                            <Input
                                label={"House Number"}
                                placeholder={"Enter house number"}
                                value={temporaryHouseNumber}
                                onChange={(e) => setTemporaryHouseNumber(e.target.value)}
                                error={temporaryHouseNumberError}
                            />
                            <DropDown
                                label={"State"}
                                placeholder={"Select state"}
                                displayValue={"stateName"}
                                data={tempStateList}
                                value={temporaryState}
                                setValue={setTemporaryState}
                                error={temporaryStateError}
                            />
                        </div>
                        <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                            <DropDown
                                label={"District"}
                                placeholder={"Select district"}
                                displayValue={"districtName"}
                                data={tempDistrictList}
                                value={temporaryDistrict}
                                setValue={setTemporaryDistrict}
                                error={temporaryDistrictError}
                                width={"290px"}
                            />
                            <Input
                                label={"Enter Pincode"}
                                placeholder={"Enter pincode"}
                                value={temporaryPincode}
                                onChange={(e) => setTemporaryPincode(e.target.value)}
                                error={temporaryPincodeError}
                                width={"290px"}
                            />
                            <div className="w-[290px]" />
                        </div>
                    </div>
                    <div className=" w-full flex flex-col  gap-3 lg:gap-6 pb-10">
                        <div>
                            <p className="text-base font-medium underline">Permanent Address </p>
                            <div className="flex items-center pt-4">
                                <input
                                    type="checkbox"
                                    id="permanentAddressSame"
                                    checked={isPermanentAddressSame}
                                    onChange={(e) => setIsPermanentAddressSame(e.target.checked)}
                                />
                                <label htmlFor="permanentAddressSame" className="ml-2 font-medium text-base">
                                    Is Permanent Address same as Temporary Address?
                                </label>
                            </div>
                        </div>
                        {!isPermanentAddressSame && (
                            <>
                                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                    <Input
                                        label={"Street Address"}
                                        placeholder={"Enter street address"}
                                        value={permanentStreetAddress}
                                        onChange={(e) => setPermanentStreetAddress(e.target.value)}
                                        error={permanentStreetAddressError}
                                    />
                                    <Input
                                        label={"House Number"}
                                        placeholder={"Enter house number"}
                                        value={permanentHouseNumber}
                                        onChange={(e) => setPermanentHouseNumber(e.target.value)}
                                        error={permanentHouseNumberError}
                                    />
                                    <DropDown
                                        label={"State"}
                                        placeholder={"Select state"}
                                        displayValue={"stateName"}
                                        data={permStateList}
                                        value={permanentState}
                                        setValue={setPermanentState}
                                        error={permanentStateError}
                                    />
                                </div>
                                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                    <DropDown
                                        label={"District"}
                                        placeholder={"Select district"}
                                        displayValue={"districtName"}
                                        data={permDistrictList}
                                        value={permanentDistrict}
                                        setValue={setPermanentDistrict}
                                        error={permanentDistrictError}
                                        width={"290px"}
                                    />
                                    <Input
                                        label={"Enter Pincode"}
                                        placeholder={"Enter pincode"}
                                        value={permanentPincode}
                                        onChange={(e) => setPermanentPincode(e.target.value)}
                                        error={permanentPincodeError}
                                        width={"290px"}
                                    />
                                    <div className="w-[290px]" />
                                </div>
                            </>
                        )}
                    </div>
                    <div className=" w-full flex gap-6 justify-center items-center">
                        <Button
                            text={"Back"}
                            width={"140px"}
                            onClick={() => {
                                setEditAddressDetailsModal(false);
                                setEditPersonalDetailsModal(true);
                            }}
                        />
                        <Button
                            text={"Next"}
                            width={"140px"}
                            onClick={() => {
                                setEditAddressDetailsModal(false);
                                setEditDocumentDetailsModal(true);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    const DocumentDetailsModal = () => {
        return (
            <>
                <div className=" w-full  mt-6 mb-10  ">
                    <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                        Edit Driver
                    </div>
                    <div className=" w-full px-1 flex justify-center items-center ">
                        <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md py-6">
                            <p className="text-base font-medium underline">Document Details</p>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                                <Input
                                    label={"UPI ID"}
                                    placeholder={"Enter UPI ID"}
                                    value={routeId}
                                    onChange={(e) => setRouteId(e.target.value)}
                                />
                                <Input
                                    label={"Aadhar Number"}
                                    placeholder={"Enter aadhar number"}
                                    value={routeId}
                                    onChange={(e) => setRouteId(e.target.value)}
                                />
                                <Upload
                                    label={"Aadhar Card"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                                <Input
                                    label={"Driving License"}
                                    placeholder={"Enter driving license"}
                                    value={routeId}
                                    onChange={(e) => setRouteId(e.target.value)}
                                />
                                <Upload
                                    label={"Driving License"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                                <DateInput
                                    label={"License Batch Exp"}
                                    placeholder={"Select license batch exp"}
                                    value={shiftTime}
                                    setValue={setShiftTime}
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                                <DateInput
                                    label={"Permit Exp Date"}
                                    placeholder={"Select permit exp"}
                                    value={shiftTime}
                                    setValue={setShiftTime}
                                />
                                <DateInput
                                    label={"Insurance Exp Date"}
                                    placeholder={"Select insurance exp"}
                                    value={shiftTime}
                                    setValue={setShiftTime}
                                />
                                <DateInput
                                    label={"Tax Exp Date"}
                                    placeholder={"Select tax exp"}
                                    value={shiftTime}
                                    setValue={setShiftTime}
                                />
                            </div>
                            <p className="text-base font-medium underline">Proof Upload</p>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3">
                                <Upload
                                    label={"Pollution Certificate"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                                <Upload
                                    label={"Covid Vaccine Certificate"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                                <Upload
                                    label={"Police Certificate"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                            </div>
                            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3">
                                <Upload
                                    label={"FC Copy"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                                <Upload
                                    label={"RC Copy"}
                                    selectedFile={file}
                                    setSelectedFile={setFile}
                                    inputValue={fileName}
                                    setInputValue={setFileName}
                                    width={"300px"}
                                />
                                <div className="w-[300px]" />
                            </div>
                        </div>
                    </div>
                    <div className=" w-full flex gap-6 justify-center items-center pt-8">
                        <Button
                            text={"Back"}
                            width={"170px"}
                            onClick={() => {
                                setEditDocumentDetailsModal(false);
                                setEditAddressDetailsModal(true);
                            }}
                        />
                        <Button
                            text={"Save Changes"}
                            width={"170px"}
                            onClick={handleUpdateDriver}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <PageLayout
                title={"Driver Management"}
                description={"View your driver details here."}
                buttonWidth={"120px"}
                BreadcrumbsUrl={BreadcrumbsUrl}
            >
                <div className="absolute right-16 top-20">
                    <Popover
                        content={actionButton}
                        placement="leftTop"
                        trigger="click"
                        className=" cursor-pointer"
                    >
                        {menuIcon()}
                    </Popover>
                </div>
                <div className="pr-4">
                    {PersonalDetails()}
                    {AddressDetails()}
                    {DocumentDetails()}
                    {CreateDetails()}
                </div>
            </PageLayout>
            <Modal
                title={
                    <>
                        <img src={edit} width={40} alt="edit" />
                    </>
                }
                visible={editPersonalDetailsModal}
                width={"1000px"}
                onCancel={() => setEditPersonalDetailsModal(false)}
                footer={null}
                centered
            >
                {PersonalDetailsModal()}
            </Modal>
            <Modal
                title={
                    <>
                        <img src={edit} width={40} alt="edit" />
                    </>
                }
                visible={editAddressDetailsModal}
                width={"1000px"}
                onCancel={() => setEditAddressDetailsModal(false)}
                footer={null}
                centered
            >
                {AddressDetailsModal()}
            </Modal>
            <Modal
                title={
                    <>
                        <img src={edit} width={40} alt="edit" />
                    </>
                }
                visible={editDocumentDetailsModal}
                width={"1000px"}
                onCancel={() => setEditDocumentDetailsModal(false)}
                footer={null}
                centered
            >
                {DocumentDetailsModal()}
            </Modal>
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                title={"Delete ?"}
                description={"Do you want to delete this ?"}
                button1={"No"}
                button2={"Yes"}
                onClickButton1={() => setConfirmModal(!confirmModal)}
                onClickButton2={() => { setDeleteModal(!deleteModal); handleDelete(); setConfirmModal(!confirmModal) }}
            />
            <SuccessModal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                animation="delete"
                title={"deleted successfully"}
                onClose={() => navigate("/driver-management")}
            />
        </>
    );
}
