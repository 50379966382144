import { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

export default function NavbarLayout() {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();
  const url = location.pathname;

  useEffect(() => {
    const accessToken = localStorage.getItem("accessTokenFleetrax");

    if (!accessToken) {
      navigate("/login");
    } else {
      if (url === "/") {
        navigate("/home");
      }
    }
  }, []);

  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className=" w-full">
        {/* {children} */}
        <Outlet />
      </div>
    </Navbar>
  );
}
