import axios from "axios";

const Invoice = {
  invoiceTrips: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/invoiceTrips`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  invoiceTripCount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/invoiceTripCount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getOneInvoice: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getOneInvoiceTrip?id=${params.id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  updateInvoice: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}invoice/verify/updateInvoiceTrip`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getAmounts: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTripsCalculation`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTodayPaidAmount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTodayPaidAmount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTodayUnPaidAmount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTodayUnPaidAmount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTotalPaidAmount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTotalPaidAmount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTotalUnPaidAmount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTotalUnPaidAmount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  totalNoOfTrips: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/totalNoOfTrips`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getExpence: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getExpence`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTotalExpence: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}invoice/verify/getTotalExpence`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Invoice;
