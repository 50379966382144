import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import LoginLayout from "../../components/layouts/login/LoginLayout";

export default function ResetPassword() {
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const Valid = () => {
        let isValid = true;
        if (!password) {
            setPasswordError("Please enter password");
            isValid = false;
        } else if (password === "00000000") {
            setPasswordError("Password cannot be old password");
            isValid = false;
        } else {
            setPasswordError("");
        }

        if (!confirmPassword) {
            setConfirmPasswordError("Please enter confirm password");
            isValid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError("Password doesn’t match");
            isValid = false;
        }
        else {
            setConfirmPasswordError("");
        }
        return isValid;
    };

    const handleChangePassword = () => {
        if (Valid()) {
            navigate("/login");
        }
    };

    return (
        <LoginLayout
            title={"Hello Again !"}
            text={"Please enter your new password"}
        >
            <form className="flex flex-col gap-6 justify-center items-center   w-full lg:w-[400px]  px-6 lg:px-0 pt-2  lg:pt-12">
                {/* email input */}
                <Input
                    label={"New Password"}
                    type={"password"}
                    placeholder={"Enter new password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                />
                {/* password input */}
                <Input
                    label={"Confirm Password"}
                    type={"password"}
                    placeholder={"Enter password again"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={confirmPasswordError}
                />
                {/* sign-in button */}
                <div />
                <Button
                    text="Change Password"
                    onClick={handleChangePassword}
                />
            </form>
        </LoginLayout>
    )
};