import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import InvoiceNotPaid from "./invoive/InvoiceNotPaidOutStation";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function InvoiceTabNavigationOutStation({ companyList }) {
  const [activeKey, setActiveKey] = useState("notPaid");

  useEffect(() => {
    const key = sessionStorage.getItem("outstationInvoiceTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("outstationInvoiceTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: "notPaid",
      label: (
        <LabelForTabs isActive={activeKey === "notPaid"}>
          Not Paid
        </LabelForTabs>
      ),
      children: <InvoiceNotPaid companyList={companyList} activeKey={1} />,
    },
    {
      key: "paid",
      label: (
        <LabelForTabs isActive={activeKey === "paid"}>
          Paid
        </LabelForTabs>
      ),
      children: <InvoiceNotPaid companyList={companyList} activeKey={3} />,
    },
    {
      key: "onHold",
      label: (
        <LabelForTabs isActive={activeKey === "onHold"}>
          On Hold
        </LabelForTabs>
      ),
      children: <InvoiceNotPaid companyList={companyList} activeKey={2} />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default InvoiceTabNavigationOutStation;
