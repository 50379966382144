import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import TableHead from "../reusable/Table/TableHead";
import TableCell from "../reusable/Table/TableCell";
import DateInput from "../reusable/DateInput";
import Table from "../reusable/Table/Table";
import Button from "../reusable/Button";
import { Modal, message } from "antd";
import Input from "../reusable/Input";
import MobileNumberInput from "../reusable/MobileNumberInput";
import addIcon from "../../assets/addIcon.png";
import DateUtils from "../../services/utility/DateUtils";
import Auth from "../../services/api/authApi";
import ConfirmModal from "../modals/ConfirmModal";
import SuccessModal from "../modals/SuccessModal";
import plus from "../../assets/plus.svg";

export default function CreateAdmin() {
  const [adminsList, setAdminsList] = useState([]);

  const [idForEdit, setIdForEdit] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [date, setDate] = useState(DateUtils.getCurrentDate());

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [dateError, setDateError] = useState("");

  const [selectedUser, setSelectedUser] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    fetchAdminData();
  }, []);

  const columns = [
    {
      id: "srNo",
      name: <TableHead>S.No</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link className="text-primary-blue">{row.sNo}</Link>
        </TableCell>
      ),
    },
    {
      id: "name",
      name: <TableHead>Name</TableHead>,
      cell: (row) => <TableCell textCenter>{row.name}</TableCell>,
    },
    {
      id: "mobileNo",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNumber}</TableCell>,
    },
    {
      id: "email",
      name: <TableHead>Email</TableHead>,
      cell: (row) => <TableCell>{row.email}</TableCell>,
    },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <div class="py-3 text-[#393e46] text-center w-full flex justify-center items-center font-medium text-sm font-roboto">
            <button
              type="button"
              onClick={() => {
                handleSelectedRowForEdit(row);
                setShowEditModal(true);
              }}
              class="py-3 text-sm font-roboto uppercase text-[#393e46] font-medium"
            >
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </svg>
            </button>
            <button
              type="button"
              class="py-3 ml-4 text-sm font-roboto uppercase text-[#393e46] font-medium"
              onClick={() => {
                setSelectedUser(row);
                setConfirmModal(true);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"></path>
              </svg>
            </button>
          </div>
        </TableCell>
      ),
    },
  ];

  const isValid = () => {
    let valid = true;
    if (name === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }
    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }
    if (mobileNumber === "") {
      setMobileNumberError("Mobile number is required");
      valid = false;
    } else {
      setMobileNumberError("");
    }
    if (date === "") {
      setDateError("Date is required");
      valid = false;
    } else {
      setDateError("");
    }
    return valid;
  };

  const fetchAdminData = async () => {
    try {
      const params = {
        userType: "admin"
      }
      const data = await Auth.getUsers(params);
      setAdminsList(
        data?.length && data?.map((item, index) => {
          item.sNo = index + 1;
          return item;
        })
      );
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleAddAdmin = async () => {
    try {
      const data = {
        name: name,
        email: email,
        password: password,
        mobileNumber: mobileNumber,
        date: date,
        createdBy: 1,
        userType: "admin",
      }
      const response = await Auth.signUp(data);
      if (response.status) {
        message.success("User added successfully");
        setName("");
        setEmail("");
        setPassword("");
        setMobileNumber("");
        fetchAdminData();
      } else {
        message.error(response.message);
      }
    } catch (err) {
      message.error(err.message);
      console.error(err);
    }
  };

  const handleCreate = () => {
    if (!isValid()) {
      return;
    }
    handleAddAdmin();
    setShowModal(false);
  };

  const handleSelectedRowForEdit = (row) => {
    setIdForEdit(row.id);
    setName(row.name);
    setEmail(row.email);
    setPassword(row.password);
    setMobileNumber(row.mobileNumber);
  };

  const handleEditUser = async () => {
    try {
      const data = {
        id: parseInt(idForEdit),
        name: name,
        email: email,
        password: password,
        mobileNumber: mobileNumber,
        date: date,
        createdBy: 1,
        userType: "admin",
      }
      const response = await Auth.updateUser(data);
      if (response.status) {
        message.success("User updated successfully");
        setShowEditModal(false);
        setName("");
        setEmail("");
        setPassword("");
        setMobileNumber("");
        fetchAdminData();
      } else {
        message.error(response.message);
      }
    } catch (err) {
      message.error(err.message);
      console.error(err);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const params = {
        id: selectedUser?.id,
      }
      const response = await Auth.deleteUser(params);
      if (response.status) {
        setConfirmModal(!confirmModal);
        setDeleteModal(true);
        setSelectedUser("");
        fetchAdminData();
        setTimeout(() => {
          setDeleteModal(false);
        }, 2000);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      message.error(err.message);
      console.error(err);
    }
  };

  return (
    <>
      <div className="w-full removeScrollBar pb-5">
        <div className="flex justify-end w-[98.5%] pr-5 mb-5">
          <div className="flex">
            <Button
              text="Add Admin"
              width="130%"
              icon={plus}
              borderColor="#1849A9"
              type="button"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        </div>
        <Table columns={columns} rows={adminsList} />
      </div>
      <Modal
        title={
          <div className="flex flex-col gap-2">
            <img src={addIcon} alt="add" className="h-10 w-10" />
            <p>Add Admin</p>
          </div>
        }
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={700}
        centered
      >
        <div className=" w-full flex-col gap-10 mt-10  flex  justify-center items-center">
          <div className=" gap-10 items-center flex-wrap flex w-full">
            <Input
              label="Name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
              type="text"
              mandate
            />
            <Input
              label="Email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              type="text"
              mandate
            />
          </div>
          <div className="flex gap-10 items-center flex-wrap w-full">
            <Input
              label="Password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              type="text"
              mandate
            />
            <MobileNumberInput
              label="Mobile Number"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              setValue={setMobileNumber}
              error={mobileNumberError}
              mandate
            />
          </div>
          <div className="flex gap-10 items-center justify-start flex-wrap w-full">
            <DateInput
              label="Date"
              placeholder="Select date"
              value={date}
              setValue={setDate}
              error={dateError}
              disabled
            />
          </div>
          <div className="pb-2">
            <Button
              text="Create User"
              width="130%"
              borderColor="#1849A9"
              type="button"
              onClick={handleCreate}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <div className="flex flex-col gap-2">
            <img src={addIcon} alt="edit" className="h-10 w-10" />
            <p>Edit Admin</p>
          </div>
        }
        visible={showEditModal}
        onCancel={() => {
          setName("");
          setEmail("");
          setPassword("");
          setMobileNumber("");
          setShowEditModal(false);
        }}
        footer={null}
        width={700}
        centered
      >
        <div className=" w-full flex-col gap-10 mt-10  flex  justify-center items-center">
          <div className=" gap-10 items-center flex-wrap flex w-full">
            <Input
              label="Name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
              type="text"
              mandate
            />
            <Input
              label="Email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              type="text"
              mandate
            />
          </div>
          <div className="flex gap-10 items-center flex-wrap w-full">
            <Input
              label="Password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              type="text"
              mandate
            />
            <MobileNumberInput
              label="Mobile Number"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              setValue={setMobileNumber}
              error={mobileNumberError}
              mandate
            />
          </div>
          <div className="flex gap-10 items-center justify-start flex-wrap w-full">
            <DateInput
              label="Date"
              placeholder="Select date"
              value={date}
              setValue={setDate}
              error={dateError}
              disabled
            />
          </div>
          <div className="pb-2">
            <Button
              text="Save Changes"
              width="130%"
              borderColor="#1849A9"
              type="button"
              onClick={handleEditUser}
            />
          </div>
        </div>
      </Modal>
      {/* confirmation modals */}
      <ConfirmModal
        isOpen={confirmModal}
        setIsOpen={setConfirmModal}
        title={`Delete ${selectedUser.name} ?`}
        description={"Do you want to delete this admin?"}
        button1={"No"}
        button2={"Yes"}
        onClickButton1={() => setConfirmModal(!confirmModal)}
        onClickButton2={() => {
          handleDeleteUser();
          fetchAdminData();
        }}
      />
      <SuccessModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        animation="delete"
        title={"User deleted successfully."}
      />
    </>
  );
}
