import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import TableHead from "../reusable/Table/TableHead";
import TableCell from "../reusable/Table/TableCell";
import DateInput from "../reusable/DateInput";
import DropDown from "../reusable/DropDown";
import Table from "../reusable/Table/Table";
import Button from "../reusable/Button";
import { Modal, message } from "antd";
import Input from "../reusable/Input";
import Common from "../../services/api/commonApi";
import addIcon from "../../assets/addIcon.png";
import DateUtils from "../../services/utility/DateUtils";
import Passbook from "../../services/api/passbookApi";

export default function Credits({ activeKey }) {
  const [creditsData, setCreditsData] = useState([]);

  const [shiftTime, setShiftTime] = useState("");
  const [fromDate, setFromDate] = useState(DateUtils.getCurrentDate());
  const [toDate, setToDate] = useState(DateUtils.getCurrentDate());
  const [paidToFilter, setPaidToFilter] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [paidTo, setPaidTo] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [date, setDate] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [paidToError, setPaidToError] = useState("");
  const [paidByError, setPaidByError] = useState("");
  const [paidAmountError, setPaidAmountError] = useState("");
  const [dateError, setDateError] = useState("");
  const [transactionIdError, setTransactionIdError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");

  const [shiftTimeList, setShiftTimeList] = useState([]);
  const [payerList, setpayerList] = useState([]);
  const [paymentModeList, setPaymentModeList] = useState([]);

  useEffect(() => {
    if (activeKey === "credits") fetchPayerData();
  }, [activeKey]);

  useEffect(() => {
    fetchCreditsData();
    // fetchShiftTimeData();
    fetchPayerData();
    fetchPaymentModeData();
  }, []);

  useEffect(() => {
    fetchCreditsData();
  }, [paidToFilter, fromDate, toDate]);

  const fetchCreditsData = async () => {
    try {
      const params = {
        payerId: paidToFilter?.id,
        toDate: fromDate,
        fromDate: toDate
      };
      const response = await Passbook.getCredits(params);
      setCreditsData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShiftTimeData = async () => {
    try {
      const data = await Common.shiftTime();
      setShiftTimeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchPayerData = async () => {
    try {
      const data = await Common.getPayer();
      setpayerList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchPaymentModeData = async () => {
    try {
      const data = await Common.getPaymentMode();
      setPaymentModeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const isValid = () => {
    let isValid = true;

    if (!paidTo) {
      setPaidToError("Please select Paid To");
      isValid = false;
    } else {
      setPaidToError("");
    }
    if (!paidBy) {
      setPaidByError("Please select Paid By");
      isValid = false;
    } else {
      setPaidByError("");
    }
    if (!paidAmount) {
      setPaidAmountError("Please enter Paid Amount");
      isValid = false;
    } else {
      setPaidAmountError("");
    }
    if (!transactionId) {
      setTransactionIdError("Please enter Transaction ID");
      isValid = false;
    } else {
      setTransactionIdError("");
    }
    if (!paymentMode) {
      setPaymentModeError("Please select Payment Mode");
      isValid = false;
    } else {
      setPaymentModeError("");
    }

    return isValid;
  };

  const columnData =
    creditsData?.length && creditsData?.map((item, index) => {
      item.sNo = index + 1;
      return item;
    });

  const handleAddCredit = async (e) => {
    e.preventDefault();
    if (!isValid()) {
      return;
    }
    try {
      const data = {
        paidTo: parseInt(paidTo?.id),
        paidBy: parseInt(paidBy?.id),
        paidAmount: parseInt(paidAmount),
        transactionId: transactionId,
        paymentMode: parseInt(paymentMode?.id),
      };
      const response = await Passbook.createCredits(data);
      if (response.status) {
        fetchCreditsData();
        message.success("Credit added successfully");
        setPaidTo("");
        setPaidBy("");
        setPaidAmount("");
        setTransactionId("");
        setPaymentMode("");
        setShowModal(false);
      }
    } catch (error) {
      message.error(error.message);
      console.error("Error fetching data", error);
    }
  };

  const columns = [
    {
      id: "srNo",
      name: <TableHead width="100px">S.No</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          <Link className="text-primary-blue">{row.sNo}</Link>
        </TableCell>
      ),
    },
    {
      id: "paidTo",
      name: <TableHead width="120px">Paid To</TableHead>,
      cell: (row) => <TableCell width="120px">{row.paidTo}</TableCell>,
    },
    {
      id: "paidBy",
      name: <TableHead>Paid By</TableHead>,
      cell: (row) => <TableCell>{row.paidBy}</TableCell>,
    },
    {
      id: "paidAmount",
      name: <TableHead>Paid Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.paidAmount)?.toLocaleString()}</TableCell>,
    },
    {
      id: "paidDate",
      name: <TableHead>Paid Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.paidDate)}</TableCell>,
    },
    {
      id: "transactionId",
      name: <TableHead>Transaction ID</TableHead>,
      cell: (row) => <TableCell>{row.transactionId}</TableCell>,
    },
    {
      id: "paymentMode",
      name: <TableHead>Payment Mode</TableHead>,
      cell: (row) => <TableCell>{row.paymentMode}</TableCell>,
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5 text-base text-black">
      <div className="flex justify-between">
        <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-5  mt-2 lg:mt-3">
          <DropDown
            label={"Paid To"}
            placeholder={"Name"}
            displayValue={"payerName"}
            data={payerList}
            value={paidToFilter}
            setValue={setPaidToFilter}
            selectAll
          />
          {/* <DropDown
          label={"Shift Time"}
          placeholder={"Select shift time"}
          displayValue={"name"}
          data={shiftTimeList}
          value={shiftTime}
          setValue={setShiftTime}
          selectAll
        /> */}
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
          />
        </div>
        <div className="flex mt-2">
          <Button
            text="Add Payment"
            width="140px"
            borderColor="#1849A9"
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end w-[98.5%] pr-5 mb-5">
      </div>
      <Table columns={columns} rows={columnData} />
      {
        // Modal
        showModal && (
          <Modal
            title={
              <div className="flex flex-col gap-2">
                <img src={addIcon} alt="add" className="h-10 w-10" />
                <p>Add Payment Details</p>
              </div>
            }
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
            width={700}
            centered
          >
            <div className="w-full flex-col gap-10 mt-10 flex justify-center items-center">
              <div className="gap-10 items-center flex-wrap flex w-full">
                <DropDown
                  label={"Paid To"}
                  placeholder={"Select Paid To"}
                  displayValue={"payerName"}
                  data={payerList}
                  value={paidTo}
                  setValue={setPaidTo}
                  error={paidToError}
                  mandate
                />
                <DropDown
                  label={"Paid By"}
                  placeholder={"Select Paid By"}
                  displayValue={"payerName"}
                  data={payerList}
                  value={paidBy}
                  setValue={setPaidBy}
                  error={paidByError}
                  mandate
                />
              </div>
              <div className="flex gap-10 items-center flex-wrap w-full">
                <Input
                  label="Paid Amount"
                  placeholder="Enter Amount"
                  value={paidAmount}
                  onChange={(e) => setPaidAmount(e.target.value)}
                  error={paidAmountError}
                  width="100%"
                  type="text"
                  mandate
                />
                <DateInput
                  label={"Date"}
                  placeholder={"Select date"}
                  value={DateUtils.getCurrentDate()}
                  setValue={setDate}
                  error={dateError}
                  width="100%"
                  disabled
                />
              </div>
              <div className="flex gap-10 items-center flex-wrap w-full">
                <Input
                  label="Transaction ID"
                  placeholder="Transaction ID"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  error={transactionIdError}
                  width="100%"
                  type="text"
                  mandate
                />
                <DropDown
                  label={"Payment Mode"}
                  placeholder={"Select payment mode"}
                  displayValue={"name"}
                  data={paymentModeList}
                  value={paymentMode}
                  setValue={setPaymentMode}
                  error={paymentModeError}
                  width="100%"
                  mandate
                />
              </div>
              <div className="mb-3">
                <Button
                  text="Create"
                  width="100px"
                  borderColor="#1849A9"
                  type="button"
                  onClick={handleAddCredit}
                />
              </div>
            </div>
          </Modal>
        )
      }
    </div>
  );
}
