import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReportTabNavigation from "../../components/report/ReportTabNavigation";

export default function ReportPage() {

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  useEffect(() => {
    if(localStorage.getItem("userTypeFleetrax") == "supervisor" ){
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Report Management"}
        description={"View all your reports here."}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className=" w-full flex mt-7">
          <ReportTabNavigation />
        </div>
      </PageLayout>
    </>
  );
}
