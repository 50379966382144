import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import InvoiceTabNavigationOutstation from "./InvoiceTabNavigationOutstation";
import TripTabNavigation from "./TripTabNavigation";




// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function OutStationTabNavigation({ companyList, tripTypeList, vehicleTypeList, driverList, isNewDataAdded }) {
  const [activeKey, setActiveKey] = useState("trips");

  useEffect(() => {
    const key = sessionStorage.getItem("outstationTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("outstationTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: "trips",
      label: (
        <LabelForTabs isActive={activeKey === "trips"}>Trips</LabelForTabs>
      ),
      children: <TripTabNavigation companyList={companyList} tripTypeList={tripTypeList} vehicleTypeList={vehicleTypeList} driverList={driverList} isNewDataAdded={isNewDataAdded} />,
    },
    {
      key: "Invoice",
      label: (
        <LabelForTabs isActive={activeKey === "Invoice"}>Invoice</LabelForTabs>
      ),
      children: <InvoiceTabNavigationOutstation companyList={companyList} />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default OutStationTabNavigation;
