import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InvoiceTabNavigation from "../../components/invoice/InvoiceTabNavigation";
import { useEffect, useState } from "react";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import Select from "react-select";
import DateUtils from "../../services/utility/DateUtils";
import Common from "../../services/api/commonApi";
import Driver from "../../services/api/driverApi";
import Invoice from "../../services/api/invoiceApi";

export default function InvoicePage() {
  const [driverList, setDriverList] = useState([{ value: null, label: "--- Select All ---", id: null, }]);
  const [shiftTimeList, setShiftTimeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const storedDriverId = localStorage.getItem("driverInvoiceDriverId");
  const initialDriverId = storedDriverId ? JSON.parse(storedDriverId) : "";

  const [driverId, setDriverId] = useState(initialDriverId);

  const storedShiftTime = localStorage.getItem("driverInvoiceShiftTime");
  const initialShiftTime = storedShiftTime ? JSON.parse(storedShiftTime) : "";

  const [shiftTime, setShiftTime] = useState(initialShiftTime);

  const storedFromDate = localStorage.getItem("driverInvoiceFromDate");
  const initialFromDate = storedFromDate
    ? storedFromDate
    : DateUtils.getTMinusDate(6);
  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("driverInvoiceToDate");
  const initialToDate = storedToDate
    ? storedToDate
    : DateUtils.getCurrentDate();

  const [toDate, setToDate] = useState(initialToDate);

  const storedCompany = localStorage.getItem("driverInvoiceCompany");
  const initialCompany = storedCompany ? JSON.parse(storedCompany) : "";

  const [company, setCompany] = useState(initialCompany);

  const [todayPaidAmount, setTodayPaidAmount] = useState(0);
  const [todayUnpaidAmount, setTodayUnpaidAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalOnholdAmount, setTotalOnholdAmount] = useState(0);
  const [totalUnpaidAmount, setTotalUnpaidAmount] = useState(0);
  const [expence, setExpence] = useState(0);
  const [totalExpence, setTotalExpence] = useState(0);


  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    fetchDriverData();
    fetchShiftTimeData();
    fetchCompanyData();
    fetchGetTodayPaidAmountData();
    fetchGetTodayUnPaidAmountData();
  }, []);
  
  useEffect(() => {
    // fetchAmountData();
    fetchGetTotalPaidAmount();
    fetchGetTotalUnPaidAmount();
    fetchTotalNoOfTrips();
    fetchGetExpence();
    fetchGetTotalExpence();
  }, [fromDate, toDate, shiftTime, driverId, company]);

  window.onbeforeunload = () => {
    localStorage.removeItem("driverInvoiceDriverId");
    localStorage.removeItem("driverInvoiceShiftTime");
    localStorage.removeItem("driverInvoiceFromDate");
    localStorage.removeItem("driverInvoiceToDate");
    localStorage.removeItem("driverInvoiceCompany");
  };

  const fetchGetTodayPaidAmountData = async () => {
    try {
      const data = await Invoice.getTodayPaidAmount();
      setTodayPaidAmount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchGetTodayUnPaidAmountData = async () => {
    try {
      const data = await Invoice.getTodayUnPaidAmount();
      setTodayUnpaidAmount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchGetTotalPaidAmount = async () => {
    try {
      const data = await Invoice.getTotalPaidAmount({
        fromDate: fromDate,
        toDate: toDate,
        shiftTime: shiftTime.id,
        driverId: driverId.id,
        company: company.id,
      });
      setTotalPaidAmount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchGetTotalUnPaidAmount = async () => {
    try {
      const data = await Invoice.getTotalUnPaidAmount({
        fromDate: fromDate,
        toDate: toDate,
        shiftTime: shiftTime.id,
        driverId: driverId.id,
        company: company.id,
      });
      setTotalUnpaidAmount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchTotalNoOfTrips = async () => {
    try {
      const data = await Invoice.totalNoOfTrips({
        fromDate: fromDate,
        toDate: toDate,
        shiftTime: shiftTime.id,
        driverId: driverId.id,
        company: company.id,
      });
      setTotalOnholdAmount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchGetExpence = async () => {
    try {
      const data = await Invoice.getExpence({
        fromDate: fromDate,
        toDate: toDate,
        shiftTime: shiftTime.id,
        driverId: driverId.id,
        company: company.id,
      });
      setExpence(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchGetTotalExpence = async () => {
    try {
      const data = await Invoice.getTotalExpence({
        fromDate: fromDate,
        toDate: toDate,
        shiftTime: shiftTime.id,
        driverId: driverId.id,
        company: company.id,
      });
      setTotalExpence(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  // const fetchAmountData = async () => {
  //   try {
  //     const data = await Invoice.getAmounts({
  //       fromDate: fromDate,
  //       toDate: toDate,
  //       shiftTime: shiftTime.id,
  //       driverId: driverId.id,
  //       company: company.id,
  //     });
  //     setTodayPaidAmount(data.todayPaidAmount);
  //     setTodayUnpaidAmount(data.todayUnPaidAmount);
  //     setTotalPaidAmount(data.totalPaidAmount);
  //     setTotalOnholdAmount(data.totalOnHoldAmount);
  //     setTotalUnpaidAmount(data.totalUnPaidAmount);
  //   } catch (error) {
  //     console.error("Error fetching data", error);
  //   }
  // };

  const fetchDriverData = async () => {
    try {
      const data = await Driver.getDrivers();
      setDriverList((prev) => [
        ...prev,
        ...data.data.map((driver) => ({
          value: driver.id,
          label: driver.driverID,
          id: driver.id,
          driverID: driver.driverID,
        })),
      ]);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchShiftTimeData = async () => {
    try {
      const data = await Common.shiftTime();
      setShiftTimeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const data = await Common.company();
      setCompanyList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  const cards = () => {
    return (
      <>
        <div className="w-full flex-wrap pr-3 gap-2 flex justify-between items-center">
          {/* Today Paid Amount */}
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Today Paid Amount
            </div>
            <div className="text-3xl text-white font-semibold">
              ₹ {parseInt(todayPaidAmount)?.toLocaleString()}
            </div>
          </div>

          {/* Today Unpaid Amount */}
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Today Unpaid Amount
            </div>
            <div className="text-3xl text-white font-semibold">
              ₹ {parseInt(todayUnpaidAmount)?.toLocaleString()}
            </div>
          </div>

          {/* Total Paid Amount */}
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Total Paid Amount
            </div>
            <div className="text-3xl text-white font-semibold">
              ₹ {parseInt(totalPaidAmount)?.toLocaleString()}
            </div>
          </div>

          {/* Total Unpaid Amount */}
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Total Unpaid Amount
            </div>
            <div className="text-3xl text-white font-semibold">
              ₹ {parseInt(totalUnpaidAmount)?.toLocaleString()}
            </div>
          </div>
          {/* Total Onhold Amount */}
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Total No. of Trips
            </div>
            <div className="text-3xl text-white font-semibold">
              {parseInt(totalOnholdAmount)?.toLocaleString()}
            </div>
          </div>
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Expence
            </div>
            <div className="text-3xl text-white font-semibold">
              {parseInt(expence)?.toLocaleString()}
            </div>
          </div>
          <div className="rounded-lg items-center py-2 bg-primary-blue px-3 flex-col flex gap-1 h-auto min-w-[190px]">
            <div className="text-lg text-white font-semibold">
              Total Expence
            </div>
            <div className="text-3xl text-white font-semibold">
              {parseInt(totalExpence)?.toLocaleString()}
            </div>
          </div>
       
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout
        title={"Invoice Management"}
        description={"View your invoice details here."}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className=" w-full flex  flex-col gap-3 mt-2">
          <div className="flex  flex-wrap  lg:gap-x-8 lg:gap-y-3 gap-2 w-full justify-center lg:justify-start items-center mb-5 lg:mb-5 mt-2 lg:mt-6">
            <div>
              <div className="text-[#344054] text-[15px] pl-1 font-medium ">
                Driver ID
              </div>
              <Select
                classNamePrefix="Select driver ID"
                placeholder="---Select All---"
                className="basic-multi-select text-sm w-[300px] rounded-md !border-border-gray"
                isSearchable={true}
                options={driverList}
                value={driverId}
                onChange={(option) => {
                  setDriverId(option);
                  localStorage.setItem(
                    "driverInvoiceDriverId",
                    JSON.stringify(option)
                  );
                }}
                isClearable={false}
              />
            </div>
            {/* <DropDown
              label={"Driver ID"}
              placeholder={"Select driver ID"}
              displayValue={"driverID"}
              data={driverList}
              value={driverId}
              setValue={setDriverId}
              setLocalStorage={"driverInvoiceDriverId"}
              // width={"230px"}
              selectAll
            /> */}
            <DropDown
              label={"Shift Time"}
              placeholder={"Select shift time"}
              displayValue={"name"}
              data={shiftTimeList}
              value={shiftTime}
              setValue={setShiftTime}
              setLocalStorage={"driverInvoiceShiftTime"}
              // width={"230px"}
              selectAll
            />
            <DateInput
              label={"From date"}
              placeholder={"Select from date"}
              value={fromDate}
              setValue={setFromDate}
              setLocalStorage={"driverInvoiceFromDate"}
              // width={"230px"}
            />
            <DateInput
              label={"To date"}
              placeholder={"Select to date"}
              value={toDate}
              setValue={setToDate}
              setLocalStorage={"driverInvoiceToDate"}
              // width={"230px"}
            />
            <DropDown
              label={"Company"}
              placeholder={"Select Company"}
              displayValue={"name"}
              data={companyList}
              value={company}
              setValue={setCompany}
              setLocalStorage={"driverInvoiceCompany"}
              // width={"230px"}
              selectAll
            />
          </div>
          {cards()}
          <InvoiceTabNavigation
            driverId={driverId}
            shiftTime={shiftTime}
            fromDate={fromDate}
            toDate={toDate}
            company={company}
          />
        </div>
      </PageLayout>
    </>
  );
}
