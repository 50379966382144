import React, { useEffect, useState } from "react";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import { Link } from "react-router-dom";
import Input from "../../reusable/Input";
import TimeInput from "../../reusable/TimeInput";
import Button from "../../reusable/Button";
import driverImage from "../../../assets/driver-image.png";
import vehicleImage from "../../../assets/suv-image.png";
import Outstation from "../../../services/api/outstationApi";
import { Card, Modal, message } from "antd";

function NotAllotedTrips({ companyList, tripTypeList, vehicleTypeList, driverList, activeKey, activeKeyName, isNewDataAdded }) {

  const [showUpdateTripModal, setShowUpdateTripModal] = useState(false);
  const [showUpdateTripAmountModal, setShowUpdateTripAmountModal] = useState(false);
  const [outstationList, setOutstationList] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [company, setCompany] = useState("");

  const [selectedRowID, setSelectedRowID] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [driverForDisplay, setDriverForDisplay] = useState(null);

  const [tripAmount, setTripAmount] = useState(null);

  const [routeId, setRouteId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [tripType, setTripType] = useState('');
  const [guestName, setGuestName] = useState('');
  const [tripStartDate, setTripStartDate] = useState(null);
  const [tripEndDate, setTripEndDate] = useState(null);
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropLocation, setDropLocation] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [dropTime, setDropTime] = useState('');
  const [guestContactNumber, setGuestContactNumber] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState('');
  const [driver, setDriver] = useState('');
  const [tripStatus, setTripStatus] = useState('Active');

  const [routeIdError, setRouteIdError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [tripTypeError, setTripTypeError] = useState('');
  const [guestNameError, setGuestNameError] = useState('');
  const [tripStartDateError, setTripStartDateError] = useState('');
  const [tripEndDateError, setTripEndDateError] = useState('');
  const [pickupLocationError, setPickupLocationError] = useState('');
  const [dropLocationError, setDropLocationError] = useState('');
  const [pickupTimeError, setPickupTimeError] = useState('');
  const [dropTimeError, setDropTimeError] = useState('');
  const [guestContactNumberError, setGuestContactNumberError] = useState('');
  const [numberOfDaysError, setNumberOfDaysError] = useState('');
  const [vehicleNameError, setVehicleNameError] = useState('');
  const [vehicleTypeError, setVehicleTypeError] = useState('');
  const [numberOfGuestsError, setNumberOfGuestsError] = useState('');
  const [driverError, setDriverError] = useState('');
  // const [tripStatusError, setTripStatusError] = useState('');

  const getVehicleName = (id) => {
    if (id === 1) {
      return "SEDAN";
    } else if (id === 2) {
      return "SUV";
    } else if (id === 3) {
      return "TEMPO";
    } else if (id === 4) {
      return "COACH";
    }
  };

  useEffect(() => {
    fetchOutstationData();
    if (activeKey === 2) setDisabled(true);
    else setDisabled(false);
  }, [activeKeyName, isNewDataAdded, fromDate, toDate, company]);

  const columns = [
    {
      id: "srNo",
      name: <TableHead width="100px">S.No</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          {row.sNo}
        </TableCell>
      ),
    },
    {
      id: "routeId",
      name: <TableHead>Route Id</TableHead>,
      cell: (row) => (
        <TableCell>
          <button onClick={() => { handleSelectedRow(row); setShowUpdateTripModal(true) }} className="text-primary-blue">{row.routeId}</button>
        </TableCell>
      ),
    },
    {
      id: "companyName",
      name: <TableHead>Company Name</TableHead>,
      cell: (row) => <TableCell>{row.company?.name}</TableCell>,
    },
    {
      id: "guestName",
      name: <TableHead>Guest Name</TableHead>,
      cell: (row) => <TableCell>{row.guestName}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripType?.name}</TableCell>,
    },
    {
      id: "guestContactNo",
      name: <TableHead >Guest Contact No</TableHead>,
      cell: (row) => <TableCell>{row.guestContactNumber}</TableCell>,
    },
    {
      id: "pickupLocation",
      name: <TableHead>Pickup Location</TableHead>,
      cell: (row) => <TableCell>{row.pickupLocation}</TableCell>,
    },
    {
      id: "noOfDays",
      name: <TableHead>No Of Days</TableHead>,
      cell: (row) => <TableCell>{row.noOfDays}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType?.name}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },
  ];

  const isValid = () => {
    let isValid = true;

    if (!routeId) {
      setRouteIdError("Please enter route id");
      isValid = false;
    } else {
      setRouteIdError("");
    }
    if (!companyName) {
      setCompanyNameError("Please enter company name");
      isValid = false;
    } else {
      setCompanyNameError("");
    }
    if (!tripType) {
      setTripTypeError("Please select trip type");
      isValid = false;
    } else {
      setTripTypeError("");
    }
    if (!guestName) {
      setGuestNameError("Please enter guest name");
      isValid = false;
    } else {
      setGuestNameError("");
    }
    if (!tripStartDate) {
      setTripStartDateError("Please select trip start date");
      isValid = false;
    } else {
      setTripStartDateError("");
    }
    if (!tripEndDate) {
      setTripEndDateError("Please select trip end date");
      isValid = false;
    } else {
      setTripEndDateError("");
    }
    if (!pickupLocation) {
      setPickupLocationError("Please enter pickup location");
      isValid = false;
    } else {
      setPickupLocationError("");
    }
    if (!dropLocation) {
      setDropLocationError("Please enter drop location");
      isValid = false;
    } else {
      setDropLocationError("");
    }
    if (!pickupTime) {
      setPickupTimeError("Please enter pickup time");
      isValid = false;
    } else {
      setPickupTimeError("");
    }
    if (!dropTime) {
      setDropTimeError("Please enter drop time");
      isValid = false;
    } else {
      setDropTimeError("");
    }
    if (!guestContactNumber) {
      setGuestContactNumberError("Please enter guest contact number");
      isValid = false;
    } else {
      setGuestContactNumberError("");
    }
    if (numberOfDays <= 0) {
      setNumberOfDaysError("Please enter a valid number of days");
      isValid = false;
    } else {
      setNumberOfDaysError("");
    }
    if (!vehicleName) {
      setVehicleNameError("Please enter vehicle name");
      isValid = false;
    } else {
      setVehicleNameError("");
    }
    if (!vehicleType) {
      setVehicleTypeError("Please select vehicle type");
      isValid = false;
    } else {
      setVehicleTypeError("");
    }
    if (numberOfGuests <= 0) {
      setNumberOfGuestsError("Please enter a valid number of guests");
      isValid = false;
    } else {
      setNumberOfGuestsError("");
    }
    // if (!driver) {
    //   setDriverError("Please enter driver");
    //   isValid = false;
    // } else {
    //   setDriverError("");
    // }
    // if (!tripStatus) {
    //   setTripStatusError("Please select trip status");
    //   isValid = false;
    // } else {
    //   setTripStatusError("");
    // }

    return isValid;
  };

  const fetchOutstationData = async () => {
    const params = {
      tripStatusID: activeKey,
      fromDate: fromDate,
      toDate: toDate,
      companyID: company?.id,
    }
    try {
      const response = await Outstation.getAllOutstationTrip(params);
      setOutstationList(
        response.map((item, index) => {
          item.sNo = index + 1;
          return item;
        })
      );
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const handleSelectedRow = (row) => {
    setSelectedRowID(row.id);
    setRouteId(row.routeId);
    setCompanyName(row.company);
    setTripType(row.tripType);
    setGuestName(row.guestName);
    setTripStartDate(row.tripStartDate);
    setTripEndDate(row.tripEndDate);
    setPickupLocation(row.pickupLocation);
    setDropLocation(row.dropLocation);
    setPickupTime(row.pickupTime);
    setDropTime(row.dropTime);
    setGuestContactNumber(row.guestContactNumber);
    setNumberOfDays(row.noOfDays);
    setVehicleName(row.vehicleName);
    setVehicleType(row.vehicleType);
    setNumberOfGuests(row.noOfGuest);
    setDriver(row.driver);
    setDriverForDisplay(row.driver);
    setTripStatus(row.tripStatus);
  };

  const isDriverValid = () => {
    let isValid = true;

    if (!driver) {
      setDriverError("Please enter driver");
      isValid = false;
    } else {
      setDriverError("");
    }
    return isValid;
  };

  const handleUpdateTripStatus = async () => {
    if (!isValid()) {
      return;
    }
    const data = {
      id: selectedRowID,
      routeId: routeId?.toUpperCase(),
      companyName: companyName?.id,
      tripType: tripType?.id,
      guestName: guestName,
      tripStartDate: tripStartDate,
      tripEndDate: tripEndDate,
      pickupLocation: pickupLocation,
      dropLocation: dropLocation,
      pickupTime: pickupTime,
      dropTime: dropTime,
      guestContactNumber: guestContactNumber,
      numberOfDays: numberOfDays,
      vehicleName: vehicleName,
      vehicleType: vehicleType?.id,
      numberOfGuests: numberOfGuests,
      driver: driver?.id,
      tripStatus: tripStatus?.id,
    };
    try {
      const response = await Outstation.updateOutstation(data);
      if (response.status) {
        message.success("Outstation updated successfully");
        fetchOutstationData();
        setShowUpdateTripModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleAllocate = async () => {
    if (!isDriverValid()) {
      return;
    }
    const data = {
      id: parseInt(selectedRowID),
      allocatedDriverID: parseInt(driver.id),
      tripStatusID: 1,
    };
    try {
      const response = await Outstation.updateOutstation(data);
      if (response.status) {
        message.success("Driver allocated successfully");
        fetchOutstationData();
        setShowUpdateTripModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleComplete = async () => {
    if (!tripAmount) {
      message.error("Please enter trip amount");
      return;
    }
    const data = {
      id: parseInt(selectedRowID),
      tripAmount: parseInt(tripAmount),
      tripStatusID: 2,
    };
    try {
      const response = await Outstation.updateOutstation(data);
      if (response.status) {
        setShowUpdateTripAmountModal(false);
        message.success("Outstation completed successfully");
        fetchOutstationData();
        setShowUpdateTripModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="w-full h-full removeScrollBar">
      <div className="flex justify-between gap-3 items-center">
        <div className="flex lg:gap-8 gap-2 w-full lg:pl-2 items-center mb-5  lg:mb-5  mt-2 lg:mt-6">
          <DropDown
            label={"Company"}
            placeholder={"Select Company"}
            displayValue={"name"}
            data={companyList}
            value={company}
            setValue={setCompany}
            selectAll
          />
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
          />
        </div>
        <button className="flex mt-5 mr-2">
          <div className="bg-primary-blue/95 hover:bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
            <p className="mr-3 whitespace-nowrap">Download Now</p>
            <svg
              className="text-2xl "
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </div>
        </button>
      </div>
      <Table columns={columns} rows={outstationList} />
      <Modal
        centered
        onCancel={() => {
          setDriver(null);
          setShowUpdateTripModal(false);
        }}
        width={1300}
        title={
          <div className="flex gap-4 items-center">
            <p>Update Trip</p>
          </div>
        }
        visible={showUpdateTripModal}
        footer={null}
        className="my-10"
      >
        <div className=" flex gap-10 max-w-[1300px] overflow-auto  flex-wrap  lg:flex-nowrap ">
          <div className=" w-full">
            <Card>
              <div className=" flex flex-col gap-y-5 gap-x-3">
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    label={"Route ID"}
                    placeholder={"Enter Route ID"}
                    value={routeId?.toUpperCase()}
                    setValue={setRouteId}
                    onChange={(e) => setRouteId(e.target.value)}
                    error={routeIdError}
                    mandate
                    disabled={disabled}
                  />
                  <DropDown
                    label={"Company Name"}
                    placeholder={"Select Company"}
                    displayValue={"name"}
                    data={companyList}
                    value={companyName}
                    setValue={setCompanyName}
                    error={companyNameError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <DropDown
                    label={"Trip Type"}
                    placeholder={"Select Trip Type"}
                    displayValue={"name"}
                    data={tripTypeList}
                    value={tripType}
                    setValue={setTripType}
                    error={tripTypeError}
                    mandate
                    disabled={disabled}
                  />
                  <Input
                    label={"Guest Name"}
                    placeholder={"Guest Name"}
                    value={guestName}
                    onChange={(e) => setGuestName(e.target.value)}
                    error={guestNameError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <DateInput
                    label={"Trip Start Date"}
                    placeholder={"Select trip start date"}
                    value={tripStartDate}
                    setValue={setTripStartDate}
                    error={tripStartDateError}
                    mandate
                    disabled={disabled}
                  />
                  <DateInput
                    label={"Trip End Date"}
                    placeholder={"Select trip end date"}
                    value={tripEndDate}
                    setValue={setTripEndDate}
                    error={tripEndDateError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    label={"Pickup Location"}
                    placeholder={"Pickup Location"}
                    value={pickupLocation}
                    onChange={(e) => setPickupLocation(e.target.value)}
                    error={pickupLocationError}
                    mandate
                    disabled={disabled}
                  />
                  <Input
                    label={"Drop Location"}
                    placeholder={"Drop Location"}
                    value={dropLocation}
                    onChange={(e) => setDropLocation(e.target.value)}
                    error={dropLocationError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <TimeInput
                    label={"Pickup Time"}
                    placeholder={"--:--:--"}
                    value={pickupTime}
                    setValue={setPickupTime}
                    error={pickupTimeError}
                    mandate
                    disabled={disabled}
                  />
                  <TimeInput
                    label={"Drop Time"}
                    placeholder={"--:--:--"}
                    value={dropTime}
                    setValue={setDropTime}
                    error={dropTimeError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    label={"Guest Contact Number"}
                    placeholder={"Guest Contact Number"}
                    value={guestContactNumber}
                    onChange={(e) => setGuestContactNumber(e.target.value)}
                    error={guestContactNumberError}
                    mandate
                    disabled={disabled}
                  />
                  <Input
                    label={"No Of Days"}
                    placeholder={"No of Days"}
                    type={"number"}
                    value={numberOfDays}
                    onChange={(e) => setNumberOfDays(e.target.value)}
                    error={numberOfDaysError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    label={"Vehicle Name"}
                    placeholder={"Vehicle Name"}
                    value={vehicleName}
                    onChange={(e) => setVehicleName(e.target.value)}
                    error={vehicleNameError}
                    mandate
                    disabled={disabled}
                  />
                  <DropDown
                    label={"Vehicle Type"}
                    placeholder={"Select Vehicle Type"}
                    displayValue={"name"}
                    data={vehicleTypeList}
                    value={vehicleType}
                    setValue={setVehicleType}
                    error={vehicleTypeError}
                    mandate
                    disabled={disabled}
                  />
                </div>
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    label={"No of Guest"}
                    placeholder={"No of Guest"}
                    type={"number"}
                    value={numberOfGuests}
                    onChange={(e) => setNumberOfGuests(e.target.value)}
                    error={numberOfGuestsError}
                    mandate
                    disabled={disabled}
                  />
                  <div className="invisible w-full"></div>
                </div>
                {!disabled && (
                  <div className=" w-full mt-4">
                    <Button
                      text={"Update Trip"}
                      onClick={handleUpdateTripStatus}
                    />
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className=" w-full gap-4 flex flex-col">
            <Card>
              <div className=" flex flex-col gap-5">
                <div className="flex w-full gap-3 items-center flex-wrap lg:flex-nowrap">
                  <DropDown
                    label={"Driver ID"}
                    placeholder={"Select Driver ID"}
                    displayValue={"driverID"}
                    data={driverList}
                    value={driver}
                    setValue={setDriver}
                    error={driverError}
                    mandate
                    disabled={disabled}
                  />
                  <Input
                    disabled={true}
                    label={"Driver Name"}
                    placeholder={"-"}
                    value={driver?.driverName || "-"}
                  />
                </div>
                <div className=" flex w-full  gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    disabled={true}
                    label={"Mobile Number"}
                    placeholder={"-"}
                    value={driver?.mobileNo || "-"}
                  />
                  <Input
                    disabled={true}
                    label={"Vehicle Type"}
                    placeholder={"-"}
                    value={driver?.vehicleType?.name || "-"}
                  />
                </div>
                <div className=" flex w-full  gap-3 items-center flex-wrap lg:flex-nowrap">
                  <Input
                    disabled={true}
                    label={"Vehicle Number"}
                    placeholder={"-"}
                    value={driver?.vehicleNo || "-"}
                  />
                  <div className=" invisible w-full"></div>
                </div>
                {!disabled && (
                  <div className=" w-full flex justify-around mt-4  lg:flex-nowrap flex-wrap gap-5 lg:gap-0  items-center ">
                    <Button
                      width={"200px"}
                      bgColor={"#2F90FA"}
                      text={"Allocate"}
                      onClick={handleAllocate}
                    />
                    <Button
                      width={"200px"}
                      bgColor={"#31C55D"}
                      text={"Complete"}
                      onClick={() => setShowUpdateTripAmountModal(true)}
                    />
                  </div>
                )}
              </div>
            </Card>
            {/* <Card>
              <div className=" flex items-center flex-wrap lg:flex-nowrap  gap-5 lg:gap-0  justify-around w-full">
                <div className=" text-lg">Trip Status</div>
                <div className=" bg-[#31007A] w-20 rounded-lg h-7"></div>
                <DropDown
                  width={"200px"}
                  placeholder={"Select Trip Status"}
                  displayValue={"option"}
                  data={[]}
                  value={tripStatus}
                  setValue={setTripStatus}
                  error={tripStatusError}
                />
              </div>
            </Card> */}
            {activeKey !== 0 && (
              <Card>
                <div className=" flex items-center flex-wrap lg:flex-nowrap  gap-5 lg:gap-0  justify-around w-full">
                  <div className=" w-full flex  ">
                    <div className=" w-full flex lg:flex-nowrap gap-5  flex-wrap justify-between items-center">
                      <div className=" w-full flex flex-col justify-center items-center gap-5">
                        <div className=" w-fit hidden lg:block">
                          <img width={100} src={driverImage} alt="" />
                        </div>
                        <div className=" w-full flex justify-center  items-center">
                          <div className=" w-full flex flex-col gap-4">
                            <div className="  w-full lg:w-32 flex ">
                              <div className="  w-full text-sm font-semibold text-text-black ">
                                Driver name
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                            <div className="  w-full lg:w-32 flex ">
                              <div className="  w-full text-sm font-semibold text-text-black ">
                                Vehicle name
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                            <div className="  w-full lg:w-32 flex ">
                              <div className="  w-full text-sm font-semibold text-text-black ">
                                Mobile Number
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                          </div>
                          <div className=" w-fit flex flex-col gap-4">
                            <div className=" w-fit flex ">
                              <div className=" lg:w-32 text-sm font-semibold text-text-black ">
                                {driverForDisplay?.driverName}
                              </div>
                            </div>
                            <div className=" w-fit flex ">
                              <div className=" lg:w-32 text-sm font-semibold text-text-black ">
                                {driverForDisplay?.vehicleName}
                              </div>
                            </div>
                            <div className=" w-fit flex ">
                              <div className=" lg:w-32 text-sm font-semibold text-text-black ">
                                {driverForDisplay?.mobileNo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" w-fit flex flex-col justify-center items-center gap-5">
                        <div className=" w-fit hidden lg:block">
                          <img src={vehicleImage} alt="" />
                        </div>
                        <div className=" w-fit flex">
                          <div className=" w-full flex flex-col gap-4">
                            <div className="  w-full lg:w-32 flex ">
                              <div className=" w-full text-sm font-semibold text-text-black ">
                                Vehicle number
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                            <div className="  w-full lg:w-32 flex ">
                              <div className=" w-full text-sm font-semibold text-text-black ">
                                Status
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                            <div className="  w-full lg:w-32 flex ">
                              <div className=" w-full text-sm font-semibold text-text-black ">
                                Vehicle type
                              </div>
                              <div className=" w-3 text-sm font-semibold text-text-black ">
                                :
                              </div>
                            </div>
                          </div>
                          <div className=" w-fit flex flex-col gap-4">
                            <div className=" w-fit flex ">
                              <div className="  w-full lg:w-32 text-sm font-semibold text-text-black ">
                                {driverForDisplay?.vehicleNo}
                              </div>
                            </div>
                            <div className=" w-fit flex ">
                              <div className="  w-full lg:w-32 text-sm font-semibold text-text-black ">
                                {driverForDisplay?.driverStatus || "-"}
                              </div>
                            </div>
                            <div className=" w-fit flex ">
                              <div className="  w-full lg:w-32 text-sm font-semibold text-text-black ">
                                {getVehicleName(driverForDisplay?.vehicleTypeID)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        centered
        onCancel={() => {
          setShowUpdateTripAmountModal(false);
        }}
        width={600}
        title={
          <div className="flex gap-4 items-center">
            <p>Add Trip Amount</p>
          </div>
        }
        visible={showUpdateTripAmountModal}
        footer={null}
        className="my-10"
      >
        <div className="flex flex-col justify-center items-center gap-4 my-4">
          <Input
            label={"Trip Amount"}
            placeholder={"Enter trip amount"}
            type="number"
            value={tripAmount}
            onChange={(e) => setTripAmount(e.target.value)}
            mandate
          />
          <Button
            text="Complete Trip"
            onClick={handleComplete}
            width="150px"
          />
        </div>
      </Modal>
    </div>
  );
}

export default NotAllotedTrips;
