import { useEffect, useState } from "react";
import Button from "../reusable/Button";
import BalanceCards from "./BalanceCards";
import { Link, useNavigate } from "react-router-dom";
import { Modal, message } from "antd";
import Input from "../reusable/Input";
import Passbook from "../../services/api/passbookApi";
import addIcon from "../../assets/addIcon.png";
import PassbookDetails from "../../pages/passbook/PassbookDetails";
import DateUtils from "../../services/utility/DateUtils";

export default function Balance({ activeKey }) {
  const navigate = useNavigate();

  const [addPersonName, setAddPersonName] = useState("");
  const [showDetailsPage, setShowDetailsPage] = useState(false);

  const [payerList, setPayerList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showDetailsPage) {
      navigate("/passbook");
    }
    fetchPayerList();
  }, [activeKey]);


  const createPerson = async () => {
    try {
      const data = {
        payerName: addPersonName,
      };
      const response = await Passbook.createPayer(data);
      if (response.status === 200) {
        fetchPayerList();
        message.success("Person created successfully");
      } else {
        message.error(response);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchPayerList = async () => {
    try {
      const params = {
      };
      const data = await Passbook.getPayerList(params);
      setPayerList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleAddPerson = (e) => {
    e.preventDefault();
    if (!addPersonName) {
      message.error("Please enter person name");
      return;
    }
    createPerson();
    setAddPersonName("");
    setShowModal(false);
  };

  if (showDetailsPage) {
    return (
      <PassbookDetails showDetailsPage={showDetailsPage} setShowDetailsPage={setShowDetailsPage} />
    )
  }

  return (
    <>
      <div className="w-full pr-5 flex justify-center items-center removeScrollBar pb-5">
        <div className=" w-full  flex justify-between items-center my-2 rounded-lg ">
          <>
            <div className="  rounded-lg items-center ml-6 py-2   bg-primary-blue/95 px-4 flex-col flex gap-1">
              <div className=" text-lg text-white font-semibold">
                Total Current Balance
              </div>
              <div className=" text-4xl  text-white font-semibold">
                ₹ {payerList?.sumForDate?.toLocaleString()}
              </div>
            </div>
            <div className=" w-fit pr-16 ">
              <Button
                text="Add Person"
                width="130%"
                borderColor="#1849A9"
                type="button"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          </>
        </div>
      </div>
      <div className=" w-full gap-4 flex flex-wrap justify-around items-center">
        {payerList?.data?.length > 0 && payerList?.data?.map((item) => {
          return (
            <>
              <BalanceCards
                handleClick={() => {
                  setShowDetailsPage(true);
                  navigate(`/passbook/${item?.payer?.payerName}?id=${item?.payerId}&date=${DateUtils.convertDate(item?.date)}`);
                }}
                item={item}
              />
            </>
          );
        })}
      </div>
      {
        // Modal
        showModal && (
          <Modal
            title={
              <div className="flex flex-col gap-2">
                <img src={addIcon} alt="add" className="h-10 w-10" />
                <p>Add Person</p>
              </div>
            }
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
            width={600}
            centered
          >
            <form className=" w-full flex-col  flex  justify-center items-center" onSubmit={handleAddPerson}>
              <Input
                label="Name"
                placeholder="Enter name"
                value={addPersonName}
                onChange={(e) => setAddPersonName(e.target.value)}
                width="100%"
                type="text"
                mandate
              />
              <div className=" pt-6 pb-3">
                <Button
                  type="submit"
                  text="Save"
                  width="120px"
                  borderColor="#1849A9"
                />
              </div>
            </form>
          </Modal>
        )
      }
    </>
  );
}
