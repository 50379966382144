import axios from "axios";

const Passbook = {
  createPayer: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}passbook/verify/createPayer`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getPayerList: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}passbook/verify/getPayerList`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  createCredits: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}passbook/verify/createCredits`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getCredits: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}passbook/verify/getCredits`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getBalanceData: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}passbook/verify/getBalanceData`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  createDebits: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}passbook/verify/createDebites`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  addBalance: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}passbook/verify/addBalance`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getBalance: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}passbook/verify/getBalance`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Passbook;
