import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import Employee from "../../../services/api/employeeApi";

export default function EmployeeTable({
  companyList,
  setEmployeeData,
  employeeList,
  search,
  setSearch,
  company,
  setCompany,
  userCompany
}) {
  const handleLinkClick = (row) => {
    setEmployeeData(row);
  };

  const columns = [
    {
      id: "employeeId",
      name: <TableHead>Employee ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className="text-primary-blue"
            to={`/employee-management/${row.employeeID}?id=${row.id}`}
            onClick={() => handleLinkClick(row)}
          >
            {row.employeeID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "employeeName",
      name: <TableHead position="left">Employee Name</TableHead>,
      cell: (row) => (
        <TableCell position="left">
          {row && row.employeeName ? row.employeeName : "-"}
        </TableCell>
      ),
    },
    {
      id: "companyName",
      name: <TableHead position="left">Company Name</TableHead>,
      cell: (row) => (
        <TableCell position="left">
          {row && row.company && row.company.name ? row.company.name : "-"}
        </TableCell>
      ),
    },
    {
      id: "gender",
      name: <TableHead>Gender</TableHead>,
      cell: (row) => (
        <TableCell>{row && row.gender ? row.gender : "-"}</TableCell>
      ),
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => (
        <TableCell>
          {row && row.mobileNumber ? row.mobileNumber : "-"}
        </TableCell>
      ),
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          type={"search"}
          label={"Search"}
          placeholder={"employee name / ID"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {userCompany !== "lapiz" &&
          (<DropDown
            label={"Company"}
            placeholder={"Select company"}
            displayValue={"name"}
            data={companyList}
            value={company}
            setValue={setCompany}
            setLocalStorage={"employeeCompany"}
            selectAll
          />
          )}
      </div>
      <Table columns={columns} rows={employeeList} />
    </div>
  );
}
