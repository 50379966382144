import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import filter from "../../../assets/filter.svg";
import { useState } from "react";
import FilterLayout from "../../../components/layouts/FilterLayout";
import { message } from "antd";
import MultiSelectFilter from "../../../components/reusable/MultiSelectFilterForCheckbox";
import FilterDates from "../../../components/reusable/FilterDates";

export default function TicketSummary({
  data,
  companyList,
  filterDate,
  setFilterDate,
  filters,
  setFilters,
  userCompany
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);


  // Function to update a specific filter
  const updateFilter = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleClearFilter = () => {
    message.success("cleared filters of Ticket Summary");
    setFilters({
      company: [],
      vehicleType: [],
      shiftTime: [],
      tripType: [],
    })
    setFilterDate({
      firstDate: "",
      secondDate: "",
    });
    setIsFilterOpen(!isFilterOpen);
  };

  // Replace these arrays with your actual data
  const companyOptions = companyList?.map((item) => item);

  const filterContent = [
    {
      title: "Company",
      body: (
        <div className=" w-full flex gap-3 items-center   ">
          <div>
            <MultiSelectFilter
              label="Company"
              options={companyOptions}
              selectedOptions={filters.company}
              updateSelectedOptions={(selected) =>
                updateFilter("company", selected)
              }
              displayValue="name"
              returnValue="id"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      body: (
        <div>
          {" "}
          <FilterDates
            firstDateLabel={"Start Date"}
            secondDateLabel={"End Date"}
            date={filterDate}
            setDate={setFilterDate}
            mandate
          />{" "}
        </div>
      ),
    },
  ];

  const filterContentForLapiz = [
    {
      title: "Date",
      body: (
        <div>
          {" "}
          <FilterDates
            firstDateLabel={"Start Date"}
            secondDateLabel={"End Date"}
            date={filterDate}
            setDate={setFilterDate}
            mandate
          />{" "}
        </div>
      ),
    },
  ];

  const sizing = {
    margin: { right: 1 },
    width: 250,
    height: 300,
    legend: { hidden: true },
  };

  const TOTAL = data ? data.map((item) => item.value).reduce((a, b) => a + b, 0) : 0;
  // const TOTAL = 0;

  const getArcLabel = (params) => {
    if (params.value) return `${params.value}`;
  };

  return (
    <>
      <div className="shadow-lg hidden p-4 w-fit lg:flex flex-auto xl:max-w-[600px] border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className="flex flex-col">
          <p className="text-lg font-semibold pb-2 w-fit">Ticket Summary</p>
          {data.length > 0 && (
            <PieChart
              series={[
                {
                  data,
                  arcLabel: getArcLabel,
                  innerRadius: 43,
                  outerRadius: 110,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: 14,
                  fontWeight: 'bold',
                },
              }}
              {...sizing}
            />
          )}
        </div>
        <div className="flex flex-col gap-3 justify-center items-start font-semibold mx-auto px-8 relative">
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
            <p>Solved</p>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#FE9900]" />
            <p>Pending</p>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
            <p>Rejected</p>
          </div>
          <div className="absolute flex bottom-0 pb-5">
            <p className="whitespace-nowrap">Total Tickets:</p>
            <span className="text-[#00ADB5] pl-2">{TOTAL}</span>
          </div>
        </div>
      </div>
      <div className="shadow-lg p-4 w-fit h-fit lg:hidden flex flex-auto gap-8 pr-6 border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className="flex flex-col">
          <p className="text-lg font-semibold pb-2 w-fit">Ticket Summary</p>
          <div className="  flex bottom-0 text-lg pb-5">
            Total Tickets:<span className="text-[#00ADB5] pl-2">{TOTAL}</span>
          </div>
          <PieChart
            series={[
              {
                data,
                arcLabel: getArcLabel,
                innerRadius: 43,
                outerRadius: 110,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
                fontWeight: 'bold',
              },
            }}
            {...sizing}
          />
          <div className="flex flex-col gap-3 justify-center items-start mx-auto   font-semibold relative">
            <div className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
              <p>Solved</p>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full bg-[#FE9900]" />
              <p>Pending</p>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
              <p>Rejected</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
