import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Typography, message } from "antd";
import { Card } from "antd";
import { Breadcrumbs } from "@mui/material";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput"
import Common from "../../services/api/commonApi";
import Tickets from "../../services/api/ticketsApi";
import DateUtils from "../../services/utility/DateUtils";
import axios from "axios";
import StatusCard from "../../components/reusable/Table/TripStatus";

export default function TicketDetailsPage() {

    const params = useParams();
    // text-area input states

    const [shiftTimeList, setShiftTimeList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [adminComment, setAdminComment] = useState("");

    const [createdDate, setCreatedDate] = useState("");
    const [createdTime, setCreatedTime] = useState("");

    const [solvedBy, setsolvedBy] = useState("");

    const [createdBy, setCreatedBy] = useState("");

    useEffect(() => {
        fetchTicketsData();
        fetchShiftTimeData();
        fetchStatesData();
        fetchDistrictsData();
        setCreatedBy(localStorage.getItem("userIdFleetrax"));
    }, []);

    useEffect(() => {
        if (localStorage.getItem("userTypeFleetrax") == "supervisor" ||
            localStorage.getItem("userTypeFleetrax") == "admin") {
            alert("You are not authorized to access this page");
            window.location.href = "/";
        }
    }, []);

    const fetchStatesData = async () => {
        try {
            const data = await Common.state();
            setStatesList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchDistrictsData = async () => {
        try {
            const data = await Common.district(ticket?.stateID);
            setDistrictsList(data.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchShiftTimeData = async () => {
        try {
            const data = await Common.shiftTime();
            setShiftTimeList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchTicketsData = async () => {
        try {
            const oneTicket = await Tickets.findOneTicket({
                id: params.id
            });
            setTicket(oneTicket);
            setCreatedDate(DateUtils.convertDate(oneTicket?.createdAt));
            setCreatedTime(DateUtils.convertTime(oneTicket?.createdAt));
            setsolvedBy(oneTicket?.ccUser?.name);
        } catch (error) {
            console.error("Error fetching tickets data", error);
        }
    };

    const returnTicketDetailsComponent = () => {
        let returnFunction = null;
        switch (ticket?.ticketType?.name) {
            case "Address change":
                returnFunction = TicketDetailsForAddressChange;
                break;
            case "Shift time change":
                returnFunction = TicketDetailsForShiftTimeChange;
                break;
            case "Pickup nodal point change":
                returnFunction = TicketDetailsForPickupChange;
                break;
            case "Ride issue":
                returnFunction = TicketDetailsForRideIssue;
                break;
            default:
                returnFunction = () => null;
        }
        return returnFunction();
    };

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    };

    const BreadcrumbsUrl = () => (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link color="inherit" href="/" onClick={handleClick}>
                    Trip Management
                </Link>
                <Link
                    color="inherit"
                    href="/getting-started/installation/"
                    onClick={handleClick}
                >
                    Not Allotted
                </Link>
                <Typography color="text.primary">LC007</Typography>
            </Breadcrumbs>
        </>
    );
    // functions for accept/reject ticket

    const sendData = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}ticket/adminTicketStatusUpdate`,
                data
            );
            return response;
        } catch (error) {
            return error.response && error.response.data;
        };
    };

    async function handleRejectTicket() {
        const data = {
            id: ticket?.id,
            ticketStatus: "Reject",
            adminComment: adminComment,
            createdBy: parseInt(createdBy)
        }
        const res = await sendData(data);
        if (res.status === 200) {
            message.success(res.data.message);
            fetchTicketsData();
        } else {
            message.error(res.data.message);
        }
    };

    async function handleAcceptTicket() {
        const data = {
            id: ticket?.id,
            ticketStatus: "Accept",
            adminComment: adminComment,
            createdBy: parseInt(createdBy)
        }
        const res = await sendData(data);
        if (res.status === 200) {
            message.success(res.data.message);
            fetchTicketsData();
        } else {
            message.error(res.data.message);
        }
    };
    // different input fields for different ticket types && rendered inside EmployeeDetails()
    const TicketDetailsForShiftTimeChange = () => {
        return (
            <>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <DateInput
                        label={"From Date"}
                        value={DateUtils.convertDate(ticket?.fromDate)}
                        readOnly
                    />
                    <Input
                        label={"Current Shift Time"}
                        value={
                            shiftTimeList?.find(item => item.id == ticket?.currentShiftTime)?.name
                        }
                        readOnly
                    />
                    <Input
                        label={"New Shift Time"}
                        value={
                            shiftTimeList?.find(item => item.id == ticket?.newShiftTime)?.name}
                        readOnly
                    />
                </div>
            </>
        );
    };

    const TicketDetailsForAddressChange = () => {
        return (
            <>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <DateInput
                        label={"From Date"}
                        value={DateUtils.convertDate(ticket?.fromDate)}
                        readOnly
                    />
                    <Input
                        label={"State Name"}
                        value={
                            statesList?.find(item => item.id == ticket?.stateID)?.stateName
                        }
                        readOnly
                    />
                    <Input
                        label={"District Name"}
                        value={
                            districtsList?.find(item => item.id == ticket?.districtID)?.dristrictName
                        }
                        readOnly
                    />
                </div>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <Input
                        label={"Pincode"}
                        value={ticket?.pincode}
                        readOnly
                    />
                    <Input
                        label={"Street Name"}
                        value={ticket?.streetName}
                        readOnly
                    />
                    <Input
                        label={"House Number"}
                        value={ticket?.houseNumber}
                        readOnly
                    />
                </div>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <Input
                        label={"Latitude"}
                        // value={ticket?.houseNumber}
                        readOnly
                    />
                    <Input
                        label={"Longitude"}
                        // value={ticket?.houseNumber}
                        readOnly
                    />
                    <div className="w-[300px]" />
                </div>
            </>
        );
    };

    const TicketDetailsForPickupChange = () => {
        return (
            <>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <Input
                        label={"Current Pickup Nodal Point"}
                        value={ticket?.currentPickupNodalPoint}
                        readOnly
                    />
                    <Input
                        label={"New Pickup Nodal Point"}
                        value={ticket?.newPickupNodalPoint}
                        readOnly
                    />
                    <DateInput
                        label={"From Date"}
                        value={DateUtils.convertDate(ticket?.fromDate)}
                        readOnly
                    />
                </div>
            </>
        );
    };

    const TicketDetailsForRideIssue = () => {
        return (
            <>
                <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                    <Input
                        label={"Ride ID"}
                        value={ticket?.rideID}
                        readOnly
                    />
                    <Input
                        label={"Ride Date"}
                        // value={DateUtils.convertDate(ticket?.rideDate)}
                        readOnly
                    />
                    <Input
                        label={"Ride Type"}
                        // value={DateUtils.convertDate(ticket?.rideType)}
                        readOnly
                    />
                </div>
            </>
        );
    };

    // common for all ticket types
    const EmployeeDetails = () => {
        return (
            <>
                <div className="w-full px-1 flex justify-center items-center">
                    <Card className="w-full mt-6 shadow-md">
                        <div className="w-full flex flex-col gap-3 lg:gap-6 rounded-md">
                            <div className="w-full flex justify-between items-center">
                                <div className="w-full text-start text-lg mt-1 font-medium text-gray-800">
                                    Employee Details
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <Input
                                    label={"Ticket ID"}
                                    value={ticket?.ticketID}
                                    readOnly
                                />
                                <Input
                                    label={"Employee ID"}
                                    value={ticket?.employee?.employeeID}
                                    readOnly
                                />
                                <Input
                                    label={"Employee Name"}
                                    value={ticket?.employee?.employeeName}
                                    readOnly
                                />
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap items-center justify-between gap-3 lg:gap-10">
                                <Input
                                    label={"Gender"}
                                    value={ticket?.employee?.gender}
                                    readOnly
                                />
                                <Input
                                    label={"Company Name"}
                                    value={ticket?.employee?.company?.name}
                                    readOnly
                                />
                                <div className="w-[300px]" />
                            </div>
                            <div className="w-full text-start text-lg mt-1 font-medium text-gray-800">
                                Ticket Details
                            </div>
                            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                                <Input
                                    label={"Ticket Type"}
                                    value={ticket?.ticketType?.name}
                                    readOnly
                                />
                                <DateInput
                                    label={"Raised Date"}
                                    value={DateUtils.convertDate(ticket?.ticketType?.createdAt)}
                                    readOnly
                                />
                                <TimeInput
                                    label={"Raised Time"}
                                    value={DateUtils.convertTime(ticket?.ticketType?.createdAt)}
                                    readOnly
                                />
                            </div>
                            {/* render according to ticket type */}
                            {returnTicketDetailsComponent()}
                            <div className="flex flex-col">
                                <div className="h-fit">
                                    <label
                                        className="text-[#344054] text-[15px] pl-[2px] font-medium"
                                        htmlFor="reason"
                                    >
                                        Reason
                                    </label>
                                </div>
                                <textarea
                                    name="Reason"
                                    rows="5"
                                    className="w-full border border-border-gray outline-none rounded-lg px-3 py-2 placeholder:text-placeholder-gray"
                                    value={ticket?.reason}
                                    readOnly
                                ></textarea>
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    const AdminAction = () => {
        return (
            <>
                <div className=" w-full px-1 flex justify-center items-center ">
                    <Card className=" w-full  mt-6  shadow-md  ">
                        <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                            <div className=" w-full flex justify-between items-center">
                                <div className="flex justify-between items-center w-full text-start text-lg font-medium text-gray-800 ">
                                    <p>Admin Action</p>
                                    <div className="pt-1">
                                        {ticket?.ticketStatus === "Reject" || ticket?.ticketStatus === "Accept" ?
                                            (<StatusCard status={ticket?.ticketStatus} />) :
                                            (<></>)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="h-fit">
                                    <label
                                        className="text-[#344054] text-[15px] pl-[2px] font-medium"
                                        htmlFor="comments"
                                    >
                                        Comments
                                    </label>
                                </div>
                                <textarea
                                    name="comments"
                                    placeholder="Comments..."
                                    rows="5"
                                    className="w-full border border-border-gray outline-none rounded-lg px-3 py-2 placeholder:text-placeholder-gray"
                                    value={ticket?.adminComment === null ? adminComment : ticket?.adminComment}
                                    disabled={ticket?.adminComment !== null}
                                    onChange={(e) => setAdminComment(e.target.value)}
                                />
                            </div>
                            {ticket?.ticketStatus !== "Reject" && ticket?.ticketStatus !== "Accept" && (
                                <div className="w-full flex justify-center items-center gap-8 font-semibold">
                                    <button type="button" className="py-2 px-8 rounded-lg bg-[#F04438] text-white" onClick={handleRejectTicket}>Reject Ticket</button>
                                    <button type="button" className="py-2 px-8 rounded-lg bg-[#12B76A] text-white" onClick={handleAcceptTicket}>Accept Ticket</button>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    const AdminDetails = () => {
        return (
            <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
                    <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                        Ticket Solving Admin Details
                    </div>
                    <>
                        <div className=" w-full flex-wrap gap-5 h-fit py-10 flex justify-between items-center">
                            <Input
                                label={"Admin Name"}
                                placeholder={"Enter created by"}
                                value={solvedBy || "-"}
                                disabled
                            />
                            <DateInput
                                label={"Closing date"}
                                placeholder={"Select closing date"}
                                value={createdDate}
                                disabled
                            />
                            <TimeInput
                                label={"Closing time"}
                                placeholder={"Select closing time"}
                                value={createdTime}
                                disabled
                                readOnly
                            />
                        </div>
                    </>
                </Card>
            </div>
        );
    };

    return (
        <>
            <PageLayout
                title={"Ticket Management"}
                description={"View your ticket details here."}
                buttonWidth={"120px"}
                BreadcrumbsUrl={BreadcrumbsUrl}
            >
                <div className="pr-4">
                    {EmployeeDetails()}
                    {AdminAction()}
                    {AdminDetails()}
                </div>
            </PageLayout>
        </>
    );
}
