import DateUtils from "../../services/utility/DateUtils";

export default function BalanceCards({ item, handleClick }) {
  return (
    <>
      <div class="w-full lg:w-[30%]  m-0  lg:my-3  lg:mx-1 h-auto lg:h-[145px] flex flex-col justify-between rounded-lg border border-solid border-gray-300 bg-white shadow-md">
        <div class="flex justify-between  px-0 lg:px-2 items-center h-full">
          <div class="w-4/12 flex justify-center items-center h-full">
            <div class="avatar w-20 h-20 bg-white rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="#d5d5d5"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </div>
          </div>
          <div class="w-7/12">
            <div class="text-[#175CD3]  font-semibold font-roboto  text-md leading-6">
              {item?.payer && item?.payer?.payerName
                ? item?.payer?.payerName
                : "N/A"}
            </div>
            <div class="text-[#667085] font-roboto text-sm font-medium leading-6">
              Opening Balance:
              <span className=" text-[#C11574] font-bold pl-1">
                ₹ {parseInt(item?.openingBalance)?.toLocaleString()}
              </span>
            </div>
            <div class="text-[#667085]  font-roboto text-sm font-medium leading-6">
              Current Balance:
              <span className="text-[#039855] font-bold pl-1">
                ₹ {parseInt(item?.currentBalance)?.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 flex items-center justify-center border-t border-solid border-gray-300 rounded-b-lg w-full h-10">
          <div class="w-full flex justify-between  px-5 items-center">
            <div className="flex items-center justify-start ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-calendar"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>
              <div className=" text-[#4E5664] pl-2   font-semibold">
                {DateUtils.convertDate(item.date)}
              </div>
            </div>
            <button
              onClick={() => {
                handleClick();
              }}
              class="text-primary-blue px-4 my-3 rounded-md h-6 flex justify-center items-center bg-primary-blue/20 font-inter text-sm font-semibold leading-8"
            >
              View
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
