import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import eye from "../report-page/assets/eye.6bf84ddf18c60bb31dcf.png";
import DateInput from "../../reusable/DateInput";
import Button from "../../reusable/Button";
import Remittance from "../../../services/api/remittanceApi";
import Common from "../../../services/api/commonApi";
import { Modal, Spin, message } from "antd";
import DateUtils from "../../../services/utility/DateUtils";
import Passbook from "../../../services/api/passbookApi";

export default function RemittanceDetailsTable() {

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramsDriverID = params.get("id");

  const [selectAll, setSelectAll] = useState(false);
  const [pushModal, setPushModal] = useState(false);
  const [pushCount, setPushCount] = useState(0);
  const [pushLoading, setPushLoading] = useState(false);

  const [selectedIds, setSelectedIds] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [paidBy, setPaidBy] = useState("");
  const [paidByError, setPaidByError] = useState("");

  const [driverRemittanceList, setDriverRemittanceList] = useState([]);
  const [paidByList, setPaidByList] = useState([]);

  useEffect(() => {
    fetchDriverData();
    fetchPaidByData();
  }, []);

  useEffect(() => {
    fetchDriverDataForFilter();
  }, [fromDate, toDate]);

  const fetchDriverDataForFilter = async () => {
    try {
      const allRemittance = await Remittance.findOne(
        {
          driverId: paramsDriverID,
          fromDate: fromDate,
          toDate: toDate
        }
      );
      setDriverRemittanceList(allRemittance);
    } catch (error) {
      console.error("Error fetching remittance data", error);
    }
  };

  const fetchDriverData = async () => {
    try {
      const allRemittance = await Remittance.findOne(
        {
          driverId: paramsDriverID,
          fromDate: fromDate,
          toDate: toDate
        }
      );
      setDriverRemittanceList(allRemittance);
    } catch (error) {
      console.error("Error fetching remittance data", error);
    }
  };

  const fetchPaidByData = async () => {
    try {
      const response = await Common.getPayer();
      setPaidByList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePushModal = () => {
    if (selectedIds?.length <= 0) {
      message.error("Please select atleast one row");
      return;
    }
    setPushModal(true);
  }

  const createDebits = async () => {
    return new Promise((resolve, reject) => {
      try {
        let sharedTimeoutCounter = 0;
        for (let i = 0; i < selectedIds.length; i++) {
          const item = selectedIds[i];
          try {
            const data = {
              invoicePaidBy: parseInt(paidBy?.id),
              invoicePaidAmount: item?.invoicePaidAmount,
              date: item?.invoicePaidDate,
              paymentMode: item?.invoicePaymentMode,
              transactionId: item?.invoiceTransactionId,
              description:
                item?.companyName +
                "/" +
                item?.routeId +
                "/" +
                item?.routeName +
                "/" +
                item?.tripType +
                "/" +
                DateUtils.convertDate(item?.tripDate),
              entryStatus: "Debit",
              tripId: parseInt(item?.id),
              routeId: item?.routeId,
              routeName: item?.routeName,
              tripDate: item?.tripDate,
            };
            setTimeout(async () => {
              const response = await Passbook.createDebits(data);
              setPushCount(prev => prev + 1);
              if (sharedTimeoutCounter === selectedIds.length - 1) {
                resolve();
              }
              sharedTimeoutCounter++;
            }, i * 1000);
          } catch (err) {
            message.error("Error occurred, please try again");
            console.log(err);
            reject(err);
          }
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
      setTimeout(() => {
        resolve();
      }, selectedIds.length * 500);
    });
  };

  const handlePush = async () => {
    if (!paidBy) {
      setPaidByError("Please select paid by");
      return;
    }
    setPushLoading(true);
    await createDebits();
    try {
      for (let i = 0; i < selectedIds.length; i++) {
        const item = selectedIds[i];
        try {
          const data = {
            tripId: parseInt(item?.id),
            status: 3,
            invoicePaidBy: parseInt(paidBy?.id),
            invoicePaidDate: DateUtils.getCurrentDate()
          };
          setTimeout(async () => {
            const response = await Remittance.pushOne(data);
          }, i * 500);
        } catch (err) {
          message.error("Error occurred, please try again");
          console.log(err);
        }
      }
      message.success("Selected remittance(s) pushed successfully");
      setPaidBy("");
      setTimeout(() => {
        setPushModal(!pushModal);
        setPushLoading(false);
        setSelectedIds([]);
        setPushCount(0);
      }, 800);
    } catch (err) {
      console.log(err);
    }
    fetchDriverData();
  };


  const handleCheckboxChange = (e, row) => {
    if (row) {
      if (e.target.checked) {
        setSelectedIds(prev => [...prev, row]);
      } else {
        setSelectedIds(prev => prev.filter(item => item !== row));
      }
      return;
    }
    if (selectAll) {
      setSelectedIds([]);
      setSelectAll(false);
    } else {
      if (selectedIds.length !== driverRemittanceList.length) {
        setSelectedIds(driverRemittanceList.map(item => item));
      } else {
        setSelectedIds([]);
        setSelectAll(false);
      }
    }
    return;
  };

  const columns = [
    {
      selector: "checkbox",
      headerCheckbox: true,
      id: "allCheckbox",
      name:
        <TableHead width="40px">
          <div className="py-4 pl-1 text-[#3C4048] text-center w-full font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
            <span
              className={"py-4 text-center w-full text-sm font-roboto font-medium"}
            >
              <input
                type="checkbox"
                value={selectAll}
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e)}
                checked={selectAll || selectedIds.length === driverRemittanceList.length}
              />
            </span>
          </div>
        </TableHead>,
      cell: (row) => (
        <TableCell width="40px">
          <div className="py-4 text-[#3C4048] text-center font-medium text-sm font-roboto">
            <span
              className={"py-4 text-center text-sm font-roboto font-medium"}
            >
              <input
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e, row)}
                checked={selectAll || selectedIds.includes(row)}
              />
            </span>
          </div>
        </TableCell>
      ),
    },
    {
      id: "routeID",
      name: <TableHead>Route ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={`/trip-management/${row.routeId}?id=${row.id}`}
          >
            {row.routeId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: <TableHead>Route Name</TableHead>,
      cell: (row) => <TableCell textCenter>{row.routeName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date </TableHead>,
      cell: (row) => <TableCell textCenter>{row.tripDate ? DateUtils.convertDate(row.tripDate) : "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type </TableHead>,
      cell: (row) => <TableCell textCenter>{row.tripType}</TableCell>,
    },
    {
      id: "companyName",
      name: <TableHead>Company Name </TableHead>,
      cell: (row) => <TableCell textCenter>{row.companyName}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead>Status</TableHead>,
      cell: (row) => <TableCell textCenter>{row.tripStatus}</TableCell>,
    },
    {
      id: "onHoldAmount",
      name: <TableHead>On Hold Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.invoicePaidAmount)?.toLocaleString()}</TableCell>,
    },
    // {
    //   id: "paidDate",
    //   name: <TableHead>Paid Date</TableHead>,
    //   cell: (row) => <TableCell>{row.invoicePaidDate ? DateUtils.convertDate(row.invoicePaidDate) : "-"}</TableCell>,
    // },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link to={`/invoice/invoice-details?id=${row.id}`}>
            <img width={35} src={eye} alt="eye" />
          </Link>
        </TableCell>
      ),
    },
  ];

  const textButton = () => {
    return (
      <div className="flex gap-2 items-center justify-center">
        <div>
          <p className="text-base">Push</p>
        </div>
        <svg
          class="bi bi-download transform rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
        </svg>
      </div>
    );
  };

  return (
    <>
      <div className="w-full removeScrollBar pb-5">
        <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
          <div className=" w-full flex lg:gap-8 gap-2  justify-start items-center ">
            <DateInput
              label={"From Date"}
              placeholder={"Select Date"}
              value={fromDate}
              setValue={setFromDate}
            />
            <DateInput
              label={"To Date"}
              placeholder={"Select Date"}
              value={toDate}
              setValue={setToDate}
            />
            {/* <DropDown
            label={"Route ID"}
            placeholder={"Select Route ID"}
            displayValue={"name"}
            data={[]}
            value={driverStatus}
            setValue={setDriverStatus}
            selectAll
          /> */}
          </div>
          <div className=" w-fit flex justify-end items-center mt-[22px]">
            <Button width={"100px"} text={textButton()} onClick={handlePushModal} />
          </div>
        </div>
        <Table columns={columns} rows={driverRemittanceList} />
      </div>
      <Modal
        open={pushModal}
        width={"500px"}
        onCancel={() => setPushModal(false)}
        footer={null}
        centered
      >
        <div className="flex flex-col gap-6 justify-center items-center mt-5">
          {pushLoading === false ? (
            <>
              <DropDown
                label={"Paid By"}
                placeholder={"Select Paid By"}
                displayValue={"payerName"}
                data={paidByList}
                value={paidBy}
                setValue={setPaidBy}
                error={paidByError}
              />
              <Button
                width={"100px"}
                text={"Submit"}
                onClick={() => { handlePush(); }}
              />
            </>
          ) : (
            <div className="flex flex-col gap-6 justify-center items-center mt-2 text-3xl">
              {pushCount !== selectedIds?.length && <Spin size="large" />}
              <div><span className="text-xl text-primary-blue">Pushed</span> : {pushCount} / {selectedIds?.length}</div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
