export default function Button({
    text,
    width,
    bgColor,
    height,
    onClick,
    type,
    icon,
    textColor,
    borderColor,
    paddingRight,
    ...others
}) {

    const buttonStyle = {
        border: borderColor ? "1px solid" : "",
        width: width || "100%",
        height: height || "40px",
        backgroundColor: bgColor || "#1849A9",
        textColor: textColor || "#ffffff",
        borderColor: borderColor || ""
    };

    return (
        <div
            className={`flex items-center rounded-lg`}
            style={{
                width: buttonStyle.width
            }}
        >
            <button
                type={type || "button"}
                onClick={onClick}
                style={{
                    width: buttonStyle.width,
                    height: buttonStyle.height,
                    backgroundColor: buttonStyle.backgroundColor,
                    color: buttonStyle.textColor,
                    border: buttonStyle.border,
                    borderColor: buttonStyle.borderColor
                }}
                className={`flex justify-around ${paddingRight && " pr-4 "} items-center w-full h-full font-medium rounded-lg text-sm lg:text-[17px] px-2`}
                {...others}
            >
                {icon && (
                    <div className="pl-1 pr-2">
                        <img src={icon} alt="icon" />
                    </div>
                )}
                {text}
            </button>
        </div>
    );
};