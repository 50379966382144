import axios from "axios";

const Trip = {
  createTrip: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/create`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  createCopyTrip: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/createCopyTrip`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTrips: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}trip/verify/getTrips`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  getTripCount: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}trip/verify/getTripCount`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  findOneTrip: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}trip/verify/getTripOne/?id=${params.id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  updateTrip: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/updateTrips`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  appAssign: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/appAssign`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  watAssign: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/watAssign`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  forceAssign: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/forceAssign`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  deleteTrip: async (params) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}trip/verify/delete/${params.id}`,
        {},
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  updateTripComplete: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/updateTripComplete`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  revertCompletedTrip: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}trip/verify/revertCompletedTrip`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Trip;
