import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import LoginLayout from "../../components/layouts/login/LoginLayout";
import LoginVerify from "../../services/api/loginApi";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const Valid = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Please enter email");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter password");
      isValid = false;
    }
    // else if (password.length < 6) {
    //   setPasswordError("Please enter a valid password");
    //   isValid = false;
    // } 
    else {
      setPasswordError("");
    }

    return isValid;
  };

  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (Valid()) {
      try {
        const userData = await LoginVerify.signin(email, password);
        if (userData) {
          navigate("/home");
        }
      } catch (error) {
        console.error("Error handling sign in", error);
      }
    }
  };

  return (
    <LoginLayout title={"Hello Again !"} text={"Please enter your details"}>
      <form className="flex flex-col px-6  lg:px-0  gap-6 justify-center items-center   w-full lg:w-[400px]  ">
        {/* email input */}
        <Input
          label={"Email"}
          placeholder={"Enter your email address"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError}
        />
        {/* password input */}
        <Input
          label={"Password"}
          placeholder={"Enter your password"}
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError}
        />
        {/* remember-me input */}
        <div className="flex justify-between w-full my-1">
          <div className="flex gap-2 justify-center items-center">
            <input type="checkbox" />
            <p className="font-semibold text-text-black text-sm lg:text-base ">
              Remember me
            </p>
          </div>
          <Link
            to={"/forgot-password"}
            className="text-primary-blue font-semibold text-sm lg:text-base "
          >
            Forgot password?
          </Link>
        </div>
        {/* sign-in button */}
        <Button type="submit" text="Sign in" onClick={handleSignIn} />
      </form>
    </LoginLayout>
  );
}
