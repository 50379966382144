import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import TripStatus from "../../../components/reusable/Table/TripStatus"
import Driver from "../../../services/api/driverApi";
import Common from "../../../services/api/commonApi";

export default function DriverTable() {

  const storedSearch = localStorage.getItem("driverSearch");
  const initialSearch = storedSearch ? storedSearch : "";
  const [search, setSearch] = useState(initialSearch);
  
  const storedSearchVehicleNo = localStorage.getItem("driverVehicleNo");
  const initialSearchVehicleNo = storedSearchVehicleNo ? storedSearchVehicleNo : "";
  const [vehicleNo, setVehicleNo] = useState(initialSearchVehicleNo);

  const storedVehicleType = localStorage.getItem("driverVehicleType");
  const initialVehicleType = storedVehicleType
    ? JSON.parse(storedVehicleType)
    : "";

  const [vehicleType, setVehicleType] = useState(initialVehicleType);

  const storedDriverStatus = localStorage.getItem("driverStatus");
  const initialDriverStatus = storedDriverStatus
    ? JSON.parse(storedDriverStatus)
    : "";

  const [driverStatus, setDriverStatus] = useState(initialDriverStatus);
  const [driverList, setDriverList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);

  const driverStatusList = [
    { name: "Dedicated", id: "Dedicated" },
    { name: "Part Time", id: "Part Time" },
  ];

  const fetchVehicleTypeData = async () => {
    try {
      const allVehicleTypes = await Common.vehicleType();
      setVehicleTypeList(allVehicleTypes);
    } catch (error) {
      console.error("Error fetching vehicle-type data", error);
    }
  };

  window.onbeforeunload = () => {
    localStorage.removeItem("driverSearch");
    localStorage.removeItem("driverVehicleType");
    localStorage.removeItem("driverStatus");
  };


  useEffect(() => {
    fetchVehicleTypeData();
  }, []);

  const fetchDriverData = async () => {
    try {
      const params = {
        searchQuery: search,
        vehicleNo: vehicleNo,
        vehicleTypeID: vehicleType.id,
        driverStatus: driverStatus.id,
      }
      const data = await Driver.getDrivers(params);
      setDriverList(data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    fetchDriverData()
    localStorage.setItem("driverSearch", search);
    localStorage.setItem("driverVehicleNo", vehicleNo);
  }, [search, vehicleType, driverStatus,vehicleNo])

  const columns = [
    {
      id: "driverID",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={`/driver-management/${row.driverID}?id=${row.id}`}
          >
            {row.driverID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead position="left" width="180px">Driver Name</TableHead>,
      cell: (row) => <TableCell position="left" width="180px">{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead position="left">Vehicle Number</TableHead>,
      cell: (row) => <TableCell position="left">{row.vehicleNo}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead position="left">Vehicle Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.vehicleName}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead position="left">Vehicle Type</TableHead>,
      cell: (row) => <TableCell position="left">{row.vehicleType?.name}</TableCell>,
    },
    {
      id: "mobileNo",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "driverStatus",
      name: <TableHead>Driver Status</TableHead>,
      cell: (row) => <TableCell>{row.driverStatus ? row.driverStatus : "-"}</TableCell>,
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"search"}
          placeholder={"name/contact number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Input
          label={"Search by Vehicle No."}
          type={"search"}
          placeholder={"vehicle number"}
          value={vehicleNo}
          onChange={(e) => setVehicleNo(e.target.value)}
        />
        <DropDown
          label={"Vehicle Type"}
          placeholder={"Select vehicle type"}
          displayValue={"name"}
          data={vehicleTypeList}
          value={vehicleType}
          setValue={setVehicleType}
          setLocalStorage={"driverVehicleType"}
          selectAll
        />
        <DropDown
          label={"Driver Status"}
          placeholder={"Select driver status"}
          displayValue={"name"}
          data={driverStatusList}
          value={driverStatus}
          setValue={setDriverStatus}
          setLocalStorage={"driverStatus"}
          selectAll
        />
      </div>
      <Table columns={columns} rows={driverList} />
    </div>
  );
}
