import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Balance from "./Balance";
import Credits from "./Credit";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function PassbookTabNavigation() {
  const [activeKey, setActiveKey] = useState("balance");

  useEffect(() => {
    const key = sessionStorage.getItem("passbookTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("passbookTabNavigation", key);
    setActiveKey(key);
  };

  // Define the tab items with labels wrapped in LabelForTabs component
  const items = [
    {
      key: "balance",
      label: (
        <LabelForTabs isActive={activeKey === "balance"}>Balance</LabelForTabs>
      ),
      children: <Balance activeKey={activeKey} />,
    },
    {
      key: "credits",
      label: (
        <LabelForTabs isActive={activeKey === "credits"}>Credits</LabelForTabs>
      ),
      children: <Credits activeKey={activeKey} />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default PassbookTabNavigation;
