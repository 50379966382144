import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import ExcelJS from "exceljs";
import Reports from "../../../services/api/reportApi";
import Common from "../../../services/api/commonApi";
import DateUtils from "../../../services/utility/DateUtils";

export default function VehicelTripTable() {

  const storedFromDate = localStorage.getItem("driverReportFromDate");
  const initialFromDate = storedFromDate ? storedFromDate : DateUtils.getTMinusDate(6);
  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("driverReportToDate");
  const initialToDate = storedToDate ? storedToDate : DateUtils.getCurrentDate();
  const [toDate, setToDate] = useState(initialToDate);

  const storedTripDate = localStorage.getItem("driverReportTripDate");
  const initialTripDate = storedTripDate ? storedTripDate : "";
  const [tripDate, setTripDate] = useState(initialTripDate);

  const storedCompany = localStorage.getItem("driverReportCompany");
  const initialCompany = storedCompany ? JSON.parse(storedCompany) : "";

  const [company, setCompany] = useState(initialCompany);

  const storedTripType = localStorage.getItem("driverReportTripType");
  const initialTripType = storedTripType ? JSON.parse(storedTripType) : "";
  const [tripType, setTripType] = useState(initialTripType);

  const [companyList, setCompanyList] = useState([]);
  const [tripTypeList, setTripTypeList] = useState([]);
  const [accountsData, setAccountsData] = useState([]);

  window.onbeforeunload = () => {
    localStorage.removeItem("driverReportFromDate");
    localStorage.removeItem("driverReportToDate");
    localStorage.removeItem("driverReportTripDate");
    localStorage.removeItem("driverReportCompany");
    localStorage.removeItem("driverReportTripType");
  };
  

  useEffect(() => {
    fetchCompanyData();
    fetchTripTypeData();
  }, []);

  useEffect(() => {
    fetchAccountsData();
  }, [company, fromDate, tripType, toDate, tripDate]);

  const fetchAccountsData = async () => {
    try {
      const params = {
        companyId: company?.id,
        fromDate: fromDate,
        toDate: toDate,
        tripType: tripType?.id,
        tripDate: tripDate,
      };
      const data = await Reports.getTripsVehicle(params);
      setAccountsData(data);
    } catch (error) {
      console.error("Error fetching reports data", error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const data = await Common.company();
      setCompanyList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTripTypeData = async () => {
    try {
      const data = await Common.tripType();
      setTripTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const columnData =
    accountsData.length && accountsData?.map((item, index) => {
      item.sNo = index + 1;
      return item;
    });

  const columns = [
    {
      id: "sNo",
      name: <TableHead width="90px">S.No</TableHead>,
      cell: (row) => (
        <TableCell width="90px">
          {row.sNo}
        </TableCell>
      ),
    },
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.driver.driverID || "-"}
        </TableCell>
      ),
    },
    {
      id: "vehicleNo",
      name: <TableHead>Vehicle No</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.driver.vehicleNo || "-"}
        </TableCell>
      ),
    },

    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => (
        <TableCell textCenter>
          {row.driver.driverName || "-"}
        </TableCell>
      ),
    },
    {
      id: "tripsCompleted",
      name: <TableHead>Trips Completed</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.totalCount || "-"}
        </TableCell>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Driver Data");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define cell style
    const cellStyle = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: { vertical: "center", horizontal: "center" },
    };

    // Adding headers
    const headers = ["S.No", "Driver ID", "Vehicle No", "Driver Name", "Trips Completed"];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Adding data rows
    accountsData.length && accountsData?.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1,
        row.driver.id,
        row.driver.vehicleNo,
        row.driver.driverName,
        row.totalCount
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
      });
    });

    // Auto-fit columns
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 12 ? 12 : maxLength;
    });

    // Generate buffer and download the file
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `DriverData_${new Date().toLocaleDateString()}.xlsx`;
    link.click();
  };

  return (
    <div className="w-full pb-5 text-base text-black">
      <div className="flex  flex-wrap lg:gap-x-8 2xl:gap-x-2 gap-2 w-full justify-center  lg:pl-2  lg:pr-10 lg:justify-start 2xl:justify-between items-center mb-5  lg:mb-5  mt-2 lg:mt-6">
        <DropDown
          label={"Company"}
          placeholder={"Select company"}
          displayValue={"name"}
          data={companyList}
          value={company}
          setValue={setCompany}
          setLocalStorage={"driverReportCompany"}
          selectAll
        />
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          setLocalStorage={"driverReportFromDate"}
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          setLocalStorage={"driverReportToDate"}
        />
        <DateInput
          label={"Trip date"}
          placeholder={"Select trip date"}
          value={tripDate}
          setValue={setTripDate}
          setLocalStorage={"driverReportTripDate"}
        />
        <DropDown
          label={"Trip Type"}
          placeholder={"Select trip type"}
          displayValue={"name"}
          data={tripTypeList}
          value={tripType}
          setValue={setTripType}
          setLocalStorage={"driverReportTripType"}
          selectAll
        />
      </div>
      <div className="flex justify-end w-[98.5%] pr-5   ">
        <div className="flex ">
          <button onClick={handleExport} className="flex  mb-5 ">
            <div className="bg-primary-blue/95 hover:bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
              <span className="mr-3">Download Now</span>
              <svg
                className="text-2xl "
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <Table columns={columns} rows={columnData} />
    </div>
  );
}
