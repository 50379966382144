import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Card, Typography, message } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import edit from "../../assets/editIcon.png";
import Input from "../../components/reusable/Input";
import Button from "../../components/reusable/Button";

import Common from "../../services/api/commonApi";
import Invoice from "../../services/api/invoiceApi";
import DateUtils from "../../services/utility/DateUtils";
import ConfirmModal from "../../components/modals/ConfirmModal";
import Passbook from "../../services/api/passbookApi";
import ErrorMessage from "../../components/reusable/ErrorMessage";
import Outstation from "../../services/api/outstationApi";

export default function OutStationInvoice() {

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const paramsInvoiceID = params.get("id");

    const [editOn, setEditOn] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const [invoicePaidAmount, setInvoicePaidAmount] = useState(0);
    const [invoicePaymentDate, setInvoicePaymentDate] = useState(null);
    const [invoicePaymentStatus, setInvoicePaymentStatus] = useState(null);
    const [invoicePaymentMode, setInvoicePaymentMode] = useState(null);
    const [invoiceTransactionId, setInvoiceTransactionId] = useState(null);
    const [invoicePaidBy, setInvoicePaidBy] = useState(null);

    const [invoicePaidAmountError, setInvoicePaidAmountError] = useState(null);
    const [invoicePaymentDateError, setInvoicePaymentDateError] = useState(null);
    const [invoicePaymentStatusError, setInvoicePaymentStatusError] = useState(null);
    const [invoicePaymentModeError, setInvoicePaymentModeError] = useState(null);
    const [invoiceTransactionIdError, setInvoiceTransactionIdError] = useState(null);
    const [invoicePaidByError, setInvoicePaidByError] = useState(null);

    const [invoice, setInvoice] = useState("");
    const [paymentStatusList, setPaymentStatusList] = useState([]);
    const [paymentModeList, setPaymentModeList] = useState([]);
    const [paidByList, setPaidByList] = useState([]);

    useEffect(() => {
        if(localStorage.getItem("userTypeFleetrax") == "supervisor" ){
          alert("You are not authorized to access this page");
          window.location.href = "/";
        }
      }, []);

    useEffect(() => {
        fetchInvoiceData();
        fetchPaymentStatusData();
        fetchPaymentModeData();
        fetchPaidByData();
    }, []);

    useEffect(() => {
        setInvoicePaidByError("");
        setInvoiceTransactionIdError("");
        setInvoicePaymentModeError("");
        setInvoicePaymentStatusError("");
        setInvoicePaymentDateError("");
        setInvoicePaidAmountError("");
    }, [invoicePaymentStatus]);

    const fetchInvoiceData = async () => {
        try {
            const response = await Outstation.getOutstationTripOne({ id: paramsInvoiceID });
            setInvoice(response);
            setInvoicePaidAmount(response.invoicePaidAmount);
            setInvoicePaymentDate(response.invoicePaymentDate);
            setInvoicePaymentStatus(response.invoicePaymentStatusID);
            setInvoicePaymentMode(response.invoicePaymentModeID);
            setInvoiceTransactionId(response.invoiceTransactionId);
            setInvoicePaidBy(response.invoicePaidByID);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchPaymentStatusData = async () => {
        try {
            const response = await Common.getPaymentStatus();
            setPaymentStatusList(response);
        } catch (err) {
            console.log(err);
        }
    };
    const fetchPaymentModeData = async () => {
        try {
            const response = await Common.getPaymentMode();
            setPaymentModeList(response);
        } catch (err) {
            console.log(err);
        }
    };
    const fetchPaidByData = async () => {
        try {
            const response = await Common.getPayer();
            setPaidByList(response);
        } catch (err) {
            console.log(err);
        }
    };

    const isValid = () => {
        let valid = true;

        if (!invoicePaidBy) {
            setInvoicePaidByError("Please enter Paid By");
            valid = false;
        } else {
            setInvoicePaidByError("");
        }

        if (invoicePaidAmount <= 0) {
            setInvoicePaidAmountError("Paid amount must be greater than 0");
            valid = false;
        } else {
            setInvoicePaidAmountError("");
        }
        if (!invoicePaymentDate) {
            setInvoicePaymentDateError("Payment date is required");
            valid = false;
        } else {
            setInvoicePaymentDateError("");
        }
        if (!invoicePaymentStatus) {
            setInvoicePaymentStatusError("Please select Payment Status");
            valid = false;
        } else {
            setInvoicePaymentStatusError("");
        }
        if (!invoicePaymentMode) {
            setInvoicePaymentModeError("Please select Payment Mode");
            valid = false;
        } else {
            setInvoicePaymentModeError("");
        }
        if (!invoiceTransactionId) {
            setInvoiceTransactionIdError("Please enter Transaction ID");
            valid = false;
        } else {
            setInvoiceTransactionIdError("");
        }
        return valid;
    };

    const createInvoiceData = async () => {
        try {
            const data = {
                invoicePaidBy: parseInt(invoicePaidBy),
                invoicePaidAmount: parseInt(invoicePaidAmount),
                date: invoicePaymentDate,
                paymentMode: invoicePaymentMode,
                transactionId: invoiceTransactionId,
                description:
                    invoice?.companyName +
                    "/" +
                    invoice?.routeId +
                    "/" +
                    invoice?.routeName +
                    "/" +
                    invoice?.tripType +
                    "/" +
                    DateUtils.convertDate(invoice?.tripDate),
                entryStatus: "Debit",
                tripId: parseInt(paramsInvoiceID),
                routeId: invoice?.routeId,
                routeName: invoice?.routeName,
                tripDate: invoice?.tripDate,
            }
            const response = await Passbook.createDebits(data);
        } catch (err) {
            console.log(err);
        }
    };

    const updateInvoiceData = async () => {
        if ((invoicePaymentStatus == 3 || invoicePaymentStatus == 2) && isValid()) {
            // createInvoiceData();
        } else if ((invoicePaymentStatus == 3 || invoicePaymentStatus == 2) && isValid() === false) {
            message.error("Please fill all the fields");
            return;
        }
        try {
            const data = {
                id: parseInt(paramsInvoiceID),
                invoicePaymentStatusID: parseInt(invoicePaymentStatus),
                invoicePaidAmount: parseInt(invoicePaidAmount),
                invoicePaymentDate: invoicePaymentDate,
                invoicePaymentModeID: parseInt(invoicePaymentMode),
                invoiceTransactionId: parseInt(invoiceTransactionId),
                invoicePaidByID: parseInt(invoicePaidBy),
            }
            const response = await Outstation.updateOutstation(data);
            if (response.data.code === 200) {
                message.success("Invoice updated successfully");
                setInvoicePaidBy("");
                setInvoiceTransactionId("");
                setInvoicePaymentMode("");
                setInvoicePaymentStatus("");
                setInvoicePaymentDate("");
                setInvoicePaidAmount("");
                setEditOn(!editOn);
                fetchInvoiceData();
            } else {
                message.error(response.data.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    const BreadcrumbsUrl = () => (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link color="inherit" href="/" onClick={handleClick}>
                    Trip Management
                </Link>
                <Link
                    color="inherit"
                    href="/getting-started/installation/"
                    onClick={handleClick}
                >
                    Not Allotted
                </Link>
                <Typography color="text.primary">LC007</Typography>
            </Breadcrumbs>
        </>
    );

    return (
        <>
            <PageLayout
                title={"Invoice Details"}
                description={"View  your Invoices Details here"}
                BreadcrumbsUrl={BreadcrumbsUrl}
            >
                <div className=" flex justify-between mt-10  w-full items-center gap-8 lg:flex-nowrap  px-2 flex-wrap">
                    <Card title={"Trip Details"} className=" w-full shadow-xl">
                        <div className=" flex flex-col gap-1">
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Route ID
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.routeId || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    From Location:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.pickupLocation || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    To Location:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.dropLocation || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Trip Start Date
                                </div>
                                <div className=" w-auto font-normal text-base">
                                    : {DateUtils.convertDate(invoice?.tripStartDate) || "-"}
                                </div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Trip End Date
                                </div>
                                <div className=" w-auto font-normal text-base">
                                    : {DateUtils.convertDate(invoice?.tripEndDate) || "-"}
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card title="Trip Summary" className=" w-full shadow-xl">
                        <div className=" flex flex-col gap-1">
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    No of Guest:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.noOfGuest || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Vehicle Type:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.driver?.vehicleType?.name || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Pickup Time
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.pickupTime || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Drop Time
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.dropTime || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Trip Type
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.tripType?.name || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Trip Amount:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.tripAmount || "-"}</div>
                            </div>
                        </div>
                    </Card>
                    <Card title="Driver & Vehicle Details" className=" w-full shadow-xl">
                        <div className=" flex flex-col gap-1">
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Driver ID
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.driver?.driverID || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Driver Name
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.driver?.driverName || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Vehicle Number:
                                </div>
                                <div className=" w-auto font-normal text-base">
                                    : {invoice?.driver?.vehicleNo || "-"}
                                </div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Vehicle Type:
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.driver?.vehicleType?.name || "-"}</div>
                            </div>
                            <div className=" flex pr-3 items-center">
                                <div className=" w-[145px] font-semibold text-base">
                                    Vehicle Name
                                </div>
                                <div className=" w-auto font-normal text-base">: {invoice?.driver?.vehicleName || "-"}</div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className=" flex justify-between mt-10  w-full items-center gap-8 lg:flex-nowrap  px-2 flex-wrap">
                    <Card className=" shadow-xl w-full">
                        <div className=" w-full justify-end items-center flex">
                            {!editOn && (
                                <button onClick={() => setEditOn(!editOn)} className="">
                                    <img src={edit} alt="" />
                                </button>
                            )}
                        </div>
                        <div class="flex flex-wrap  lg:flex-nowrap  pt-5  justify-between items-center w-full">
                            <Input
                                label="Paid Amount"
                                id="paid-amount"
                                type="text"
                                placeholder="Enter paid amount"
                                onChange={(e) => setInvoicePaidAmount(e.target.value)}
                                value={invoicePaidAmount}
                                error={invoicePaidAmountError}
                                disabled={!editOn}
                            />
                            <DateInput
                                label="Payment Date"
                                id="payment-date"
                                type="date"
                                placeholder="Select payment date"
                                value={invoicePaymentDate}
                                setValue={setInvoicePaymentDate}
                                error={invoicePaymentDateError}
                                disabled={!editOn}
                            />
                            {/* 
              <DropDown
                label={"Payment Status"}
                placeholder={"Select Payment Status"}
                data={paymentStatusList}
                defaultValue={invoicePaymentStatus}
                value={invoicePaymentStatus}
                setValue={setInvoicePaymentStatus}
                displayValue={"name"}
                disabled={!editOn}
              /> */}
                            <div>
                                <div className="text-[#344054] text-[15px] pl-1 font-medium">
                                    Payment Status
                                </div>
                                <select
                                    className="form-control border border-border-gray min-w-[300px] py-2 rounded-md px-2 disabled:bg-gray-200"
                                    id="categoryName"
                                    defaultChecked={invoicePaymentStatus}
                                    onChange={(e) => setInvoicePaymentStatus(e.target.value)}
                                    value={invoicePaymentStatus}
                                    disabled={!editOn}
                                >
                                    <option value="">Select Payment Status</option>
                                    {paymentStatusList?.map((item) => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                                {invoicePaymentStatusError && (
                                    <ErrorMessage>
                                        {invoicePaymentStatusError}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>
                        <div class="flex flex-wrap  mt-8 lg:flex-nowrap justify-between items-center w-full">
                            {/* <DropDown
                label={"Payment Mode"}
                placeholder={"Select Payment Status"}
                displayValue={"name"}
                data={paymentModeList}
                value={invoicePaymentMode}
                setValue={setInvoicePaymentMode}
                disabled={!editOn}
              /> */}
                            <div>
                                <div className="text-[#344054] text-[15px] pl-1 font-medium">
                                    Payment Mode
                                </div>
                                <select
                                    className={`form-control border ${invoicePaymentModeError ? "border-red-400" : "border-border-gray"}  min-w-[300px] py-2 rounded-md px-2 disabled:bg-gray-200`}
                                    id="categoryName"
                                    defaultChecked={invoicePaymentMode}
                                    onChange={(e) => setInvoicePaymentMode(e.target.value)}
                                    value={invoicePaymentMode}
                                    disabled={!editOn}
                                >
                                    <option value="">Select Payment Mode</option>
                                    {paymentModeList?.map((item) => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                                {invoicePaymentModeError && (
                                    <ErrorMessage>
                                        {invoicePaymentModeError}
                                    </ErrorMessage>
                                )}
                            </div>
                            <Input
                                label="Transaction ID"
                                id="paid-amount"
                                type="text"
                                placeholder="Enter Transaction ID"
                                onChange={(e) => setInvoiceTransactionId(e.target.value)}
                                value={invoiceTransactionId}
                                error={invoiceTransactionIdError}
                                disabled={!editOn}
                            />

                            {/* <DropDown
                label={"Paid By"}
                placeholder={"Select Paid by"}
                displayValue={"payerName"}
                data={paidByList}
                value={invoicePaidBy}
                setValue={setInvoicePaidBy}
                disabled={!editOn}
              /> */}
                            <div>
                                <div className="text-[#344054] text-[15px] pl-1 font-medium">
                                    Paid By
                                </div>
                                <select
                                    className={`form-control border ${invoicePaidByError ? "border-red-400" : "border-border-gray"} min-w-[300px] py-2 rounded-md px-2 disabled:bg-gray-200`}
                                    id="categoryName"
                                    defaultChecked={invoicePaidBy}
                                    onChange={(e) => setInvoicePaidBy(e.target.value)}
                                    value={invoicePaidBy}
                                    disabled={!editOn}
                                >
                                    <option value="">Select Paid by</option>
                                    {paidByList?.map((item) => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.payerName}
                                        </option>
                                    ))}
                                </select>
                                {invoicePaidByError && (
                                    <ErrorMessage>
                                        {invoicePaidByError}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>
                        {
                            <div className="flex justify-center mt-10">
                                {editOn && (
                                    <div className=" flex justify-center items-center gap-5">
                                        <Button
                                            text="Discard"
                                            width={100}
                                            bgColor="#ffffff"
                                            textColor="#ed1607"
                                            borderColor="#ed1607"
                                            onClick={() => setIsConfirmModalOpen(true)}
                                        />
                                        <Button
                                            text="Save"
                                            width={100}
                                            onClick={() => { updateInvoiceData(); }}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                    </Card>
                </div>
            </PageLayout>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                setIsOpen={setIsConfirmModalOpen}
                title={"Discard changes?"}
                description={"All unsaved changes will be lost."}
                button1={"Yes"}
                onClickButton1={() => {
                    setInvoicePaidBy("");
                    setInvoiceTransactionId("");
                    setInvoicePaymentMode("");
                    setInvoicePaymentStatus("");
                    setInvoicePaymentDate("");
                    setInvoicePaidAmount("");

                    setInvoicePaidByError("");
                    setInvoiceTransactionIdError("");
                    setInvoicePaymentModeError("");
                    setInvoicePaymentStatusError("");
                    setInvoicePaymentDateError("");
                    setInvoicePaidAmountError("");

                    fetchInvoiceData();
                    setEditOn(!editOn);
                    setIsConfirmModalOpen(false)
                }}
                button2={"No"}
                onClickButton2={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
            />
        </>
    );
}
