import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Card, Modal, Typography, message } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import addTrip from "../../assets/addIcon.png";
import OutStationTabNavigation from "../../components/outstation/OutstationTabNavigation";
import { useEffect, useState } from "react";
import DropDown from "../../components/reusable/DropDown";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import DateInput from "../../components/reusable/DateInput";
import Input from "../../components/reusable/Input";
import TimeInput from "../../components/reusable/TimeInput";
import Button from "../../components/reusable/Button";
import Common from "../../services/api/commonApi";
import Driver from "../../services/api/driverApi";
import Outstation from "../../services/api/outstationApi";

export default function OutStation() {
  const [showAddTripModal, setShowAddTripModal] = useState(false);

  const [routeId, setRouteId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tripType, setTripType] = useState("");
  const [guestName, setGuestName] = useState("");
  const [tripStartDate, setTripStartDate] = useState(null);
  const [tripEndDate, setTripEndDate] = useState(null);
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [guestContactNumber, setGuestContactNumber] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState("");
  const [driver, setDriver] = useState("");
  const [tripStatus, setTripStatus] = useState("Active");

  const [routeIdError, setRouteIdError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [tripTypeError, setTripTypeError] = useState("");
  const [guestNameError, setGuestNameError] = useState("");
  const [tripStartDateError, setTripStartDateError] = useState("");
  const [tripEndDateError, setTripEndDateError] = useState("");
  const [pickupLocationError, setPickupLocationError] = useState("");
  const [dropLocationError, setDropLocationError] = useState("");
  const [pickupTimeError, setPickupTimeError] = useState("");
  const [dropTimeError, setDropTimeError] = useState("");
  const [guestContactNumberError, setGuestContactNumberError] = useState("");
  const [numberOfDaysError, setNumberOfDaysError] = useState("");
  const [vehicleNameError, setVehicleNameError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [numberOfGuestsError, setNumberOfGuestsError] = useState("");
  const [driverError, setDriverError] = useState("");
  const [tripStatusError, setTripStatusError] = useState("");

  const [isNewDataAdded, setIsNewDataAdded] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [tripTypeList, setTripTypeList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [driverList, setDriverList] = useState([]);

  useEffect(() => {
    fetchCompanyData();
    fetchTripTypeData();
    fetchVehicleTypeData();
    fetchDriverData();
    // fetchOutstationData();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const isValid = () => {
    let isValid = true;

    if (!routeId) {
      setRouteIdError("Please enter route id");
      isValid = false;
    } else {
      setRouteIdError("");
    }
    if (!companyName) {
      setCompanyNameError("Please enter company name");
      isValid = false;
    } else {
      setCompanyNameError("");
    }
    if (!tripType) {
      setTripTypeError("Please select trip type");
      isValid = false;
    } else {
      setTripTypeError("");
    }
    if (!guestName) {
      setGuestNameError("Please enter guest name");
      isValid = false;
    } else {
      setGuestNameError("");
    }
    if (!tripStartDate) {
      setTripStartDateError("Please select trip start date");
      isValid = false;
    } else {
      setTripStartDateError("");
    }
    if (!tripEndDate) {
      setTripEndDateError("Please select trip end date");
      isValid = false;
    } else {
      setTripEndDateError("");
    }
    if (!pickupLocation) {
      setPickupLocationError("Please enter pickup location");
      isValid = false;
    } else {
      setPickupLocationError("");
    }
    if (!dropLocation) {
      setDropLocationError("Please enter drop location");
      isValid = false;
    } else {
      setDropLocationError("");
    }
    if (!pickupTime) {
      setPickupTimeError("Please enter pickup time");
      isValid = false;
    } else {
      setPickupTimeError("");
    }
    if (!dropTime) {
      setDropTimeError("Please enter drop time");
      isValid = false;
    } else {
      setDropTimeError("");
    }
    if (!guestContactNumber) {
      setGuestContactNumberError("Please enter guest contact number");
      isValid = false;
    } else {
      setGuestContactNumberError("");
    }
    if (numberOfDays <= 0) {
      setNumberOfDaysError("Please enter a valid number of days");
      isValid = false;
    } else {
      setNumberOfDaysError("");
    }
    if (!vehicleName) {
      setVehicleNameError("Please enter vehicle name");
      isValid = false;
    } else {
      setVehicleNameError("");
    }
    if (!vehicleType) {
      setVehicleTypeError("Please select vehicle type");
      isValid = false;
    } else {
      setVehicleTypeError("");
    }
    if (numberOfGuests <= 0) {
      setNumberOfGuestsError("Please enter a valid number of guests");
      isValid = false;
    } else {
      setNumberOfGuestsError("");
    }
    // if (!driver) {
    //   setDriverError("Please enter driver");
    //   isValid = false;
    // } else {
    //   setDriverError("");
    // }
    // if (!tripStatus) {
    //   setTripStatusError("Please select trip status");
    //   isValid = false;
    // } else {
    //   setTripStatusError("");
    // }

    return isValid;
  };

  const createOutstationData = async () => {
    if (!isValid()) {
      return;
    }
    try {
      const data = {
        routeId: routeId?.toUpperCase(),
        companyID: parseInt(companyName?.id),
        tripTypeID: parseInt(tripType?.id),
        guestName: guestName,
        tripStartDate: tripStartDate,
        tripEndDate: tripEndDate,
        pickupLocation: pickupLocation,
        dropLocation: dropLocation,
        pickupTime: pickupTime,
        dropTime: dropTime,
        guestContactNumber: parseInt(guestContactNumber),
        noOfDays: parseInt(numberOfDays),
        vehicleName: vehicleName,
        vehicleTypeID: parseInt(vehicleType?.id),
        noOfGuest: parseInt(numberOfGuests),
        tripStatusID: 0,
        // tripAmount : parseInt(tripAmount),
        // allocatedDriverID: parseInt(driver?.id),
      };
      const response = await Outstation.createOutstation(data);
      if (response.status) {
        message.success("Outstation created successfully");
        setShowAddTripModal(false);
        setIsNewDataAdded(!isNewDataAdded);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };
  const fetchTripTypeData = async () => {
    try {
      const data = await Common.tripType();
      setTripTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchVehicleTypeData = async () => {
    try {
      const data = await Common.vehicleType();
      setVehicleTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchDriverData = async () => {
    try {
      const data = await Driver.getDrivers();
      setDriverList(data?.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Outstation Trips"}
        description={"View  your outstation trips"}
        BreadcrumbsUrl={BreadcrumbsUrl}
        buttonTitle={"Add Trip"}
        paddingRight={"0px"}
        setAddTripModal={() => {
          setShowAddTripModal(true);
        }}
      >
        <div className=" w-full flex mt-7">
          <OutStationTabNavigation
            companyList={companyList}
            tripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            driverList={driverList}
            isNewDataAdded={isNewDataAdded}
          />
        </div>
        <Modal
          centered
          onCancel={() => {
            setShowAddTripModal(false);
            setRouteId("");
            setCompanyName("");
            setTripType("");
            setGuestName("");
            setTripStartDate("");
            setTripEndDate("");
            setPickupLocation("");
            setDropLocation("");
            setPickupTime("");
            setDropTime("");
            setGuestContactNumber("");
            setNumberOfDays("");
            setVehicleName("");
            setVehicleType("");
            setNumberOfGuests("");

            setRouteIdError("");
            setCompanyNameError("");
            setTripTypeError("");
            setGuestNameError("");
            setTripStartDateError("");
            setTripEndDateError("");
            setNumberOfDaysError("");
            setVehicleNameError("");
            setVehicleTypeError("");
            setNumberOfGuestsError("");
            setPickupLocationError("");
            setDropLocationError("");
            setPickupTimeError("");
            setDropTimeError("");
            setGuestContactNumberError("");
          }}
          width={700}
          title={
            <div className="flex gap-4 items-center">
              <img src={addTrip} width={40} alt="add" />
              <p>Add Trip</p>
            </div>
          }
          visible={showAddTripModal}
          footer={null}
          className="my-10"
        >
          <div className=" flex w-full gap-10  flex-wrap  lg:flex-nowrap ">
            <div className=" w-full">
              <Card className="border-none">
                <div className=" flex flex-col gap-y-5 gap-x-3">
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <Input
                      label={"Route ID"}
                      placeholder={"Enter route ID"}
                      value={routeId?.toUpperCase()}
                      setValue={setRouteId}
                      onChange={(e) => setRouteId(e.target.value)}
                      error={routeIdError}
                      mandate
                    />
                    <DropDown
                      label={"Company Name"}
                      placeholder={"Select company"}
                      displayValue={"name"}
                      data={companyList}
                      value={companyName}
                      setValue={setCompanyName}
                      error={companyNameError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <DropDown
                      label={"Trip Type"}
                      placeholder={"Select trip type"}
                      displayValue={"name"}
                      data={tripTypeList}
                      value={tripType}
                      setValue={setTripType}
                      error={tripTypeError}
                      mandate
                    />
                    <Input
                      label={"Guest Name"}
                      placeholder={"Enter guest name"}
                      value={guestName}
                      onChange={(e) => setGuestName(e.target.value)}
                      error={guestNameError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <DateInput
                      label={"Trip Start Date"}
                      placeholder={"Select trip start date"}
                      value={tripStartDate}
                      setValue={setTripStartDate}
                      error={tripStartDateError}
                      mandate
                    />
                    <DateInput
                      label={"Trip End Date"}
                      placeholder={"Select trip end date"}
                      value={tripEndDate}
                      setValue={setTripEndDate}
                      error={tripEndDateError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <Input
                      label={"Pickup Location"}
                      placeholder={"Enter pickup location"}
                      value={pickupLocation}
                      onChange={(e) => setPickupLocation(e.target.value)}
                      error={pickupLocationError}
                      mandate
                    />
                    <Input
                      label={"Drop Location"}
                      placeholder={"Enter drop location"}
                      value={dropLocation}
                      onChange={(e) => setDropLocation(e.target.value)}
                      error={dropLocationError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <TimeInput
                      label={"Pickup Time"}
                      placeholder={"--:--"}
                      value={pickupTime}
                      setValue={setPickupTime}
                      error={pickupTimeError}
                      mandate
                    />
                    <TimeInput
                      label={"Drop Time"}
                      placeholder={"--:--"}
                      value={dropTime}
                      setValue={setDropTime}
                      error={dropTimeError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <MobileNumberInput
                      label={"Guest Contact Number"}
                      placeholder={"Enter guest contact number"}
                      value={guestContactNumber}
                      setValue={setGuestContactNumber}
                      error={guestContactNumberError}
                      mandate
                    />
                    <Input
                      label={"No Of Days"}
                      placeholder={"Enter no of day(s)"}
                      type={"number"}
                      value={numberOfDays}
                      onChange={(e) => setNumberOfDays(e.target.value)}
                      error={numberOfDaysError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <Input
                      label={"Vehicle Name"}
                      placeholder={"Enter vehicle name"}
                      value={vehicleName}
                      onChange={(e) => setVehicleName(e.target.value)}
                      error={vehicleNameError}
                      mandate
                    />
                    <DropDown
                      label={"Vehicle Type"}
                      placeholder={"Select vehicle type"}
                      displayValue={"name"}
                      data={vehicleTypeList}
                      value={vehicleType}
                      setValue={setVehicleType}
                      error={vehicleTypeError}
                      mandate
                    />
                  </div>
                  <div className="flex w-full gap-5 items-center flex-wrap lg:flex-nowrap">
                    <Input
                      label={"No of Guest"}
                      placeholder={"Enter no of guest(s)"}
                      type={"number"}
                      value={numberOfGuests}
                      onChange={(e) => setNumberOfGuests(e.target.value)}
                      error={numberOfGuestsError}
                      mandate
                    />
                    <div className="invisible w-full"></div>
                  </div>
                  <div className="flex justify-center w-full mt-4">
                    <Button
                      text={"Create Trip"}
                      onClick={createOutstationData}
                      width="170px"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Modal>
      </PageLayout>
    </>
  );
}
