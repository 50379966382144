import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import PageLayout from "../../components/layouts/PageLayout";
import DriverTable from "../../components/tables/driver-management/DriverTable";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Upload from "../../components/reusable/Upload";
import edit from "../../assets/edit_pencil.svg";
import profile from "../../assets/profile_avatar.svg";
import DateInput from "../../components/reusable/DateInput";
import Common from "../../services/api/commonApi";
import Driver from "../../services/api/driverApi";
import axios from "axios";

export default function DriverManagement() {

  const [addDriverModal, setAddDriverModal] = useState(false);
  const [addDriverModal2, setAddDriverModal2] = useState(false);
  const [addDriverModal3, setAddDriverModal3] = useState(false);
  const [isPermanentAddressSame, setIsPermanentAddressSame] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [shiftTime, setShiftTime] = useState("");
  const profileImageRef = useRef();
  const [driverName, setDriverName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypesList, setVehicleTypesList] = useState([]);
  const [driverStatus, setDriverStatus] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [tempStreetAddress, setTempStreetAddress] = useState("");
  const [tempHouseNumber, setTempHouseNumber] = useState("");
  const [tempState, setTempState] = useState("");
  const [tempStateList, setTempStateList] = useState([]);
  const [tempDistrictList, setTempDistrictList] = useState([]);
  const [permDistrictList, setPermDistrictList] = useState([]);
  const [tempDistrict, setTempDistrict] = useState("");
  const [tempPincode, setTempPincode] = useState("");

  const [permStreetAddress, setPermStreetAddress] = useState("");
  const [permHouseNumber, setPermHouseNumber] = useState("");
  const [permState, setPermState] = useState("");
  const [permDistrict, setPermDistrict] = useState("");
  const [permPincode, setPermPincode] = useState("");

  const [upiId, setUpiId] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [licenseExpDate, setLicenseExpDate] = useState("");
  const [permitExpDate, setPermitExpDate] = useState("");
  const [insuranceExpDate, setInsuranceExpDate] = useState("");
  const [taxExpDate, setTaxExpDate] = useState("");
  const [aadharFile, setAadharFile] = useState("");
  const [drivingLicenseFile, setDrivingLicenseFile] = useState("");
  const [pollutionCertificateFile, setPollutionCertificateFile] = useState("");
  const [covidVaccineCertificateFile, setCovidVaccineCertificateFile] =
    useState("");
  const [policeCertificateFile, setPoliceCertificateFile] = useState("");
  const [fcFile, setFcFile] = useState("");
  const [rcFile, setRcFile] = useState("");
  const [cvFile, setCvFile] = useState("");
  const [pcFile, setPcFile] = useState("");
  const [dlFile, setDlFile] = useState("");

  const [driverIdError, setDriverIdError] = useState("");
  const [driverNameError, setDriverNameError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [vehicleNameError, setVehicleNameError] = useState("");
  const [vehicleNumberError, setVehicleNumberError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [bloodGroupError, setBloodGroupError] = useState("");
  const [driverStatusError, setDriverStatusError] = useState("");

  const [tempStreetAddressError, setTempStreetAddressError] = useState("");
  const [tempHouseNumberError, setTempHouseNumberError] = useState("");
  const [tempStateError, setTempStateError] = useState("");
  const [tempDistrictError, setTempDistrictError] = useState("");
  const [tempPincodeError, setTempPincodeError] = useState("");
  const [permStreetAddressError, setPermStreetAddressError] = useState("");
  const [permHouseNumberError, setPermHouseNumberError] = useState("");
  const [permStateError, setPermStateError] = useState("");
  const [permDistrictError, setPermDistrictError] = useState("");
  const [permPincodeError, setPermPincodeError] = useState("");

  const [upiIdError, setUpiIdError] = useState("");
  const [aadharNumberError, setAadharNumberError] = useState("");
  const [drivingLicenseError, setDrivingLicenseError] = useState("");
  const [shiftTimeError, setShiftTimeError] = useState("");
  const [permitExpDateError, setPermitExpDateError] = useState("");
  const [insuranceExpDateError, setInsuranceExpDateError] = useState("");
  const [taxExpDateError, setTaxExpDateError] = useState("");
  const [aadharFileError, setAadharFileError] = useState("");
  const [dlFileError, setDlFileError] = useState("");
  const [policeCertificateFileError, setPoliceCertificateFileError] = useState("");
  const [covidVaccineCertificateFileError, setCovidVaccineCertificateFileError] = useState("");
  const [fcFileError, setFcFileError] = useState("");
  const [rcFileError, setRcFileError] = useState("");

  const [file, setFile] = useState(null);
  const [AadharfileName, setAadharFileName] = useState("");
  const [drivingLicenseFileName, setDrivingLicenseFileName] = useState("");
  const [pollutionCertificateFileName, setPollutionCertificateFileName] = useState("");
  const [covidVaccineCertificateFileName, setCovidVaccineCertificateFileName] = useState("");
  const [policeCertificateFileName, setPoliceCertificateFileName] = useState("");
  const [fcFileName, setFcFileName] = useState("");
  const [rcFileName, setRcFileName] = useState("");
  const [cvFileName, setCvFileName] = useState("");
  const [pcFileName, setPcFileName] = useState("");
  const [dlFileName, setDlFileName] = useState("");

  const [adhaarUrls, setAdhaarUrls] = useState([]);
  const [dlUrls, setDlUrls] = useState([]);
  const [pcUrls, setPcUrls] = useState([]);
  const [cvUrls, setCvUrls] = useState([]);
  const [policeCUrls, setPoliceCUrls] = useState([]);
  const [fcUrls, setFcUrls] = useState([]);
  const [rcUrls, setRcUrls] = useState([]);

  const [createdBy, setCreatedBy] = useState("");

  useEffect(() => {
    setCreatedBy(localStorage.getItem("userIdFleetrax"));
  }, []);

  const handleProfileImage = (event) => {
    if (event) {
      setProfileImage(event.target.files[0]);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const response = await Common.vehicleType();
        setVehicleTypesList(response);
      } catch (err) {
        console.log(err);
      }
    };

    fetchVehicleTypes();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await Common.state();
        setTempStateList(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const id = tempState.id;
    const fetchDistricts = async () => {
      try {
        const response = await Common.district(id);
        setTempDistrictList(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDistricts();
  }, [tempState]);

  useEffect(() => {
    const id = permState.id;
    const fetchDistricts = async () => {
      try {
        const response = await Common.district(id);
        setPermDistrictList(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDistricts();
  }, [permState]);

  const driverStatusList = [
    { name: "Dedicated", id: "Dedicated" },
    { name: "Part Time", id: "Part Time" },
  ];

  const uploadAdhaar = async (files) => {
    const formData = new FormData();

    formData.append("images", file);

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setAdhaarUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadDL = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setDlUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadPollutionCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setPcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadCovidVaccineCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setCvUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadPoliceCertificate = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setPoliceCUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadFc = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setFcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const uploadRc = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(
        window.api + "driver/uploadDocument",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status && response.data.data) {
        setRcUrls(response.data.data);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const validate1 = () => {
    let isValid = true;

    // Validation for Driver Name
    if (!driverName || driverName.trim() === "") {
      setDriverNameError("Enter Driver Name");
      isValid = false;
    } else {
      setDriverNameError("");
    }

    // Validation for Mobile Number
    if (!mobileNumber) {
      setMobileNumberError("Enter Mobile Number");
      isValid = false;
    } else if (mobileNumber.length !== 10) {
      message.error("Mobile Number must be at 10 digits");
      isValid = false;
    } else {
      setMobileNumberError("");
    }

    // Validation for Vehicle Name
    if (!vehicleName || vehicleName.trim() === "") {
      setVehicleNameError("Enter Vehicle Name");
      isValid = false;
    } else {
      setVehicleNameError("");
    }

    // Validation for Vehicle Number
    if (!vehicleNumber || vehicleNumber.trim() === "") {
      setVehicleNumberError("Enter Vehicle Number");
      isValid = false;
    } else {
      setVehicleNumberError("");
    }

    // Validation for Vehicle Type
    if (!vehicleType) {
      setVehicleTypeError("Select Vehicle Type");
      isValid = false;
    } else {
      setVehicleTypeError("");
    }

    return isValid;
  };

  const handlePostData = async () => {
    if (validate1()) {
      const formData = {
        driverName: driverName,
        mobileNumber: mobileNumber,
        vehicleName: vehicleName,
        vehicleNumber: vehicleNumber,
        vehicleTypeID: parseInt(vehicleType.id),
        driverStatus: driverStatus?.id,
        bloodGroup: bloodGroup?.toUpperCase(),
        tempStreetAddress: tempStreetAddress,
        tempHouseNo: tempHouseNumber,
        selectedTemState: tempState?.id,
        selectedTemDistrict: tempDistrict?.id,
        tempPincode: tempPincode,
        isAddressPermanent: isPermanentAddressSame,
        perStreetAddress: permStreetAddress,
        perHouseNo: permHouseNumber,
        selectedPerState: permState?.id,
        selectedPerDistrict: permDistrict?.id,
        perPincode: permPincode,
        upiId: upiId,
        aadharNum: aadharNumber,
        licenseNumber: drivingLicense,
        licExpDate: licenseExpDate,
        permitExpDate: permitExpDate,
        insExpDate: insuranceExpDate,
        taxExpDate: taxExpDate,
        adhaarUrls: adhaarUrls,
        dlUrls: dlUrls,
        pcUrls: pcUrls,
        cvUrls: cvUrls,
        policeCUrls: policeCUrls,
        fcUrls: fcUrls,
        rcUrls: rcUrls,
        createdBy: parseInt(createdBy)
      };

      try {
        const response = await Driver.addDriver(formData);
        if (response.status) {
          message.success("Driver added successfully");
          setAddDriverModal3(false);
          setDriverName("");
          setMobileNumber("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setBloodGroup("");
          setTempStreetAddress("");
          setTempHouseNumber("");
          setTempState("");
          setTempDistrict("");
          setTempPincode("");
          setIsPermanentAddressSame(false);
          setPermStreetAddress("");
          setPermHouseNumber("");
          setPermState("");
          setPermDistrict("");
          setPermPincode("");
          setUpiId("");
          setAadharNumber("");
          setDrivingLicense("");
          setLicenseExpDate("");
          setPermitExpDate("");
          setInsuranceExpDate("");
          setTaxExpDate("");
          setAdhaarUrls([]);
          setDlUrls([]);
          setPcUrls([]);
          setCvUrls([]);
          setPoliceCUrls([]);
          setFcUrls([]);
          setRcUrls([]);
        } else {
          message.error(response.message);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Please fill all the mandatory fields");
    }
  };

  const AddDriverModalContent = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Create Driver
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <p className="text-base font-medium underline">Personal Details</p>
            {/* Profile Image */}
            <div className="flex flex-col items-center justify-center w-full">
              <div className="relative -top-4">
                {profileImage === undefined ? (
                  <img
                    width={100}
                    src={profile}
                    alt="profile"
                    className="w-32 h-32"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(profileImage)}
                    alt="profile"
                    className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                  />
                )}
                <button
                  className="absolute right-1 top-3"
                  onClick={() => profileImageRef.current.click()}
                >
                  <img src={edit} alt="" />
                  <input
                    type="file"
                    className="hidden"
                    ref={profileImageRef}
                    onChange={handleProfileImage}
                    accept="image/*"
                  // value={profileImage}
                  />
                </button>
              </div>
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Enter Driver Name"}
                placeholder={"Enter driver name"}
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
                mandate={true}
                error={driverNameError}
                width={"290px"}
              />
              <MobileNumberInput
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={mobileNumber}
                setValue={setMobileNumber}
                mandate={true}
                error={mobileNumberError}
                width={"300px"}
              />
              <Input
                label={"Vehicle Name"}
                placeholder={"Enter vehicle name"}
                value={vehicleName}
                onChange={(e) => setVehicleName(e.target.value)}
                mandate={true}
                error={vehicleNameError}
                width={"290px"}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <Input
                label={"Vehicle Number"}
                placeholder={"Enter vehicle number"}
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
                mandate={true}
                error={vehicleNumberError}
                width={"290px"}
              />
              <DropDown
                label={"Vehicle Type"}
                placeholder={"Select vehicle type"}
                displayValue={"name"}
                data={vehicleTypesList}
                value={vehicleType}
                setValue={setVehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                mandate={true}
                error={vehicleTypeError}
              />
              <Input
                label={"Blood Group"}
                placeholder={"Enter blood group"}
                value={bloodGroup?.toUpperCase()}
                onChange={(e) => setBloodGroup(e.target.value)}
                width={"290px"}
                error={bloodGroupError}
              />
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap items-center gap-3 lg:gap-10">
              <DropDown
                label={"Driver Status"}
                placeholder={"Select driver status"}
                displayValue={"name"}
                data={driverStatusList}
                value={driverStatus}
                setValue={setDriverStatus}
                onChange={(e) => setDriverStatus(e.target.value)}
                width={"290px"}
                error={driverStatusError}
              />
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Next"}
              width={"170px"}
              onClick={() => {
                if (validate1()) {
                  setAddDriverModal(false);
                  setAddDriverModal2(true);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const AddDriverModalContent2 = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Create Driver
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <p className="text-base font-medium underline">
              Temporary Address{" "}
            </p>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Street Address"}
                placeholder={"Enter street address"}
                value={tempStreetAddress}
                onChange={(e) => setTempStreetAddress(e.target.value)}
                error={tempStreetAddressError}
              />
              <Input
                label={"House Number"}
                placeholder={"Enter house number"}
                value={tempHouseNumber}
                onChange={(e) => setTempHouseNumber(e.target.value)}
                error={tempHouseNumberError}
              />
              <DropDown
                label={"State"}
                placeholder={"Select state"}
                displayValue={"stateName"}
                data={tempStateList}
                value={tempState}
                setValue={setTempState}
                onChange={(e) => setTempState(e.target.value)}
                error={tempStateError}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"District"}
                placeholder={"Select district"}
                displayValue={"districtName"}
                data={tempDistrictList}
                value={tempDistrict}
                setValue={setTempDistrict}
                onChange={(e) => setTempDistrict(e.target.value)}
                error={tempDistrictError}
              />

              <Input
                label={"Pincode"}
                type="number"
                placeholder={"Enter pincode"}
                value={tempPincode}
                onChange={(e) => setTempPincode(e.target.value)}
                error={tempPincodeError}
              />
              <div className=" w-full "></div>
            </div>
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 pb-10">
            <div>
              <p className="text-base font-medium underline">
                Permanent Address{" "}
              </p>
              <div className="flex items-center pt-4">
                <input
                  type="checkbox"
                  id="permanentAddressSame"
                  name="permanentAddressSame"
                  checked={isPermanentAddressSame}
                  onChange={(e) => setIsPermanentAddressSame(e.target.checked)}
                />
                <label
                  htmlFor="permanentAddressSame"
                  className="ml-2 font-medium text-base"
                >
                  Is Permanent Address same as Temporary Address?
                </label>
              </div>
            </div>
            {!isPermanentAddressSame && (
              <>
                <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
                  <Input
                    label={"Street Address"}
                    placeholder={"Enter street address"}
                    value={permStreetAddress}
                    onChange={(e) => setPermStreetAddress(e.target.value)}
                    error={permStreetAddressError}
                  />
                  <Input
                    label={"House Number"}
                    placeholder={"Enter house number"}
                    value={permHouseNumber}
                    onChange={(e) => setPermHouseNumber(e.target.value)}
                    error={permHouseNumberError}
                  />
                  <DropDown
                    label={"State"}
                    placeholder={"Select state"}
                    displayValue={"stateName"}
                    data={tempStateList}
                    value={permState}
                    setValue={setPermState}
                    onChange={(e) => setPermState(e.target.value)}
                    error={permStateError}
                  />
                </div>
                <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
                  <DropDown
                    label={"District"}
                    placeholder={"Select district"}
                    displayValue={"districtName"}
                    data={permDistrictList}
                    value={permDistrict}
                    setValue={setPermDistrict}
                    onChange={(e) => setPermDistrict(e.target.value)}
                    error={permDistrictError}
                  />
                  <Input
                    label={"Pincode"}
                    placeholder={"Enter pincode"}
                    type="number"
                    value={permPincode}
                    onChange={(e) => setPermPincode(e.target.value)}
                    error={permPincodeError}
                  />
                  <div className=" w-full "></div>
                </div>
              </>
            )}
          </div>

          <div className=" w-full flex gap-6 justify-center items-center">
            <Button
              text={"Back"}
              width={"140px"}
              onClick={() => {
                setAddDriverModal2(false);
                setAddDriverModal(true);
              }}
            />
            <Button
              text={"Next"}
              width={"140px"}
              onClick={() => {
                setAddDriverModal2(false);
                setAddDriverModal3(true);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const AddDriverModalContent3 = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Create Driver
          </div>
          <div className=" w-full px-1 flex justify-center items-center ">
            <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md py-6">
              <p className="text-base font-medium underline">
                Document Details
              </p>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <Input
                  label={"UPI ID"}
                  placeholder={"Enter UPI ID"}
                  value={upiId}
                  onChange={(e) => setUpiId(e.target.value)}
                />
                <Input
                  label={"Aadhar Number"}
                  placeholder={"Enter aadhar number"}
                  value={aadharNumber}
                  onChange={(e) => setAadharNumber(e.target.value)}
                />
                <Upload
                  label={"Aadhar Card"}
                  selectedFile={aadharFile}
                  setSelectedFile={() => {
                    setAadharFile(aadharFile);
                    uploadAdhaar(aadharFile);
                  }}
                  inputValue={AadharfileName}
                  setInputValue={setAadharFileName}
                  width={"300px"}
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <Input
                  label={"Driving License"}
                  placeholder={"Enter driving license"}
                  value={drivingLicense}
                  onChange={(e) => setDrivingLicense(e.target.value)}
                />
                <Upload
                  label={"Driving License"}
                  selectedFile={dlFile}
                  setSelectedFile={() => {
                    setDlFile(dlFile);
                    uploadDL(dlFile);
                  }}
                  inputValue={dlFileName}
                  setInputValue={setDlFileName}
                  width={"300px"}
                />
                <DateInput
                  label={"License Batch Exp"}
                  placeholder={"Select license batch exp"}
                  value={licenseExpDate}
                  setValue={setLicenseExpDate}
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <DateInput
                  label={"Permit Exp Date"}
                  placeholder={"Select permit exp"}
                  value={permitExpDate}
                  setValue={setPermitExpDate}
                />
                <DateInput
                  label={"Insurance Exp Date"}
                  placeholder={"Select insurance exp"}
                  value={insuranceExpDate}
                  setValue={setInsuranceExpDate}
                />
                <DateInput
                  label={"Tax Exp Date"}
                  placeholder={"Select tax exp"}
                  value={taxExpDate}
                  setValue={setTaxExpDate}
                />
              </div>
              <p className="text-base font-medium underline">Proof Upload</p>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3">
                <Upload
                  label={"Pollution Certificate"}
                  selectedFile={policeCertificateFile}
                  setSelectedFile={() => {
                    setPoliceCertificateFile(policeCertificateFile);
                    uploadPoliceCertificate(policeCertificateFile);
                  }}
                  inputValue={policeCertificateFileName}
                  setInputValue={setPoliceCertificateFileName}
                  width={"300px"}
                />
                <Upload
                  label={"Covid Vaccine Certificate"}
                  selectedFile={covidVaccineCertificateFile}
                  setSelectedFile={() => {
                    setCovidVaccineCertificateFile(covidVaccineCertificateFile);
                    uploadCovidVaccineCertificate(covidVaccineCertificateFile);
                  }}
                  inputValue={covidVaccineCertificateFileName}
                  setInputValue={setCovidVaccineCertificateFileName}
                  width={"300px"}
                />
                <Upload
                  label={"Police Certificate"}
                  selectedFile={policeCertificateFile}
                  setSelectedFile={() => {
                    setPoliceCertificateFile(policeCertificateFile);
                    uploadPoliceCertificate(policeCertificateFile);
                  }}
                  inputValue={policeCertificateFileName}
                  setInputValue={setPoliceCertificateFileName}
                  width={"300px"}
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3">
                <Upload
                  label={"FC Copy"}
                  selectedFile={fcFile}
                  setSelectedFile={() => {
                    setFcFile(fcFile);
                    uploadFc(fcFile);
                  }}
                  inputValue={fcFileName}
                  setInputValue={setFcFileName}
                  width={"300px"}
                />
                <Upload
                  label={"RC Copy"}
                  selectedFile={rcFile}
                  setSelectedFile={() => {
                    setRcFile(rcFile);
                    uploadRc(rcFile);
                  }}
                  inputValue={rcFileName}
                  setInputValue={setRcFileName}
                  width={"300px"}
                />
                <div className="w-[300px]" />
              </div>
            </div>
          </div>
          <div className=" w-full flex gap-6 justify-center items-center pt-8">
            <Button
              text={"Back"}
              width={"170px"}
              onClick={() => {
                setAddDriverModal3(false);
                setAddDriverModal2(true);
              }}
            />
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                handlePostData();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout
        title={"Driver Management"}
        description={"View your driver details here."}
        buttonTitle={"Add Driver"}
        buttonWidth={"fit-content"}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={setAddDriverModal}
      >
        <div className=" w-full flex mt-7 pr-4">
          <DriverTable />
        </div>
      </PageLayout>
      {/* first modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal}
        width={"1000px"}
        onCancel={() => {
          setAddDriverModal(false);

          setDriverName("");
          setMobileNumber("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setBloodGroup("");

          setDriverNameError("");
          setMobileNumberError("");
          setVehicleNameError("");
          setVehicleNumberError("");
          setVehicleTypeError("");
        }}
        footer={null}
        centered
      >
        {AddDriverModalContent()}
      </Modal>
      {/* second modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal2}
        width={"1000px"}
        onCancel={() => {
          setAddDriverModal2(false);

          setDriverName("");
          setMobileNumber("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setBloodGroup("");

          setTempStreetAddress("");
          setTempHouseNumber("");
          setTempState("");
          setTempDistrict("");
          setTempPincode("");
          setIsPermanentAddressSame(false);
          setPermStreetAddress("");
          setPermHouseNumber("");
          setPermState("");
          setPermDistrict("");
          setPermPincode("");
        }}
        footer={null}
        centered
        className="my-6"
      >
        {AddDriverModalContent2()}
      </Modal>
      {/* third modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal3}
        width={"1000px"}
        onCancel={() => {
          setAddDriverModal3(false);


          setDriverName("");
          setMobileNumber("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setBloodGroup("");

          setTempStreetAddress("");
          setTempHouseNumber("");
          setTempState("");
          setTempDistrict("");
          setTempPincode("");
          setIsPermanentAddressSame(false);
          setPermStreetAddress("");
          setPermHouseNumber("");
          setPermState("");
          setPermDistrict("");
          setPermPincode("");


          setUpiId("");
          setAadharNumber("");
          setDrivingLicense("");
          setLicenseExpDate("");
          setPermitExpDate("");
          setInsuranceExpDate("");
          setTaxExpDate("");
          setAdhaarUrls([]);
          setDlUrls([]);
          setPcUrls([]);
          setCvUrls([]);
          setPoliceCUrls([]);
          setFcUrls([]);
          setRcUrls([]);
        }}
        footer={null}
        centered
        className="my-6"
      >
        {AddDriverModalContent3()}
      </Modal>
    </>
  );
}
