export default function StatusCard({ status }) {
  let labelColorClass = "";
  let labelText = "";
  let labelBgColorClass = "";
  let DotBgColorClass = "";

  switch (status) {
    case 0:
      labelColorClass = "text-[#B42318]";
      labelText = "Not Alloted";
      labelBgColorClass = "bg-[#FEF3F2]";
      DotBgColorClass = "bg-[#F04438]";
      break;
    case 1:
      labelColorClass = "text-[#1849A9]";
      labelText = "Alloted";
      labelBgColorClass = "bg-[#D7E6FF]";
      DotBgColorClass = "bg-[#3A74D8]";
      break;
    case 3:
      labelColorClass = "text-[#027A48]";
      labelText = "Completed";
      labelBgColorClass = "bg-[#ECFDF3]";
      DotBgColorClass = "bg-[#12B76A]";
      break;
    case "notyetbegin":
      labelColorClass = "text-[#B54708]";
      labelText = "Not yet begin";
      labelBgColorClass = "bg-[#FFFAEB]";
      DotBgColorClass = "bg-[#F79009]";
      break;
    case "cancelled":
      labelColorClass = "text-[#B42318]";
      labelText = "Cancelled";
      labelBgColorClass = "bg-[#FEF3F2]";
      DotBgColorClass = "bg-[#F04438]";
      break;
    case 2:
      labelColorClass = "text-[#1849A9]";
      labelText = "Ongoing";
      labelBgColorClass = "bg-[#D7E6FF]";
      DotBgColorClass = "bg-[#3A74D8]";
      break;
    case "active":
      labelColorClass = "text-[#027A48]";
      labelText = "Active";
      labelBgColorClass = "bg-[#ECFDF3]";
      DotBgColorClass = "bg-[#12B76A]";
      break;
    case "inactive":
      labelColorClass = "text-[#B42318]";
      labelText = "Inactive";
      labelBgColorClass = "bg-[#FEF3F2]";
      DotBgColorClass = "bg-[#F04438]";
      break;
    case "Accept":
      labelColorClass = "text-[#027A48]";
      labelText = "Accepted";
      labelBgColorClass = "bg-[#ECFDF3]";
      DotBgColorClass = "bg-[#12B76A]";
      break;
    case "Pending":
      labelColorClass = "text-[#B54708]";
      labelText = "Pending";
      labelBgColorClass = "bg-[#FFFAEB]";
      DotBgColorClass = "bg-[#F79009]";
      break;
    case "Reject":
      labelColorClass = "text-[#B42318]";
      labelText = "Rejected";
      labelBgColorClass = "bg-[#FEF3F2]";
      DotBgColorClass = "bg-[#F04438]";
      break;
    case "app":
      labelColorClass = "text-[#027A48]";
      labelText = "App Assign";
      labelBgColorClass = "bg-[#ECFDF3]";
      DotBgColorClass = "bg-[#12B76A]";
      break;
    case "wat":
      labelColorClass = "text-[#B54708]";
      labelText = "Wat Assign";
      labelBgColorClass = "bg-[#FFFAEB]";
      DotBgColorClass = "bg-[#F79009]";
      break;
    case "force":
      labelColorClass = "text-[#B42318]";
      labelText = "Force Assign";
      labelBgColorClass = "bg-[#FEF3F2]";
      DotBgColorClass = "bg-[#F04438]";
      break;
    case "notallocated":
      labelColorClass = "";
      labelText = "-";
      labelBgColorClass = "";
      DotBgColorClass = "";
      break;
    default:
      labelColorClass = "";
      labelText = "";
      labelBgColorClass = "";
      DotBgColorClass = "";
      break;
  }
  return (
    <div
      className={`w-fit py-1 px-3 flex items-center justify-center rounded-full ${labelBgColorClass}`}
    >
      <div
        className={`w-[6px] h-[6px] p-1 rounded-full ${DotBgColorClass}`}
      ></div>
      <p className={`${labelColorClass} pl-[6px] pr-[2px] text-xs font-medium`}>
        {labelText}
      </p>
    </div>
  );
}
