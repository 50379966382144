import { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import { useLocation, Outlet } from "react-router-dom";

export default function NavbarLayout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
    useEffect(() => {
        const isMobileScreen = window.innerWidth <= 768;
        if (isMobileScreen) {
        setIsOpen(false);
        }
    }, [location]);

  return (
    <div className=" w-full">
      <Outlet />
    </div>
  );
}
