import { BarChart } from "@mui/x-charts/BarChart";
import filter from "../../../assets/filter.svg";
import { useState } from "react";
import FilterLayout from "../../../components/layouts/FilterLayout";
import { message } from "antd";
import MultiSelectFilter from "../../../components/reusable/MultiSelectFilterForCheckbox";
import FilterDates from "../../../components/reusable/FilterDates";

export default function EmployeeSummary({ totalData, allottedData, xLabels, companyList, shiftTimeList, TripTypeList, vehicleTypeList, filters, setFilters, userCompany }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Function to update a specific filter
  const updateFilter = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleClearFilter = () => {
    message.success("cleared filters of Employee Summary");
    setFilters({
      company: [],
      vehicleType: [],
      shiftTime: [],
      tripType: [],
    })
    setIsFilterOpen(!isFilterOpen);
  };

  // Replace these arrays with your actual data
  const companyOptions = companyList?.map(item => item);
  const shiftTimeOptions = shiftTimeList?.map(item => item);
  const tripTypeOptions = TripTypeList?.map(item => item);
  const vehicleTypeOptions = vehicleTypeList?.map(item => item);

  const filterContent = [
    {
      title: "Company",
      body: (
        <div className=" w-full flex gap-3 items-center   ">
          <div>
            <MultiSelectFilter
              label="Company"
              options={companyOptions}
              selectedOptions={filters.company}
              updateSelectedOptions={(selected) =>
                updateFilter("company", selected)
              }
              displayValue="name"
              returnValue="id"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Shift Time",
      body: (
        <div>
          <MultiSelectFilter
            label="Shift Time"
            options={shiftTimeOptions}
            selectedOptions={filters.shiftTime}
            updateSelectedOptions={(selected) =>
              updateFilter("shiftTime", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Trip Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Trip Type"
            options={tripTypeOptions}
            selectedOptions={filters.tripType}
            updateSelectedOptions={(selected) =>
              updateFilter("tripType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Vehicle Type"
            options={vehicleTypeOptions}
            selectedOptions={filters.vehicleType}
            updateSelectedOptions={(selected) =>
              updateFilter("vehicleType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
          {/* Date inputs go here */}
        </div>
      ),
    },
  ];

  const filterContentForLapiz = [
    {
      title: "Shift Time",
      body: (
        <div>
          <MultiSelectFilter
            label="Shift Time"
            options={shiftTimeOptions}
            selectedOptions={filters.shiftTime}
            updateSelectedOptions={(selected) =>
              updateFilter("shiftTime", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Trip Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Trip Type"
            options={tripTypeOptions}
            selectedOptions={filters.tripType}
            updateSelectedOptions={(selected) =>
              updateFilter("tripType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Vehicle Type"
            options={vehicleTypeOptions}
            selectedOptions={filters.vehicleType}
            updateSelectedOptions={(selected) =>
              updateFilter("vehicleType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
          {/* Date inputs go here */}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="shadow-lg p-4 w-fit  hidden lg:flex flex-col flex-auto xl:max-w-[800px] border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <p className="text-lg font-semibold w-fit">Employee Summary</p>
        <BarChart
          width={700}
          height={300}
          series={[
            {
              data: totalData,
              label: "Total Employees",
              id: "totalData",
              color: "#52D3D8",
            },
            {
              data: allottedData,
              label: "Allotted Employees",
              id: "allottedData",
              color: "#009EFF",
            },
          ]}
          xAxis={[{ data: xLabels, scaleType: "band" }]}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <div className="flex gap-10 justify-center items-start font-semibold">
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
            <p>Total</p>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
            <p>Alotted</p>
          </div>
        </div>
      </div>
      <div className="shadow-lg p-4 w-fit  flex lg:hidden flex-col border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <p className="text-lg font-semibold w-fit">Employee Summary</p>
        <BarChart
          width={300}
          height={300}
          series={[
            {
              data: totalData,
              label: "Total Employees",
              id: "totalData",
              color: "#52D3D8",
            },
            {
              data: allottedData,
              label: "Allotted Employees",
              id: "allottedData",
              color: "#009EFF",
            },
          ]}
          xAxis={[{ data: xLabels, scaleType: "band" }]}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <div className="flex gap-10 justify-center items-start font-semibold">
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
            <p>Total</p>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
            <p>Alotted</p>
          </div>
        </div>
      </div>
    </>
  );
}
