import axios from "axios";

const Employee = {
  addEmployee: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/verify/createEmployee`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  findOneEmployee: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}employee/verify/findOneEmployee/${params.id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.employee; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  findAllEmplyee: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}employee/verify/findAllEmployee`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  updateEmployee: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/verify/updateEmployee`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  deleteEmployee: async (id, deletedBy) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}employee/verify/deleteEmployee/${id}`,
        { deletedBy },
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Employee;
