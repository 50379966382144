import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import filter from "../../../assets/filter.svg";
import { useState } from 'react';
import FilterLayout from '../../../components/layouts/FilterLayout';
import { message } from 'antd';
import MultiSelectFilter from '../../../components/reusable/MultiSelectFilterForCheckbox';
import FilterDates from '../../../components/reusable/FilterDates';

export default function EmployeeAttendence({ data, companyList, shiftTimeList, TripTypeList, vehicleTypeList, filters, setFilters, filterDate, setFilterDate, userCompany }) {

  const [isFilterOpen, setIsFilterOpen] = useState(false);


  // Function to update a specific filter
  const updateFilter = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleClearFilter = () => {
    message.success("cleared filters of Employee Attendence");
    setFilters({
      company: [],
      vehicleType: [],
      shiftTime: [],
      tripType: [],
    })
    setFilterDate({
      firstDate: "",
      secondDate: "",
    });
    setIsFilterOpen(!isFilterOpen);
  };

  // Replace these arrays with your actual data
  const companyOptions = companyList?.map(item => item);
  const shiftTimeOptions = shiftTimeList?.map(item => item);
  const tripTypeOptions = TripTypeList?.map(item => item);
  const vehicleTypeOptions = vehicleTypeList?.map(item => item);

  const filterContent = [
    {
      title: "Company",
      body: (
        <div className=" w-full flex gap-3 items-center   ">
          <div>
            <MultiSelectFilter
              label="Company"
              options={companyOptions}
              selectedOptions={filters.company}
              updateSelectedOptions={(selected) =>
                updateFilter("company", selected)
              }
              displayValue="name"
              returnValue="id"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Shift Time",
      body: (
        <div>
          <MultiSelectFilter
            label="Shift Time"
            options={shiftTimeOptions}
            selectedOptions={filters.shiftTime}
            updateSelectedOptions={(selected) =>
              updateFilter("shiftTime", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Trip Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Trip Type"
            options={tripTypeOptions}
            selectedOptions={filters.tripType}
            updateSelectedOptions={(selected) =>
              updateFilter("tripType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Vehicle Type"
            options={vehicleTypeOptions}
            selectedOptions={filters.vehicleType}
            updateSelectedOptions={(selected) =>
              updateFilter("vehicleType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
          {/* Date inputs go here */}
        </div>
      ),
    },
    {
      title: "Date",
      body: (
        <div>
          {" "}
          <FilterDates
            firstDateLabel={"Start Date"}
            secondDateLabel={"End Date"}
            date={filterDate}
            setDate={setFilterDate}
            mandate
          />{" "}
        </div>
      ),
    },
  ];

  const filterContentForLapiz = [
    {
      title: "Shift Time",
      body: (
        <div>
          <MultiSelectFilter
            label="Shift Time"
            options={shiftTimeOptions}
            selectedOptions={filters.shiftTime}
            updateSelectedOptions={(selected) =>
              updateFilter("shiftTime", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Trip Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Trip Type"
            options={tripTypeOptions}
            selectedOptions={filters.tripType}
            updateSelectedOptions={(selected) =>
              updateFilter("tripType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      body: (
        <div>
          <MultiSelectFilter
            label="Vehicle Type"
            options={vehicleTypeOptions}
            selectedOptions={filters.vehicleType}
            updateSelectedOptions={(selected) =>
              updateFilter("vehicleType", selected)
            }
            displayValue="name"
            returnValue="id"
          />
          {/* Date inputs go here */}
        </div>
      ),
    },
    {
      title: "Date",
      body: (
        <div>
          {" "}
          <FilterDates
            firstDateLabel={"Start Date"}
            secondDateLabel={"End Date"}
            date={filterDate}
            setDate={setFilterDate}
            mandate
          />{" "}
        </div>
      ),
    },
  ];

  const sizing = {
    margin: { right: 1 },
    width: 250,
    height: 300,
    legend: { hidden: true },
  };
  const sizing2 = {
    margin: { right: 1 },
    width: 250,
    height: 300,
    legend: { hidden: true },
  };

  const getArcLabel = (params) => {
    return `${params.value}`;
  };

  return (
    <>
      <div className='shadow-lg hidden p-4 w-fit lg:flex flex-auto xl:max-w-[600px] pr-6 border rounded-md relative'>
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className='flex flex-col'>
          <p className="text-lg font-semibold pb-2 w-fit">Employee Attendence</p>
          <PieChart
            series={[
              {
                data,
                arcLabel: getArcLabel,
                innerRadius: 43,
                outerRadius: 110,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontSize: 14,
                fontWeight: 'bold',
              },
            }}
            {...sizing}
          />
        </div>
        <div className="flex flex-col gap-3 justify-center items-center mx-auto px-8 font-semibold">
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
            <p>Present</p>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
            <p>Absent</p>
          </div>
        </div>
      </div>
      <div className='shadow-lg p-2 w-fit flex lg:hidden flex-auto pr-6 border rounded-md relative'>
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={userCompany == "lapiz" ? filterContentForLapiz : filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className='flex flex-col'>
          <p className="text-lg font-semibold pb-2 w-fit">Employee Attendence</p>
          <PieChart
            series={[
              {
                data,
                arcLabel: getArcLabel,
                innerRadius: 43,
                outerRadius: 110,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontSize: 14,
                fontWeight: 'bold',
              },
            }}
            {...sizing2}
          />
          <div className="flex flex-col gap-3 justify-center items-center mx-auto pr-8 font-semibold">
            <div className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
              <p>Present</p>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
              <p>Absent</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}