import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Card, Typography, message } from "antd";
import PageLayout from "../../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import DropDown from "../../../components/reusable/DropDown";
import DateInput from "../../../components/reusable/DateInput";
import edit from "../../../assets/editIcon.png";
import Input from "../../../components/reusable/Input";
import Button from "../../../components/reusable/Button";

import Common from "../../../services/api/commonApi";
import Invoice from "../../../services/api/invoiceApi";
import DateUtils from "../../../services/utility/DateUtils";

export default function TripPaymentDetails() {

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramsInvoiceID = params.get("id");

  const [editOn, setEditOn] = useState(false);

  const [invoicePaidAmount, setInvoicePaidAmount] = useState(0);
  const [invoicePaymentDate, setInvoicePaymentDate] = useState(null);
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState(null);
  const [invoicePaymentMode, setInvoicePaymentMode] = useState(null);
  const [invoiceTransactionId, setInvoiceTransactionId] = useState(null);
  const [invoicePaidBy, setInvoicePaidBy] = useState(null);

  const [invoice, setInvoice] = useState("");
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paidByList, setPaidByList] = useState([]);

  useEffect(() => {
    fetchInvoiceData();
    fetchPaymentStatusData();
    fetchPaymentModeData();
    fetchPaidByData();
  }, []);

  const fetchInvoiceData = async () => {
    try {
      const response = await Invoice.getOneInvoice({ id: paramsInvoiceID });
      setInvoice(response);
      setInvoicePaidAmount(response.invoicePaidAmount);
      setInvoicePaymentDate(response.invoicePaidDate);
      setInvoicePaymentStatus(response.invoiceStatus);
      setInvoicePaymentMode(response.invoicePaymentMode);
      setInvoiceTransactionId(response.invoiceTransactionId);
      setInvoicePaidBy(response.invoicePaidBy);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchPaymentStatusData = async () => {
    try {
      const response = await Common.getPaymentStatus();
      setPaymentStatusList(response);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchPaymentModeData = async () => {
    try {
      const response = await Common.getPaymentMode();
      setPaymentModeList(response);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchPaidByData = async () => {
    try {
      const response = await Common.getPayer();
      setPaidByList(response);
    } catch (err) {
      console.log(err);
    }
  };
  const updateInvoiceData = async () => {
    try {
      const data = {
        id: parseInt(paramsInvoiceID),
        invoiceStatus: parseInt(invoicePaymentStatus?.id),
        invoicePaidAmount: parseInt(invoicePaidAmount),
        invoicePaidDate: invoicePaymentDate,
        invoicePaymentMode: parseInt(invoicePaymentMode?.id),
        invoiceTransactionId: parseInt(invoiceTransactionId),
        invoicePaidBy: parseInt(invoicePaidBy?.id),
      }
      const response = await Invoice.updateInvoice(data);
      if (response.data.code === 200) {
        message.success("Invoice updated successfully");
        setInvoicePaidBy("");
        setInvoiceTransactionId("");
        setInvoicePaymentMode("");
        setInvoicePaymentStatus("");
        setInvoicePaymentDate("");
        setInvoicePaidAmount("");
        setEditOn(!editOn);
        fetchInvoiceData();
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Trip Payment Details"}
        description={"View  your Invoices Details here"}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className=" flex justify-between mt-10  w-full items-center gap-8 lg:flex-nowrap  px-2 flex-wrap">
          <Card title={"Trip Details"} className=" w-full shadow-xl">
            <div className=" flex flex-col gap-1">
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Route ID
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.routeId}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Route Name
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.routeName}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  From Location:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.fromLocation}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  To Location:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.dropLocation}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Trip Date
                </div>
                <div className=" w-auto font-normal text-base">
                  : {DateUtils.convertDate(invoice?.tripDate)}
                </div>
              </div>
            </div>
          </Card>
          <Card title="Trip Summary" className=" w-full shadow-xl">
            <div className=" flex flex-col gap-1">
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  No of Pickups:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.noOfPickup}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Vehicle Type:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.vehicleType}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Shift Time
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.shiftTime}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Trip Amount:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.tripAmount}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Trip Type
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.tripType}</div>
              </div>
            </div>
          </Card>
          <Card title="Driver & Vehicle Details" className=" w-full shadow-xl">
            <div className=" flex flex-col gap-1">
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Driver ID
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.driverId}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Driver Name
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.driverName}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Vehicle Number:
                </div>
                <div className=" w-auto font-normal text-base">
                  : {invoice?.vehicleNo}
                </div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Vehicle Type:
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.vehicleType}</div>
              </div>
              <div className=" flex pr-3 items-center">
                <div className=" w-[145px] font-semibold text-base">
                  Vehicle Name
                </div>
                <div className=" w-auto font-normal text-base">: {invoice?.vehicleName}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className=" flex justify-between mt-10  w-full items-center gap-8 lg:flex-nowrap  px-2 flex-wrap">
          <Card className=" shadow-xl w-full">
            <div className=" w-full justify-end items-center flex">
              <button onClick={() => setEditOn(!editOn)} className="">
                <img src={edit} alt="" />
              </button>
            </div>
            <div class="flex flex-wrap  lg:flex-nowrap  pt-5  justify-between items-center w-full">
              <Input
                label="Paid Amount"
                id="paid-amount"
                type="text"
                placeholder="Enter paid amount"
                onChange={(e) => setInvoicePaidAmount(e.target.value)}
                value={invoicePaidAmount}
                disabled={!editOn}
              />
              <DateInput
                label="Payment Date"
                id="payment-date"
                type="date"
                placeholder="Select payment date"
                value={invoicePaymentDate}
                setValue={setInvoicePaymentDate}
                disabled={!editOn}
              />

              <DropDown
                label={"Payment Status"}
                placeholder={"Select Payment Status"}
                data={paymentStatusList}
                value={invoicePaymentStatus}
                setValue={setInvoicePaymentStatus}
                displayValue={"name"}
                disabled={!editOn}
              />
            </div>
            <div class="flex flex-wrap  mt-4 lg:flex-nowrap   justify-between items-center w-full">
              <DropDown
                label={"Payment Mode"}
                placeholder={"Select Payment Status"}
                displayValue={"name"}
                data={paymentModeList}
                value={invoicePaymentMode}
                setValue={setInvoicePaymentMode}
                disabled={!editOn}
              />
              <Input
                label="Transaction ID"
                id="paid-amount"
                type="text"
                placeholder="Enter Transaction ID"
                onChange={(e) => setInvoiceTransactionId(e.target.value)}
                value={invoiceTransactionId}
                disabled={!editOn}
              />

              <DropDown
                label={"Paid By"}
                placeholder={"Select Paid by"}
                displayValue={"payerName"}
                data={paidByList}
                value={invoicePaidBy}
                setValue={setInvoicePaidBy}
                disabled={!editOn}
              />
            </div>
            {
              <div className="flex justify-center mt-10">
                {editOn && (
                  <div className=" flex justify-center items-center gap-5">
                    <Button
                      text="Discard"
                      width={100}
                      bgColor="#ffffff"
                      textColor="#ed1607"
                      borderColor="#ed1607"
                      onClick={() => {
                        setInvoicePaidBy("");
                        setInvoiceTransactionId("");
                        setInvoicePaymentMode("");
                        setInvoicePaymentStatus("");
                        setInvoicePaymentDate("");
                        setInvoicePaidAmount("");
                        fetchInvoiceData();
                        setEditOn(!editOn);
                      }}
                    />

                    <Button
                      text="Save"
                      width={100}
                      onClick={() => { updateInvoiceData(); }}
                    />
                  </div>
                )}
              </div>
            }
          </Card>
        </div>
      </PageLayout>
    </>
  );
}
