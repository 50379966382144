import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>

      <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
      <p className="mb-4">
        Our privacy policy will help you understand what information we collect
        at Fleetrax - Office Rider, how it is used, and the choices you have.
        Fleetrax - Office Rider provides the Office Rider app as a free
        service, and by using it, you agree to the collection and use of
        information as described in this policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, available on our website unless otherwise defined
        in this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold my-2">Information Collection and Use</h2>
      <p className="mb-4">
        For a better experience while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to your name, email address, gender, location, and
        pictures. The information we request will be retained and used as
        described in this privacy policy. The app may also use third-party
        services that collect information for identification purposes.
      </p>

      <h2 className="text-2xl font-semibold my-2">Cookies</h2>
      <p className="mb-4">
        Cookies are files with a small amount of data that is commonly used as
        an anonymous unique identifier. Although our Service does not use
        cookies explicitly, third-party code and libraries may use them to
        improve their services. You have the option to accept or refuse these
        cookies.
      </p>

      <h2 className="text-2xl font-semibold my-2">Location Information</h2>
      <p className="mb-4">
        Some services may use location information transmitted from users'
        mobile phones. We only use this information within the scope necessary
        for the designated service.
      </p>

      <h2 className="text-2xl font-semibold my-2">Device Information</h2>
      <p className="mb-4">
        We collect information from your device in some cases. The information
        will be utilized for providing better service and preventing fraudulent
        acts. Additionally, such information will not include details that
        identify the individual user.
      </p>

      <h2 className="text-2xl font-semibold my-2">Service Providers</h2>
      <p className="mb-4">
        We may employ third-party companies and individuals to facilitate,
        provide, or analyze our Service. These third parties have access to
        your Personal Information solely to perform tasks assigned by us and are
        obligated not to disclose or use the information for any other purpose.
      </p>

      <h2 className="text-2xl font-semibold my-2">Security</h2>
      <p className="mb-4">
        We value your trust in providing us your Personal Information; thus, we
        strive to use commercially acceptable means of protecting it. However,
        no method of transmission over the internet or electronic storage is
        100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-semibold my-2">Children’s Privacy</h2>
      <p className="mb-4">
        This Service does not address anyone under the age of 13. We do not
        knowingly collect personal identifiable information from children under
        13. If such information is discovered, we immediately delete it from
        our servers.
      </p>

      <h2 className="text-2xl font-semibold my-2">Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. Review this page
        periodically for any changes. We will notify you of any changes by
        posting the new Privacy Policy on this page. Changes are effective
        immediately after they are posted.
      </p>

      <h2 className="text-2xl font-semibold my-2">Contact Us</h2>
      <p className="mb-2">
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us.
      </p>
      <p>
        <strong>Contact Information:</strong>
        <br />
        Email: admin@chennaicabs.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
