import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import NotAllotedTrips from "./trips/NotAllotedTripsOutStation";
import AllotedTripsOutstation from "./trips/AllotedTripsOutStation";
import CompletedTripsOutStation from "./trips/CompletedTripsOutstation";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function TripTabNavigation({ companyList, tripTypeList, vehicleTypeList, driverList, outstationList, isNewDataAdded }) {
  const [activeKey, setActiveKey] = useState("notAlloted");

  useEffect(() => {
    const key = sessionStorage.getItem("outstationTripsTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("outstationTripsTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: "notAlloted",
      label: (
        <LabelForTabs isActive={activeKey === "notAlloted"}>
          Not Alloted
        </LabelForTabs>
      ),
      children: <NotAllotedTrips companyList={companyList} tripTypeList={tripTypeList} vehicleTypeList={vehicleTypeList} driverList={driverList} outstationList={outstationList} activeKey={0} activeKeyName={activeKey} isNewDataAdded={isNewDataAdded} />,
    },
    {
      key: "alloted",
      label: (
        <LabelForTabs isActive={activeKey === "alloted"}>Alloted</LabelForTabs>
      ),
      children: <NotAllotedTrips companyList={companyList} tripTypeList={tripTypeList} vehicleTypeList={vehicleTypeList} driverList={driverList} outstationList={outstationList} activeKey={1} activeKeyName={activeKey} isNewDataAdded={isNewDataAdded} />,
    },
    {
      key: "completed",
      label: (
        <LabelForTabs isActive={activeKey === "completed"}>
          Completed
        </LabelForTabs>
      ),
      children: <NotAllotedTrips companyList={companyList} tripTypeList={tripTypeList} vehicleTypeList={vehicleTypeList} driverList={driverList} outstationList={outstationList} activeKey={2} activeKeyName={activeKey} isNewDataAdded={isNewDataAdded} />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default TripTabNavigation;
