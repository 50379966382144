import { useLocation } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import search from "../../assets/search.svg";
import Mandate from "./Mandate";

export default function TextFiled({
    label,
    type,
    placeholder,
    value,
    error,
    onChange,
    defaultValue,
    width,
    mandate,
    disabled,
    readOnly,
    ...other
}) {

    const inputStyle = {
        width: width || "100%",
    };

    const location = useLocation();
    const url = location.pathname;

    return (
        <div
            className={`flex flex-col min-w-[200px]  lg:min-w-[630px]  w-full ${url === "/" || url === "/forgot-password" || url === "/reset-password" ? " w-full" : " max-w-[300px] "}`}
            style={{
                width: inputStyle.width
            }}
        >
            <div className="h-fit">
                <label
                    className="text-[#344054] text-[15px] pl-1 font-medium"
                    htmlFor={label}
                >
                    {label}<Mandate mandate={mandate} />
                </label>
            </div>
            <>
                {type === "search" ? (
                    <>
                        <div
                            className={`flex items-center border ${disabled && !readOnly && "bg-[#eee]"} ${error ? "border-[#F04438]" : "border-border-gray"} rounded-lg w-full h-[40px] p-1 bg-white`}
                        >
                            <img src={search} alt="search" className="px-1" />
                            <textarea
                                type="text"
                                name={label}
                                id={label}
                                className={`w-full   px-1 h-24 border-none outline-none placeholder-placeholder-gray ${value === "" ? "text-sm" : "text-base"}`}
                                style={{
                                    width: " 100%"
                                }}
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                disabled={disabled || readOnly}
                                {...other}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <textarea
                            type={type}
                            name={label}
                            id={label}
                            className={`${disabled && !readOnly && "bg-[#eee]"} ${readOnly && "pointer-events-none  disabled:bg-white"} pt-2 border ${!value && error ? "border-[#F04438]" : "border-border-gray"} ${value === "" ? "text-sm" : "text-base"} text-text-black placeholder-placeholder-gray outline-none rounded-lg pb-[1px] pl-2 focus:border-[#1849A9]`}
                            style={{
                                width: " 100%"
                            }}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            {...other}
                        />
                        <div>
                            {!value && error && (
                                <ErrorMessage>
                                    {error}
                                </ErrorMessage>
                            )}
                        </div>
                    </>
                )}
            </>
        </div>
    );
};
