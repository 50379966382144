import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import TableCell from "../../reusable/Table/TableCell";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import eye from "./assets/eye.6bf84ddf18c60bb31dcf copy.png";
import arrow from "./assets/download (2).png";
import Invoice from "../../../services/api/invoiceApi";
import Remittance from "../../../services/api/remittanceApi";
import DateUtils from "../../../services/utility/DateUtils";
import { message } from "antd";
import Input from "../../reusable/Input";
import Button from "../../reusable/Button";

export default function NotPaidInvoiceTable({
  type,
  activeKey,
  driverId,
  shiftTime,
  fromDate,
  toDate,
  company,
  setTripCount,
}) {
  const [tripList, setTripList] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredTripList, setFilteredTripList] = useState([]);

  const [selectedIds, setSelectedIds] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchTripData();
    fetchTripCount();
  }, [activeKey, driverId, shiftTime, fromDate, toDate, company]);

  const getInvoiceStatusName = (id) => {
    if (id == 1) {
      return "Not Paid";
    }
    else if (id == 2) {
      return "On Hold";
    }
    else if (id == 3) {
      return "Paid";
    }
    return "-";
  };

  const fetchTripData = async () => {
    try {
      const params = {
        invoiceStatus: activeKey,
        driverId: driverId.id ? parseInt(driverId.id) : null,
        shiftTime: shiftTime.id ? parseInt(shiftTime.id) : null,
        fromDate: fromDate,
        toDate: toDate,
        company: company.id ? parseInt(company.id) : null,
      };
      const data = await Invoice.invoiceTrips(params);
      setSearch("");
      setTripList(data);
      setFilteredTripList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTripCount = async () => {
    try {
      const params = {
        invoiceStatus: activeKey,
        driverId: driverId.id ? parseInt(driverId.id) : null,
        shiftTime: shiftTime.id ? parseInt(shiftTime.id) : null,
        fromDate: fromDate,
        toDate: toDate,
        company: company.id ? parseInt(company.id) : null,
      };
      const data = await Invoice.invoiceTripCount(params);
      setTripCount(data);
      console.log("data--------------------", data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const HandlePushRemittance = async (id) => {
    try {
      const data = {
        tripId: parseInt(id),
        status: 4,
        invoicePaidBy: 1,
      };
      const response = await Remittance.pushOne(data);
      if (response.status) {
        message.success("Remittance pushed successfully");
        fetchTripData();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handlePushRemittanceMultiple = async () => {
    try {
      selectedIds.map(async (item) => {
        try {
          const data = {
            tripId: parseInt(item?.id),
            status: 4,
            invoicePaidBy: 1,
          };
          const response = await Remittance.pushOne(data);
          // console.log(response);
        } catch (err) {
          message.error("Error occurred, please try again");
          console.log(err);
        }
      });
      message.success("Remittance pushed successfully");
    } catch (err) {
      console.log(err);
    }
  };

  console.log(selectedIds);

  const handleCheckboxChange = (e, row) => {
    if (row) {
      if (e.target.checked) {
        setSelectedIds(prev => [...prev, row]);
      } else {
        setSelectedIds(prev => prev.filter(item => item !== row));
      }
      return;
    }
    if (selectAll) {
      setSelectedIds([]);
      setSelectAll(false);
    } else {
      if (selectedIds.length !== tripList.length) {
        setSelectedIds(tripList.map(item => item));
      } else {
        setSelectedIds([]);
        setSelectAll(false);
      }
    }
    return;
  };


  const columns = [
    {
      id: "routeId",
      name: <TableHead>Route ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.routeId}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: <TableHead position="left">Route Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.routeName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead position="left">Vehicle No</TableHead>,
      cell: (row) => <TableCell position="left">{row.vehicleNo}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{row.tripDate ? DateUtils.convertDate(row.tripDate) : "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead position="left">Trip Type</TableHead>,
      cell: (row) => <TableCell position="left">{row.tripType}</TableCell>,
    },
    {
      id: "companyName",
      name: <TableHead position="left">Company Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.companyName}</TableCell>,
    },
    {
      id: "tripAmount",
      name: <TableHead>Trip Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.tripAmount)?.toLocaleString()}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead p>Status</TableHead>,
      cell: (row) => <TableCell>{getInvoiceStatusName(row.invoiceStatus)}</TableCell>,
    },
    {
      id: "paidAmount",
      name: <TableHead>Paid Amount</TableHead>,
      cell: (row) => <TableCell>{row.invoicePaidAmount ? "₹ " + parseInt(row.invoicePaidAmount)?.toLocaleString() : "-"}</TableCell>,
    },
    {
      id: "paidDate",
      name: <TableHead>Paid Date</TableHead>,
      cell: (row) => (
        <TableCell>{row.invoicePaidDate ? DateUtils.convertDate(row.invoicePaidDate) : "-"}</TableCell>
      ),
    },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            to={`/invoice/invoice-details?id=${row.id}`}
            className=" flex items-center gap-2"
          >
            <img src={eye} width={30} alt="" />
          </Link>
          {type === 2 && (
            <button onClick={() => HandlePushRemittance(row.id)} className="pl-1">
              <img src={arrow} width={30} alt="" />
            </button>
          )}
        </TableCell>
      ),
    },
  ];

  const columnsWithSelect = [
    {
      selector: "checkbox",
      headerCheckbox: true,
      id: "allCheckbox",
      name:
        <TableHead width="40px">
          <div className="py-4 pl-1 text-[#3C4048] text-center w-full font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
            <span
              className={"py-4 text-center w-full text-sm font-roboto font-medium"}
            >
              <input
                type="checkbox"
                value={selectAll}
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e)}
                checked={selectAll || selectedIds.length === tripList.length}
              />
            </span>
          </div>
        </TableHead>,
      cell: (row) => (
        <TableCell width="40px">
          <div className="py-4 text-[#3C4048] text-center font-medium text-sm font-roboto">
            <span
              className={"py-4 text-center text-sm font-roboto font-medium"}
            >
              <input
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e, row)}
                checked={selectAll || selectedIds.includes(row)}
              />
            </span>
          </div>
        </TableCell>
      ),
    },
    {
      id: "routeId",
      name: <TableHead>Route ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.routeId}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: <TableHead position="left">Route Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.routeName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead position="left">Vehicle No</TableHead>,
      cell: (row) => <TableCell position="left">{row.vehicleNo}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{row.tripDate ? DateUtils.convertDate(row.tripDate) : "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead position="left">Trip Type</TableHead>,
      cell: (row) => <TableCell position="left">{row.tripType}</TableCell>,
    },
    {
      id: "companyName",
      name: <TableHead position="left">Company Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.companyName}</TableCell>,
    },
    {
      id: "tripAmount",
      name: <TableHead>Trip Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.tripAmount)?.toLocaleString()}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead p>Status</TableHead>,
      cell: (row) => <TableCell>{getInvoiceStatusName(row.invoiceStatus)}</TableCell>,
    },
    {
      id: "paidAmount",
      name: <TableHead>Paid Amount</TableHead>,
      cell: (row) => <TableCell>{row.invoicePaidAmount ? "₹ " + parseInt(row.invoicePaidAmount)?.toLocaleString() : "-"}</TableCell>,
    },
    {
      id: "paidDate",
      name: <TableHead>Paid Date</TableHead>,
      cell: (row) => (
        <TableCell>{row.invoicePaidDate ? DateUtils.convertDate(row.invoicePaidDate) : "-"}</TableCell>
      ),
    },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            to={`/invoice/invoice-details?id=${row.id}`}
            className=" flex items-center gap-2"
          >
            <img src={eye} width={30} alt="" />
          </Link>
          {type === 2 && (
            <button onClick={() => HandlePushRemittance(row.id)} className="pl-1">
              <img src={arrow} width={30} alt="" />
            </button>
          )}
        </TableCell>
      ),
    },
  ];

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filteredItems = tripList.filter((item) =>
      item.routeId?.toLowerCase().includes(value.toLowerCase()) ||
      item.vehicleNo?.toLowerCase().includes(value.toLowerCase()) ||
      item.routeName?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTripList(filteredItems);
  };

  const textButton = () => {
    return (
      <div className="flex gap-2 items-center justify-center">
        <div>
          <p className="text-base">Push</p>
        </div>
        <svg
          class="bi bi-download transform rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
        </svg>
      </div>
    );
  };

  const handlePushModal = () => {
    if (selectedIds.length <= 0) {
      message.error("Please select atleast one row");
      return;
    }
  };
  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex justify-between w-full pb-2 pr-2">
        <div className="">
          {activeKey === 2 && selectedIds.length > 0 && (
            <Button width={"100px"} text={textButton()} onClick={handlePushRemittanceMultiple} />
          )}
        </div>
        <Input
          placeholder="Search"
          type="search"
          value={search}
          onChange={handleSearchFilter}
        />
      </div>
      <Table columns={activeKey === 2 ? columnsWithSelect : columns} rows={filteredTripList} />
    </div>
  );
}
