import { useEffect, useState } from "react";
import Button from "../reusable/Button";

import Table from "../reusable/Table/Table";
import TableHead from "../reusable/Table/TableHead";
import TableCell from "../reusable/Table/TableCell";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import Input from "../reusable/Input";
import addIcon from "../../assets/addIcon.png";
import Passbook from "../../services/api/passbookApi";
import DateUtils from "../../services/utility/DateUtils";
import plus from "../../assets/plus.svg";

export default function Wallet() {

  const [money, setMoney] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [walletData, setWalletData] = useState([]);

  useEffect(() => {
    fetchWalletData();
  }, []);

  const fetchWalletData = async () => {
    try {
      const response = await Passbook.getBalance();
      setWalletData(
        response.length > 0 && response.map((item, index) => {
          item.sNo = index + 1;
          return item;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "srNo",
      name: <TableHead>S.No</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link className="text-primary-blue">{row.sNo}</Link>
        </TableCell>
      ),
    },
    {
      id: "amount",
      name: <TableHead>Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.balance)?.toLocaleString()}</TableCell>,
    },
    {
      id: "date",
      name: <TableHead>Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.date)}</TableCell>,
    },
  ];

  const handleAddMoney = async () => {
    if (!money) {
      message.error("Please enter amount");
      return;
    }
    try {
      const data = {
        balance: money,
      }
      const response = await Passbook.addBalance(data);
      if (response.status) {
        message.success("Money added successfully");
        fetchWalletData();
        setMoney(null);
      }
    } catch (err) {
      message.error(err.message);
      console.error(err);
    }
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full pr-5 flex justify-center items-center removeScrollBar pb-5">
        <div className=" w-full  flex justify-between items-center rounded-lg ">
          <div className="flex justify-end w-full">
            <div className="w-fit py-2">
              <Button
                text="Add Money"
                width="150px"
                borderColor="#1849A9"
                icon={plus}
                type="button"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Table rows={walletData} columns={columns} />
      {showModal && (
        <Modal
          title={
            <div className="flex flex-col gap-2">
              <img src={addIcon} alt="add" className="h-10 w-10" />
            </div>
          }
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          width={600}
          centered
        >
          <div className=" w-full flex-col flex justify-center items-center">
            <Input
              label="Add Money"
              placeholder="Enter amount"
              value={money}
              onChange={(e) => setMoney(e.target.value)}
              width="100%"
              type="number"
              mandate
            />
            <div className="pt-6">
              <Button
                text="Add"
                width="100px"
                borderColor="#1849A9"
                type="button"
                onClick={handleAddMoney}
              />
            </div>
          </div>
        </Modal>
      )
      }
    </>
  );
}
