import axios from "axios";

const Home = {
  tripSummary: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/tripSummary`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  employeeAttendence: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/employeeAttendence`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  ticketSummary: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/ticketSummary`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  employeeSummary: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/employeeSummary`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  lastSevenDaysAttendance: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/lastSevenDaysAttendance`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  totalTripSummary: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}home/verify/totalTripSummary`,
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  totalTicketSummary: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}home/verify/totalTicketSummary`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            email: localStorage.getItem("emailFleetrax"),
            usertype: localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  // state: async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}dropDown/getStates`
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
  // district: async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}dropDown/getDistricts/`,
  //       {
  //         params: { id: id },
  //       }
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
  // tripType: async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}tripType/findAll`
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
  // vehicleType: async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}vehicleType/findAll`
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
  // shiftTime: async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}shiftTime/findAll`
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
  // bloodGroup: async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}dropDown/getBloodGroup`
  //     );
  //     return response.data.data; // Return the response data
  //   } catch (error) {
  //     return error.response && error.response.data;
  //   }
  // },
};

export default Home;
