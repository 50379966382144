import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Driver from "../../../services/api/driverApi";
import Remittance from "../../../services/api/remittanceApi";
import eye from "../report-page/assets/eye.6bf84ddf18c60bb31dcf.png"
export default function RemittanceTable() {

  const [driverStatus, setDriverStatus] = useState("");
  const [remittanceList, setRemittanceList] = useState([]);
  const [driverList, setDriverList] = useState([]);

  useEffect(() => {
    fetchTicketsData();
    fetchDriverData();
  }, []);

  useEffect(() => {
    fetchDriverData();
  }, [driverStatus]);

  const fetchDriverData = async () => {
    try {
      const params = {
        driverId: driverStatus.id ? parseInt(driverStatus.id) : null,
      };
      const allRemittance = await Remittance.findAll(params);
      setRemittanceList(allRemittance);
    } catch (error) {
      console.error("Error fetching remittance data", error);
    }
  };
  const fetchTicketsData = async () => {
    try {
      const allDrivers = await Driver.getDrivers();
      setDriverList(allDrivers.data);
    } catch (error) {
      console.error("Error fetching driver data", error);
    }
  };

  const columns = [
    {
      id: "driverID",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {/* <Link
            className=" text-primary-blue"
            to={`/driver-management/${row.driverID}?id=${row.id}`}
          > */}
          {row.driverID}
          {/* </Link> */}
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell textCenter>{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "totalNoOfTrips",
      name: <TableHead>Total No of Trips</TableHead>,
      cell: (row) => <TableCell textCenter>{row.totalNoOfTrip}</TableCell>,
    },
    {
      id: "onHoldAmount",
      name: <TableHead>On Hold Amount</TableHead>,
      cell: (row) => <TableCell>₹ {parseInt(row.totalRemaingAmount)?.toLocaleString()}</TableCell>,
    },
    {
      id: "action",
      name: <TableHead>Action</TableHead>,
      cell: (row) => <TableCell>
        <Link to={`/remittance/${row.driverID}?id=${row.id}`}>
          <img width={35} src={eye} alt="eye" />
        </Link>
      </TableCell>,
    },

  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2">
        <DropDown
          label={"Driver ID"}
          placeholder={"Select ID"}
          displayValue={"driverID"}
          data={driverList}
          value={driverStatus}
          setValue={setDriverStatus}
          selectAll
        />
      </div>
      <Table columns={columns} rows={remittanceList} />
    </div>
  );
}
