import { useRef } from "react";
import calendar from "../../assets/calendar.svg";
import ErrorMessage from "./ErrorMessage";
import Mandate from "./Mandate";
import DateUtils from "../../services/utility/DateUtils";

export default function DateInput({
    value,
    setValue,
    placeholder,
    label,
    width,
    mandate,
    disabled,
    readOnly,
    error,
    backgroundColor,
    setLocalStorage,
}) {
    const dateRef = useRef();
    const handleClick = (e) => {
        if (e.target.nodeName === "INPUT" || readOnly) {
            return;
        }
        dateRef.current.showPicker();
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        if (setLocalStorage) {
            localStorage.setItem(setLocalStorage, e.target.value);
        }
    };

    const dateStyle = `
    .spin-button-none::-webkit-outer-spin-button,
    .spin-button-none::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .spin-button-none {
      -moz-appearance: textfield;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }
  `;

    const inputStyle = {
        width: width || "100%",
    };

    return (
        <div
            className="flex flex-col min-w-[200px]  max-w-[300px]"
            style={{
                width: inputStyle.width
            }}
        >
            <div className="text-[#344054] text-[15px] pl-1 font-medium">
                {label}<Mandate mandate={mandate} />
            </div>
            <style>{dateStyle}</style>
            <div
                onClick={!disabled ? handleClick : () => { }}
                className={`flex relative justify-start items-center w-full h-[40px] ${!disabled && !readOnly && "cursor-pointer"
                    }   rounded-md ${!value && error ? "border-[#F04438]" : "border-border-gray"
                    } border pl-3  py-2 ${disabled && !readOnly && "bg-[#EEEEEE]"}
                    
                    ${backgroundColor === "#fff" && "bg-white"}
                    `}
            >
                <img src={calendar} alt="calendar" />
                <input
                    id="date1"
                    type="date"
                    readOnly
                    name="input1"
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={`outline-none text-base opacity-0 pl-0 pointer-events-none \ ${value === undefined || value === null || !value
                        ? "text-textGray"
                        : "text-textBlack"
                        } ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
                />
                <div className={`absolute ${!value ? "top-[9px]" : "top-[7px]"} left-9 w-fit font-normal`}>
                    <div className={`${!value && " text-sm text-placeholder-gray"}`}>
                        {DateUtils.convertDate(value) !== "Invalid date" ? DateUtils.convertDate(value) : value || placeholder}
                    </div>
                    <input
                        type="date"
                        ref={dateRef}
                        value={value}
                        onChange={handleChange}
                        className={`opacity-0 pointer-events-none text-base absolute top-[32px] -left-[37px] h-1`}
                        disabled={disabled}
                    />
                </div>
            </div>
            {!value && error && (
                <div>
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                </div>
            )}
        </div>
    );
}