import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Wallet from "./Wallet";
import CreateTabNavigation from "./CreateTabNavigation";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function AdminTabNavigation() {
  const [activeKey, setActiveKey] = useState("wallet");

  useEffect(() => {
    const key = sessionStorage.getItem("adminTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("adminTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: "wallet",
      label: (
        <LabelForTabs isActive={activeKey === "wallet"}>Wallet</LabelForTabs>
      ),
      children: <Wallet />,
    },
    {
      key: "create",
      label: (
        <LabelForTabs isActive={activeKey === "create"}>Create</LabelForTabs>
      ),
      children: <CreateTabNavigation />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default AdminTabNavigation;
