import axios from "axios";

const Tickets = {
  findAllTickets: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ticket/verify/findAllTicket`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
  findOneTicket: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ticket/verify/findOneTicket/${params.id}`,
        {
          params,
          headers: {
            "x-access-token": localStorage.getItem("accessTokenFleetrax"),
            "email": localStorage.getItem("emailFleetrax"),
            "usertype": localStorage.getItem("userTypeFleetrax"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  },
};

export default Tickets;
