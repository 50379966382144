import { useRef } from "react";
import calendarIcon from "../../assets/calendar.svg";
import ErrorMessage from "./ErrorMessage";
import Mandate from "./Mandate";

export default function FilterDates({ date, setDate, firstDateLabel, secondDateLabel, mandate, disabled, error }) {
    const firstDateRef = useRef();
    const secondDateRef = useRef();

    const handleFirstDate = (e) => {
        if (e.target.nodeName === "INPUT" || disabled) {
            return;
        }
        firstDateRef.current.showPicker();
    };

    const handleSecondDate = (e) => {
        if (e.target.nodeName === "INPUT" || disabled) {
            return;
        }
        secondDateRef.current.showPicker();
    };

    const dateStyle = `
      .spin-button-none::-webkit-outer-spin-button,
      .spin-button-none::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .spin-button-none {
        -moz-appearance: textfield;
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
      }
    `;

    return (
        <div className="flex flex-col">
            <div className="flex gap-4">
                <style>{dateStyle}</style>
                <div>
                    <div className="h-fit">
                        <label
                            className="text-[#344054] text-[13px] pl-1 font-medium"
                            htmlFor={firstDateLabel}
                        >
                            {firstDateLabel}<Mandate mandate={mandate} />
                        </label>
                    </div>
                    <div
                        onClick={handleFirstDate}
                        className={`flex items-center gap-2 px-2 text-sm w-[130px] h-[33px] ${disabled ? "cursor-default bg-[#EEEEEE]" : "cursor-pointer"} rounded-md border ${error ? "border-[#F04438]" : "border-inputBorderGray"}`}
                    >
                        <img src={calendarIcon} alt="calendar" className="pb-[1px]" />
                        <input
                            id="date"
                            type="date"
                            readOnly
                            name="input"
                            disabled={disabled}
                            value={date.firstDate}
                            onChange={(e) => setDate({ ...date, firstDate: e.target.value })}
                            className={`outline-none pointer-events-none border-none ${date.firstDate === "" ? "text-gray-400" : "text-black"}`}
                        />
                    </div>
                    <input
                        type="date"
                        ref={firstDateRef}
                        disabled={disabled}
                        value={date.firstDate}
                        onChange={(e) => setDate({ ...date, firstDate: e.target.value })}
                        className={`opacity-0 pointer-events-none relative -top-[26px]`}
                    />
                </div>
                <div>
                    <div className="h-fit">
                        <label
                            className="text-[#344054] text-[13px] pl-1 font-medium"
                            htmlFor={secondDateLabel}
                        >
                            {secondDateLabel}<Mandate mandate={mandate} />
                        </label>
                    </div>
                    <div
                        onClick={handleSecondDate}
                        className={`flex items-center gap-2 px-2 text-sm w-[130px] h-[33px] ${disabled ? "cursor-default bg-[#EEEEEE]" : "cursor-pointer"} rounded-md border ${error ? "border-[#F04438]" : "border-inputBorderGray"} `}
                    >
                        <img src={calendarIcon} alt="calendar" className="pb-[1px]" />
                        <input
                            id="date"
                            type="date"
                            readOnly
                            disabled={disabled}
                            name="input"
                            value={date.secondDate}
                            onChange={(e) => setDate({ ...date, secondDate: e.target.value })}
                            className={`outline-none pointer-events-none border-none ${date.secondDate === "" ? "text-gray-400" : "text-black"}`}
                        />
                    </div>
                    <input
                        type="date"
                        ref={secondDateRef}
                        disabled={disabled}
                        value={date.secondDate}
                        onChange={(e) => setDate({ ...date, secondDate: e.target.value })}
                        className={`opacity-0 pointer-events-none relative -top-[26px]`}
                    />
                </div>
            </div>
            <div className={`-mt-6 ${error && "pb-3"}`}>
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            </div>
        </div>
    );
}