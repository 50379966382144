import { useEffect, useState } from "react";
import AttendenceSummary from "./charts/AttendenceSummary";
import EmployeeAttendence from "./charts/EmployeeAttendence";
import EmployeeSummary from "./charts/EmployeeSummary";
import TicketSummary from "./charts/TicketSummary";
import TotalTripSummary from "./charts/TotalTripSummary";
import TripSummary from "./charts/TripSummary";
import TicketDetails from "./charts/TicketDetails";
import HomeApi from "../../services/api/homeApi";
import DateUtils from "../../services/utility/DateUtils";
import moment from "moment-timezone";
import Common from "../../services/api/commonApi";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  const url = location.pathname;

  const [companyList, setCompanyList] = useState([]);
  const [tripTypeList, setTripTypeList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [shiftTimeList, setShiftTimeList] = useState([]);

  const [userName, setUserName] = useState("");
  const [tripSummaryData, setTripSummaryData] = useState([]);
  const [employeeAttendenceData, setEmployeeAttendenceData] = useState([]);
  const [ticketSummaryData, setTicketSummaryData] = useState([]);
  const [allotedEmployeesData, setAllotedEmployeesData] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [totalNoOfEmployees, setTotalNoOfEmployees] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [presentData, setPresentData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [absentData, setAbsentData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [totalTripSummaryData, setTotalTripSummaryData] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [totalTicketSummaryData, setTotalTicketSummaryData] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [filtersTripSummary, setFiltersTripSummary] = useState({
    company: [],
    shiftTime: [],
    tripType: [],
    vehicleType: [],
  });
  const [filterDateTripSummary, setFilterDateTripSummary] = useState({
    firstDate: "",
    secondDate: "",
  });
  const [filtersEmployeeAttandance, setFiltersEmployeeAttandance] = useState({
    company: [],
    shiftTime: [],
    tripType: [],
    vehicleType: [],
  });
  const [filterDateEmployeeAttandance, setFilterDateEmployeeAttandance] =
    useState({
      firstDate: "",
      secondDate: "",
    });

  const [filtersTicketSummary, setFiltersTicketSummary] = useState({
    company: [],
  });

  const [filterDateTicketSummary, setFilterDateTicketSummary] = useState({
    firstDate: "",
    secondDate: "",
  });

  const [filtersEmployeeSummary, setFiltersEmployeeSummary] = useState({
    company: [],
    shiftTime: [],
    tripType: ["1"],
    vehicleType: [],
  });
  const [filtersAttendanceSummary, setFiltersAttendanceSummary] = useState({
    company: [],
    shiftTime: [],
    tripType: ["1"],
    vehicleType: [],
  });
  const [filtersTotalTripSummary, setFiltersTotalTripSummary] = useState({
    company: [],
    shiftTime: [],
    tripType: [],
    vehicleType: [],
  });

  const storedUser = localStorage.getItem("userTypeFleetrax");
  const initialUser = storedUser || "";
  const [userCompany, setUserCompany] = useState(initialUser);

  useEffect(() => {
    const userName = localStorage.getItem("nameFleetrax");
    if (userName) {
      setUserName(userName);
    }
    // filters
    fetchVehicleTypeData();
    fetchTripTypeData();
    fetchShiftTimeData();
    fetchCompanyData();

    fetchTotalTicketSummary();
    fetchTotalTripSummary();
    fetchLastSevenDaysAttendance();
    fetchEmployeeSummaryData();
    fetchTicketSummaryData();
    fetchTripSummaryData();
    fetchAttendenceData();
  }, []);

  useEffect(() => {
    fetchTotalTicketSummary();
  }, []);

  useEffect(() => {
    fetchTotalTripSummary();
  }, [filtersTotalTripSummary]);

  useEffect(() => {
    fetchLastSevenDaysAttendance();
  }, [filtersAttendanceSummary]);

  useEffect(() => {
    fetchEmployeeSummaryData();
  }, [filtersEmployeeSummary]);

  useEffect(() => {
    fetchTicketSummaryData();
  }, [filtersTicketSummary, filterDateTicketSummary]);

  useEffect(() => {
    fetchTripSummaryData();
  }, [filtersTripSummary, filterDateTripSummary]);

  useEffect(() => {
    fetchAttendenceData();
  }, [filtersEmployeeAttandance, filterDateEmployeeAttandance]);

  const getLastSevenDaysLabels = () => {
    const lastSevenDaysLabels = [];

    for (let i = 6; i >= 0; i--) {
      const currentDate = DateUtils.getTMinusDate(i); // Get date for each of the last seven days
      const dayLabel = moment(currentDate).tz("Asia/Kolkata").format("ddd"); // Get the day label (Mon, Tue, etc.)

      lastSevenDaysLabels.push(dayLabel);
    }

    return lastSevenDaysLabels;
  };

  const xLabels = getLastSevenDaysLabels();

  const fetchTripSummaryData = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersTripSummary.company,
        shiftTime: filtersTripSummary.shiftTime,
        tripType: filtersTripSummary.tripType,
        vehicleType: filtersTripSummary.vehicleType,
        firstDate: filterDateTripSummary.firstDate,
        secondDate: filterDateTripSummary.secondDate,
      };

      const data = await HomeApi.tripSummary(dataObj);
      setTripSummaryData(data);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchAttendenceData = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersEmployeeAttandance.company,
        shiftTime: filtersEmployeeAttandance.shiftTime,
        tripType: filtersEmployeeAttandance.tripType,
        vehicleType: filtersEmployeeAttandance.vehicleType,
        firstDate: filterDateEmployeeAttandance.firstDate,
        secondDate: filterDateEmployeeAttandance.secondDate,
      };

      const data = await HomeApi.employeeAttendence(dataObj);
      setEmployeeAttendenceData(data);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchTicketSummaryData = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersTicketSummary.company,
        firstDate: filterDateTicketSummary.firstDate,
        secondDate: filterDateTicketSummary.secondDate,
      };
      const data = await HomeApi.ticketSummary(dataObj);
      setTicketSummaryData(data);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchEmployeeSummaryData = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersEmployeeSummary.company,
        shiftTime: filtersEmployeeSummary.shiftTime,
        tripType: filtersEmployeeSummary.tripType,
        vehicleType: filtersEmployeeSummary.vehicleType,
      };

      const data = await HomeApi.employeeSummary(dataObj);
      const TotalData = data.totalEmployee;
      const AllottedData = data.last7DaysData;

      setAllotedEmployeesData(AllottedData);
      setTotalNoOfEmployees(TotalData);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchLastSevenDaysAttendance = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersAttendanceSummary.company,
        shiftTime: filtersAttendanceSummary.shiftTime,
        tripType: filtersAttendanceSummary.tripType,
        vehicleType: filtersAttendanceSummary.vehicleType,
      };
      const data = await HomeApi.lastSevenDaysAttendance(dataObj);
      const PresentData = data.PresentData;
      const AbsentData = data.AbsentData;

      setPresentData(PresentData);
      setAbsentData(AbsentData);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchTotalTripSummary = async () => {
    try {
      const dataObj = {
        company: userCompany === "lapiz" ? [3] : filtersTotalTripSummary.company,
        shiftTime: filtersTotalTripSummary.shiftTime,
        tripType: filtersTotalTripSummary.tripType,
        vehicleType: filtersTotalTripSummary.vehicleType,
      };
      const data = await HomeApi.totalTripSummary(dataObj);
      setTotalTripSummaryData(data);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchTotalTicketSummary = async () => {
    try {
      const data = await HomeApi.totalTicketSummary();
      setTotalTicketSummaryData(data);
    } catch (error) {
      console.error("Error fetching trip summary data", error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const data = await Common.company();
      setCompanyList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchShiftTimeData = async () => {
    try {
      const data = await Common.shiftTime();
      setShiftTimeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTripTypeData = async () => {
    try {
      const data = await Common.tripType();
      setTripTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchVehicleTypeData = async () => {
    try {
      const data = await Common.vehicleType();
      setVehicleTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-y-6 p-2 lg:p-8 overflow-y-auto h-[calc(100vh-10px)]">
        <p className="text-3xl font-semibold pb-4">Welcome {userName} !</p>
        <div className="flex justify-between flex-wrap gap-x-5 gap-y-10 w-full">
          <TripSummary
            data={tripSummaryData}
            companyList={companyList}
            shiftTimeList={shiftTimeList}
            TripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            setFilters={setFiltersTripSummary}
            filters={filtersTripSummary}
            filterDate={filterDateTripSummary}
            setFilterDate={setFilterDateTripSummary}
            userCompany={userCompany}
          />
          <EmployeeAttendence
            data={employeeAttendenceData}
            companyList={companyList}
            shiftTimeList={shiftTimeList}
            TripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            setFilters={setFiltersEmployeeAttandance}
            filters={filtersEmployeeAttandance}
            filterDate={filterDateEmployeeAttandance}
            setFilterDate={setFilterDateEmployeeAttandance}
            userCompany={userCompany}
          />
          {userCompany !== "lapiz" && (
            <TicketSummary
              data={ticketSummaryData}
              companyList={companyList}
              filters={filtersTicketSummary}
              setFilters={setFiltersTicketSummary}
              filterDate={filterDateTicketSummary}
              setFilterDate={setFilterDateTicketSummary}
              userCompany={userCompany}
            />
          )}
          <EmployeeSummary
            totalData={totalNoOfEmployees}
            allottedData={allotedEmployeesData}
            xLabels={xLabels}
            companyList={companyList}
            shiftTimeList={shiftTimeList}
            TripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            setFilters={setFiltersEmployeeSummary}
            filters={filtersEmployeeSummary}
            userCompany={userCompany}
          />
          <AttendenceSummary
            presentData={presentData}
            absentData={absentData}
            xLabels={xLabels}
            companyList={companyList}
            shiftTimeList={shiftTimeList}
            TripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            setFilters={setFiltersAttendanceSummary}
            filters={filtersAttendanceSummary}
            userCompany={userCompany}
          />
          <TotalTripSummary
            data={totalTripSummaryData}
            xLabels={xLabels}
            companyList={companyList}
            shiftTimeList={shiftTimeList}
            TripTypeList={tripTypeList}
            vehicleTypeList={vehicleTypeList}
            setFilters={setFiltersTotalTripSummary}
            filters={filtersTotalTripSummary}
            userCompany={userCompany}
          />
          {userCompany !== "lapiz" && (
            <TicketDetails
              data={totalTicketSummaryData}
              companyList={companyList}
              shiftTimeList={shiftTimeList}
              TripTypeList={tripTypeList}
              vehicleTypeList={vehicleTypeList}
              userCompany={userCompany}
            />
          )}
        </div>
      </div>
    </>
  );
}
