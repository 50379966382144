import { useEffect, useState, useRef } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import PageLayout from "../../components/layouts/PageLayout";
import LiveTrackingTable from "../../components/tables/live-tracking/LiveTrackingTable";
import DateUtils from "../../services/utility/DateUtils";
import LiveTrack from "../../services/api/liveTrackingApi";
import Common from "../../services/api/commonApi";

export default function LiveTracking() {
  const location = useLocation();
  const url = location.pathname;

  const storedCompanyID = localStorage.getItem("liveTrackingCompany");
  const initialCompanyID = storedCompanyID ? JSON.parse(storedCompanyID) : "";

  const [company, setCompany] = useState(initialCompanyID);

  const storedTripTypeID = localStorage.getItem("liveTrackingTripType");
  const initialTripTypeID = storedTripTypeID
    ? JSON.parse(storedTripTypeID)
    : "";

  const [tripType, setTripType] = useState(initialTripTypeID);

  const storedFromDate = localStorage.getItem("liveTrackingFromDate");
  const initialFromDate = storedFromDate
    ? storedFromDate
    : DateUtils.getCurrentDate();

  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("liveTrackingToDate");
  const initialToDate = storedToDate
    ? storedToDate
    : DateUtils.getCurrentDate();

  const [toDate, setToDate] = useState(initialToDate);

  const [tripsList, setTripsList] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [tripTypeList, setTripTypeList] = useState([]);
  const storedUser = localStorage.getItem("userTypeFleetrax");
  const initialUser = storedUser || "";
  const [userCompany, setUserCompany] = useState(initialUser);

  window.onbeforeunload = () => {
    localStorage.removeItem("liveTrackingCompany");
    localStorage.removeItem("liveTrackingTripType");
    localStorage.removeItem("liveTrackingFromDate");
    localStorage.removeItem("liveTrackingToDate");
  };

  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching company data", error);
    }
  };

  const fetchTripTypeData = async () => {
    try {
      const allTripTypes = await Common.tripType();
      setTripTypeList(allTripTypes);
    } catch (error) {
      console.error("Error fetching trip-type data", error);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    fetchTripTypeData();
  }, []);

  const paramsRef = useRef();

  useEffect(() => {
    const params = {
      companyID: userCompany === "lapiz" ? 3 : company.id,
      tripTypeID: tripType.id,
      fromDate: fromDate,
      toDate: toDate,
    };

    paramsRef.current = params;
    fetchTripData(params);
  }, [company, tripType, fromDate, toDate]);

  useEffect(() => {
    let intervalId;

    if (url === "/live-tracking") {
      intervalId = setInterval(() => {
        const params = paramsRef.current;
        fetchTripData(params);
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [url]);

  const fetchTripData = async (dataOBJ) => {
    try {
      // const params = {
      //   companyID: company.id,
      //   tripTypeID: tripType.id,
      //   fromDate: fromDate,
      //   toDate: toDate,
      // };
      const data = await LiveTrack.getAllLiveTracking(dataOBJ);
      setTripsList(data);
    } catch (error) {
      console.error("Error fetching trips data", error);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Live Tracking"}
        description={"track your trip details here."}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className="flex flex-wrap gap-x-8 gap-y-2 mt-7 mr-3">
          {userCompany === "lapiz" ? (
            // <DropDown
            //   label={"Company Name"}
            //   placeholder={"LAPIZ"}
            //   value={"LAPIZ"}
            //   mandate
            //   disabled
            // />
            ""
          ) : (
            <DropDown
              label={"Select Company"}
              placeholder={"Select company name"}
              displayValue={"name"}
              data={companyList}
              value={company}
              setValue={setCompany}
              setLocalStorage={"liveTrackingCompany"}
              selectAll
            />
          )}
          <DropDown
            label={"Trip Type"}
            placeholder={"Select trip type"}
            displayValue={"name"}
            data={tripTypeList}
            value={tripType}
            setValue={setTripType}
            setLocalStorage={"liveTrackingTripType"}
            selectAll
          />
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
            setLocalStorage={"liveTrackingFromDate"}
            mandate
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
            setLocalStorage={"liveTrackingToDate"}
            mandate
          />
        </div>
        <div className="w-full flex mt-7">
          <LiveTrackingTable data={tripsList} />
        </div>
      </PageLayout>
    </>
  );
}
