import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import PageLayout from "../../components/layouts/PageLayout";
import DriverTable from "../../components/tables/driver-management/DriverTable";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Upload from "../../components/reusable/Upload";
import edit from "../../assets/edit_pencil.svg";
import profile from "../../assets/profile_avatar.svg";
import DateInput from "../../components/reusable/DateInput";
import Common from "../../services/api/commonApi";
import Driver from "../../services/api/driverApi";
import axios from "axios";
import RemittanceDetailsTable from "../../components/tables/remittance/RemittanceDetailsTable";

export default function RemittanceDetails() {
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Remittance Details"}
        description={"View your Remittance Details here."}
        buttonWidth={"fit-content"}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className=" w-full flex mt-7 pr-4">
          <RemittanceDetailsTable />
        </div>
      </PageLayout>
    </>
  );
}
