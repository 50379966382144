import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import NotPaidInvoiceTable from "../tables/invoice/NotPaidInvoiceTable";

function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"
        } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

function InvoiceTabNavigation({
  driverId,
  shiftTime,
  fromDate,
  toDate,
  company,
}) {
  const [activeKey, setActiveKey] = useState(1);
  const [tripCount, setTripCount] = useState({});

  useEffect(() => {
    const key = sessionStorage.getItem("invoiceTabNavigation");
    if (key) {
      setActiveKey(parseInt(key));
    }
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("invoiceTabNavigation", key);
    setActiveKey(key);
  };

  const items = [
    {
      key: 1,
      label: <LabelForTabs isActive={activeKey === 1}>Not Paid ({tripCount && tripCount.notPaidTripCount})</LabelForTabs>,
      children: (
        <>
          <NotPaidInvoiceTable
            type={1}
            activeKey={activeKey}
            driverId={driverId}
            shiftTime={shiftTime}
            fromDate={fromDate}
            toDate={toDate}
            company={company}
            setTripCount={setTripCount}
            />
        </>
      ),
    },
    {
      key: 2,
      label: <LabelForTabs isActive={activeKey === 2}> On hold ({tripCount && tripCount.onHoldTripCount}) </LabelForTabs>,
      children: (
        <>
          <NotPaidInvoiceTable
            type={2}
            activeKey={activeKey}
            driverId={driverId}
            shiftTime={shiftTime}
            fromDate={fromDate}
            toDate={toDate}
            company={company}
            setTripCount={setTripCount}
            />
        </>
      ),
    },
    {
      key: 3,
      label: <LabelForTabs isActive={activeKey === 3}>Paid ({tripCount && tripCount.paidTripCount})</LabelForTabs>,
      children: (
        <>
          <NotPaidInvoiceTable
            type={3}
            activeKey={activeKey}
            driverId={driverId}
            shiftTime={shiftTime}
            fromDate={fromDate}
            toDate={toDate}
            company={company}
            setTripCount={setTripCount}
            />
        </>
      ),
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default InvoiceTabNavigation;
